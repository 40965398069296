import { Row, Col, Spin } from "antd";
import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import PageHeader from "../../components/PageHeader";
import { STATUS } from "../../constants";
import ShDescription from "../../components/ShDescription";
import { UserContext } from "../../context";
import {
  reqTmgoodInfo,
  reqContactInfo,
} from "../../service/management/transaction";
import ShDetailPageModel from "../../components/ShDetailPageModel";
import qs from "query-string";
export default function TransactionDetail() {
  const { id: tmgoodId } = useParams();
  const { search } = useLocation();
  const [status, setStatus] = useState(STATUS.idle);
  const [tmgoodInfo, setTmgoodInfo] = useState([]);
  const { parkName } = useContext(UserContext);
  const [tmStatus, setTmStatus] = useState(STATUS.idle);
  const [contactInfo, setContactInfo] = useState([]);
  const baseDesConfig = {
    title: "基本信息",
    column: 2,
    nameList: [
      {
        label: "商标",
        span: 1,
        dataIndex: "request_img",
      },
      {
        label: "商标名称",
        span: 1,
        dataIndex: "mark_name",
      },
      {
        label: "分类",
        span: 1,
        dataIndex: "good_kind",
      },
      {
        label: "申请号/注册号",
        span: 1,
        dataIndex: "request_no",
      },
      {
        label: "商标类型",
        span: 1,
        dataIndex: "good_kind_name",
      },
      {
        label: "商标样式",
        span: 1,
        dataIndex: "shape_flag",
      },
      {
        label: "字符数量",
        span: 1,
        dataIndex: "char_num",
      },
      {
        label: "商标状态",
        span: 1,
        dataIndex: "goods_status",
      },

      {
        label: "注册日期",
        span: 1,
        dataIndex: "registration_date",
      },
      {
        label: "备注",
        span: 2,
        dataIndex: "remark",
      },
      {
        label: "关联群组",
        span: 2,
        dataIndex: "group_id",
      },
      {
        label: "商品/服务",
        span: 2,
        dataIndex: "group_cn",
      },
    ],
  };
  const contactsConfig = {
    title: "联系人",
    nameList: [
      {
        title: "联系人",
        dataIndex: "contacts",
      },
      {
        title: "联系电话",
        dataIndex: "contacts_phone",
      },
      {
        title: "报价",
        dataIndex: "price",
        render: (data) => "￥" + data,
      },
      {
        title: "地址",
        dataIndex: "contacts_addr",
      },
    ],
  };

  useEffect(() => {
    reqTmgoodInfo(tmgoodId).then((res) => {
      setTmgoodInfo(res.data);
      setStatus(STATUS.success);
    });
    if (search) {
      const { request_no, good_kind } = qs.parse(search);
      reqContactInfo(request_no, good_kind).then((res) => {
        setContactInfo(res);
        setTmStatus(STATUS.success);
      });
    }
  }, [tmgoodId, search]);
  return (
    <Spin spinning={status === STATUS.idle ? true : false}>
      <PageHeader
        title={tmgoodInfo[0]?.mark_name}
        description="展示商标的详细信息。"
        breadcrumb={[
          {
            title: <Link to={`/${parkName}/transaction`}>商标数据检索</Link>,
          },
          { title: `${tmgoodInfo[0]?.mark_name ?? ""}` },
        ]}
      />
      <Row gutter={32}>
        <Col span={18}>
          <ShDescription
            status={STATUS.success}
            config={baseDesConfig}
            dataSource={tmgoodInfo}
          />
        </Col>
        <Col span={18}>
          <ShDetailPageModel
            rowKey={"id"}
            config={contactsConfig}
            status={tmStatus}
            dataSource={contactInfo}
          />
        </Col>
      </Row>
    </Spin>
  );
}

import { useRef, useEffect, useContext } from "react";
import Chart from "chart.js/auto";
import { palette1 } from "./palette";
import { Spin } from "antd";

import { STATUS } from "../../constants";
import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context";

const Chart12 = memo(
  ({
    kanbanFilterInfo,
    status,
    setPracticalTotal,
    setAppearanceTotal,
    setInventTotal,
  }) => {
    const element = useRef();
    const filterData = kanbanFilterInfo.filter((item) => item.cloumn === 1);
    const navigate = useNavigate();
    const { parkName } = useContext(UserContext);
    useEffect(() => {
      let labels = [];
      let counts = [];
      if (filterData.length > 0) {
        const datas = filterData[0].context;
        labels = datas.map((item) => item.type_name);
        counts = datas.map((item) => item.count);
        setPracticalTotal(counts[0]);
        setAppearanceTotal(counts[1]);
        setInventTotal(counts[2]);
      }

      const chart = new Chart(element.current, {
        type: "pie",
        data: {
          labels: labels,
          datasets: [
            {
              data: counts,
              backgroundColor: palette1,
            },
          ],
        },
        options: {
          aspectRatio: 1.5,
          layout: {
            padding: 16,
          },
          plugins: {
            legend: { position: "right" },
          },
          onClick: (event, elements) => {
            const clickedIndex = elements[0]?.index;
            if (clickedIndex !== undefined) {
              const clickedLabel = labels[clickedIndex];
              navigate(
                `/${parkName}/query/patents?patentType=${clickedLabel}&pageSize=10&current=1&keywords=`
              );
            }
          },
        },
      });
      return () => chart.destroy();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterData]);
    return (
      <Spin spinning={status === STATUS.idle}>
        <canvas ref={element} />
      </Spin>
    );
  }
);

export default Chart12;

import { Row, Col, Typography, Anchor, List, Spin } from "antd";
import { Link, useLocation } from "react-router-dom";
import styles from "../query.module.css";
import { NumberOutlined } from "@ant-design/icons";
import { useContext, useEffect, useState } from "react";
import qs from "query-string";

import ShDescription from "../../../components/ShDescription";
import PageHeader from "../../../components/PageHeader";

import { STATUS } from "../../../constants";
import baseDesConfig from "./detailsConfig/baseDesConfig";

import { reqPatentInfo } from "../../../service/query/patent";
import { UserContext } from "../../../context";

export default function Patent() {
  const location = useLocation();
  const [patentInfo, setPatentInfo] = useState([]);
  const [status, setStatus] = useState(STATUS.idle);
  const { parkName } = useContext(UserContext);

  useEffect(() => {
    const parsedQuery = qs.parse(location.search.slice(1), {
      parseNumbers: true,
    });
    reqPatentInfo(parsedQuery.id_pt).then((res) => {
      setPatentInfo(res.data);
      setStatus(STATUS.success);
    });
  }, [location]);
  return (
    <>
      <PageHeader
        title={patentInfo[0]?.patent_name}
        description="展示专利的详细信息。"
        breadcrumb={[
          {
            title: <Link to={`/${parkName}/query/patents`}>专利数据检索</Link>,
          },
          { title: `${patentInfo[0]?.patent_name ?? ""}` },
          // { title: `${location}` },
        ]}
      />
      <Row gutter={32}>
        <Col span={18}>
          <ShDescription
            status={status}
            config={baseDesConfig}
            dataSource={patentInfo}
          />

          <Typography.Title className={styles.title} id="摘要" level={5}>
            <NumberOutlined />
            摘要
          </Typography.Title>
          <div className={styles.section}>
            <Typography>
              <Spin spinning={status === STATUS.success ? false : true}>
                <Typography.Paragraph>
                  {patentInfo[0]?.brief}
                </Typography.Paragraph>
              </Spin>
            </Typography>
          </div>
          <Typography.Title className={styles.title} id="附图" level={5}>
            <NumberOutlined />
            附图
          </Typography.Title>
          <div className={styles.section}>
            {patentInfo[0]?.annex !== null ? (
              <Link to={patentInfo[0]?.annex}>专利证明.pdf</Link>
            ) : (
              ""
            )}
          </div>

          <Typography.Title className={styles.title} id="生命周期" level={5}>
            <NumberOutlined />
            生命周期
          </Typography.Title>
          <div className={styles.section}>
            <List
              renderItem={(item) =>
                item.tiemstamp ? (
                  <List.Item>
                    <List.Item.Meta description={item.tiemstamp} />
                    <div>{item.title}</div>
                  </List.Item>
                ) : (
                  <></>
                )
              }
              dataSource={[
                {
                  tiemstamp: `${patentInfo[0]?.request_date ?? "-"}`,
                  title: "申请",
                },
                {
                  tiemstamp: `${patentInfo[0]?.outhor_date ?? "-"}`,
                  title: "授权",
                },
                // {
                //   tiemstamp: "-",
                //   title: "授权",
                // },
                // {
                //   tiemstamp: `${patentInfo[0]?.end_time ?? "-"}`,
                //   title: "届满",
                // },
              ]}
            />
          </div>
        </Col>
        <Col span={6}>
          <Anchor
            offsetTop={96}
            items={[
              { key: "1", href: "#基本信息", title: "基本信息" },
              { key: "2", href: "#摘要", title: "摘要" },
              { key: "3", href: "#附图", title: "附图" },
              { key: "4", href: "#生命周期", title: "生命周期" },
            ]}
          />
        </Col>
      </Row>
    </>
  );
}

import PageHeader from "../../components/PageHeader";
import { reqAudits } from "../../service/audit";
import { useContext, useEffect, useState } from "react";
import qs from "query-string";
import { Link, useLocation, useParams } from "react-router-dom";
import { STATUS } from "../../constants";
import { UserContext } from "../../context";
import dayjs from "dayjs";
import ShPageModel from "../../components/ShPageModel";
import { Tag } from "antd";

export default function AuditDetails() {
  const [audit, setAudits] = useState([]);
  const { search, pathname } = useLocation();
  const [status, setStatus] = useState(STATUS.idle);
  const [total, setTotal] = useState(1);
  const { parkId, parkName } = useContext(UserContext);
  const { id: userId } = useParams();

  const filterOperation = (operation) => {
    switch (operation) {
      case 1:
        return <Tag color="#1B9C85">登陆</Tag>;
      default:
        return "";
    }
  };
  const auditConfig = {
    nameList: [
      {
        title: "用户",
        dataIndex: "user",
        render(data) {
          return data.name;
        },
      },
      {
        title: "时间",
        dataIndex: "operation_time",
        render: (data, record) => dayjs(data).format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        title: "操作",
        dataIndex: "operation",
        render(data) {
          return filterOperation(data);
        },
      },
    ],
  };
  useEffect(() => {
    const parsedQuery = qs.parse(search.slice(1), {
      parseNumbers: true,
    });
    if (parkId) {
      reqAudits(parsedQuery, parkId, userId).then((res) => {
        setAudits(res.data);
        setTotal(res.count);
        setStatus(STATUS.success);
      });
    }
  }, [parkId, search, userId]);
  useEffect(() => {
    setStatus(STATUS.idle);
  }, [pathname, search]);
  return (
    <>
      <PageHeader
        title={audit[0]?.user.name}
        description="展示用户的各种操作信息，包括：登录信息等。"
        breadcrumb={[
          {
            title: <Link to={`/${parkName}/audit`}>用户审计</Link>,
          },
          { title: `${audit[0]?.user.name ?? ""}` },
        ]}
      />

      <ShPageModel
        status={status}
        config={auditConfig}
        dataSource={audit}
        total={total}
        rowKey={"id"}
        placeholder={"用户名/邮箱"}
      />
    </>
  );
}

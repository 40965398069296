import styles from "./index.module.css";
import { useContext, useEffect, useState } from "react";
import ShPageModel from "../../../components/ShPageModel";
import AddFee from "./financelCpn/addFee";
import { STATUS } from "../../../constants";
import { useLocation, useParams } from "react-router-dom";
import { reqMemberFees } from "../../../service/member/finance";
import { UserContext } from "../../../context";
import qs from "query-string";
import dayjs from "dayjs";

export default function FinanceList() {
  const [status, setStatus] = useState(STATUS.idle);
  const { search } = useLocation();
  const { parkId } = useContext(UserContext);
  const { id } = useParams();
  const [financeList, setFinanceList] = useState([]);
  const [count, setCount] = useState(0);
  const eiditButtons = () => {
    return id === "1" ? (
      <AddFee onStatusChange={handleChildStatusChange} />
    ) : (
      ""
    );
  };
  const financelTableConfig = {
    nameList: [
      {
        title: "公司名称",
        dataIndex: "member",
        render(data) {
          return data.member_name;
        },
      },
      {
        title: "缴费金额（元）",
        dataIndex: "price",
      },
      {
        title: "备注",
        dataIndex: "explain",
      },
      {
        title: "缴费日期",
        dataIndex: "pay_date",
        render(data) {
          return data ? dayjs(data).format("YYYY-MM-DD") : "-";
        },
      },
    ],
  };
  const handleChildStatusChange = (newStatus) => {
    setStatus(newStatus);
  };
  useEffect(() => {
    let parsedQuery = qs.parse(search.slice(1), {
      parseNumbers: true,
    });
    const doPost = async () => {
      await reqMemberFees(parsedQuery, parkId, id).then((res) => {
        setFinanceList(res.data);
        setCount(res.count);
        setStatus(STATUS.success);
      });
    };
    if (STATUS.idle === status && parkId) {
      doPost();
    }
  }, [status, parkId, search, id]);
  useEffect(() => {
    if (status === STATUS.success) {
      setStatus(STATUS.idle);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);
  return (
    <div className={styles.root}>
      <ShPageModel
        status={status}
        config={financelTableConfig}
        dataSource={financeList}
        rowKey={"id"}
        total={count}
        placeholder={"公司名称"}
        eiditButtons={eiditButtons}
      />
    </div>
  );
}

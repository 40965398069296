import { Row, Col, Statistic, Button, Spin } from "antd";
import styles from "./kanban.module.css";
import { Chart16, Chart17, Chart18 } from "../../components/charts";
import PageHeader from "../../components/PageHeader";
import { useNavigate } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { reqCounts, reqKanBanFilterInfo } from "../../service/kanban";
import { UserContext } from "../../context";
import { STATUS } from "../../constants";
import dayjs from "dayjs";
var utc = require("dayjs/plugin/utc");
const span = { xs: 24, sm: 24, md: 24, lg: 12, xl: 8, xxl: 8 };

export default function Kanban1() {
  const navigate = useNavigate();
  const [kanbanFilterInfo, setKanBanFilterInfo] = useState([]);
  const [status, setStatus] = useState(STATUS.idle);

  const [cptotal, setCpTotal] = useState(0);
  const [softtotal, setSoftTotal] = useState(0);
  const { parkId, parkName } = useContext(UserContext);
  const [dateTime, setDateTime] = useState("");

  useEffect(() => {
    dayjs.extend(utc);
    if (parkId) {
      const fetchData = async () => {
        const fetchCpCounts = reqCounts("select_count_cp", parkId);
        const fetchSoftCounts = reqCounts("select_count_soft", parkId);
        const fetchKanBan = reqKanBanFilterInfo(parkId, 4);
        Promise.all([fetchCpCounts, fetchSoftCounts, fetchKanBan]).then(
          ([cpCount, softCount, kanban]) => {
            setCpTotal(cpCount.data);
            setSoftTotal(softCount.data);
            setKanBanFilterInfo(kanban.data);
            setDateTime(kanban.data[0]?.created_at);
            setStatus(STATUS.success);
          }
        );
      };
      if (status === STATUS.idle) {
        fetchData();
      }
    }
  }, [parkId, status]);
  useEffect(() => {
    if (parkId) {
      setStatus(STATUS.idle);
      setKanBanFilterInfo([]);
    }
  }, [parkId]);
  return (
    <Spin spinning={status === STATUS.success ? false : true}>
      <PageHeader
        title="著作权数据统计"
        description="针对入驻园区企业的著作权、软件著作权等数据的统计看板。"
      />
      <div className={styles.timestamp}>
        最后更新时间：
        {dateTime && dayjs.utc(dateTime).format("YYYY年MM月DD日 hh:mm:ss")}
      </div>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <div className={styles.section}>
            <div className={styles.statistic}>
              <div className={styles.item}>
                <Statistic title="著作权登记数量" value={cptotal} />
                <Button
                  type="primary"
                  onClick={() => navigate(`/${parkName}/query/copyrights`)}
                >
                  查看详情
                </Button>
              </div>
              <div className={styles.divider} />
              <div className={styles.item}>
                <Statistic title="软件著作权登记数量" value={softtotal} />
                <Button
                  type="primary"
                  onClick={() => navigate(`/${parkName}/query/software`)}
                >
                  查看详情
                </Button>
              </div>
            </div>
          </div>
        </Col>
        <Col {...span}>
          <div className={styles.section}>
            <div className={styles.chart}>
              <h2>著作权类型分布</h2>
              <Chart16
                softtotal={softtotal}
                kanbanFilterInfo={kanbanFilterInfo}
                status={status}
              />
            </div>
          </div>
        </Col>
        <Col {...span}>
          <div className={styles.section}>
            <div className={styles.chart}>
              <h2>著作权登记数量（近5年）</h2>
              <Chart17 kanbanFilterInfo={kanbanFilterInfo} status={status} />
            </div>
          </div>
        </Col>
        <Col {...span}>
          <div className={styles.section}>
            <div className={styles.chart}>
              <h2>软件著作权登记数量（近5年）</h2>
              <Chart18 kanbanFilterInfo={kanbanFilterInfo} status={status} />
            </div>
          </div>
        </Col>
      </Row>
    </Spin>
  );
}

const contactDesConfig = {
  title: "联系方式",
  column: 2,
  nameList: [
    {
      label: "联系电话",
      span: 1,
      dataIndex: "telephone",
    },
    {
      label: "邮箱",
      span: 1,

      dataIndex: "email",
    },
    {
      label: "地址",
      span: 2,
      dataIndex: "address",
    },
  ],
};
export default contactDesConfig;

import supabase from "..";
export const reqCopyRightsList = async (parsedQuery, parkId) => {
  const defaultQuery = {
    pageSize: 10,
    current: 1,
    keywords: "",
    cpCom: "",
    cpType: "",
    sortFiled: "",
    sortOrder: "",
  };
  // 合并默认查询参数和传入的查询参数
  parsedQuery = { ...defaultQuery, ...parsedQuery };
  const { pageSize, current, keywords } = parsedQuery;
  const start = (current - 1) * pageSize;
  const end = start + pageSize - 1;

  let query = supabase
    .from("park_copyright")
    .select(`*,copyright!inner(*)`, { count: "exact" })
    .eq("park_id", parkId);

  if (parsedQuery.sortFiled === "approval_date") {
    query.order("approval_date_timestamp", {
      ascending: parsedQuery.sortOrder === "ascend" ? true : false,
    });
  }
  if (parsedQuery.cpCom) {
    query.like("copyright.company", `%${parsedQuery.cpCom}%`);
  }
  if (parsedQuery.cpType) {
    query.like("copyright.type_name", `%${parsedQuery.cpType}%`);
  }
  if (keywords) {
    query.or(`name.like.%${keywords}%,number.like.%${keywords}%`, {
      foreignTable: "copyright",
    });
  }

  const { error, data, count } = await query.range(start, end);

  if (error) {
    console.error("获取数据失败:", error.message);
  }

  const result = {
    data: data ? data.map((item) => item.copyright) : [],
    count: count ?? 0,
  };

  return result;
};

import { Tag } from "antd";
const riskTableConfig = {
  title: "经营风险",
  nameList: [
    // 列入日期
    {
      title: "触发时间",
      dataIndex: "in_date",
      render: (data) => data,
    },
    // 处罚部门
    {
      title: "处罚部门",
      dataIndex: "department",
    },

    // 经营异常列入原因
    {
      title: "类型",
      dataIndex: "in_reason",
    },
    // 是否历史：0否1是
    {
      title: "等级",
      dataIndex: "is_history",
      render: (data) =>
        data === 0 ? (
          <Tag color="red">高风险</Tag>
        ) : (
          <Tag color="green">低风险</Tag>
        ),
    },
    // 移除时间
    {
      title: "移除时间",
      dataIndex: "out_date",
    },
    {
      title: "描述",
      dataIndex: "out_reason",
    },
  ],
};
export default riskTableConfig;

import { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

import { Form, Input, Button, Alert, Spin } from "antd";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import Footer from "../components/Footer";
import ParkFn from "../components/ParkFn";
import styles from "./index.module.css";
import { STATUS } from "../constants";

import { reqLogin, reqParks } from "../service/home/login";

export default function Index() {
  const [status, setStatus] = useState(STATUS.idle);
  const [isShow, setIsShow] = useState(STATUS.idle);
  const [loading, setLoading] = useState(false);
  const [park, setPark] = useState();
  const [parkFn, setParkFn] = useState([]);
  const location = useLocation();
  const parkName = location.pathname.split("/").filter(Boolean)[0];
  const pathname = location.state?.referral?.pathname;
  const navigate = useNavigate();
  const onFinish = (values) => {
    const doPost = async () => {
      setStatus(STATUS.loading);
      setLoading(true);
      await reqLogin(values)
        .then((res) => {
          setStatus(STATUS.success);
          setLoading(false);
          return res.data.user.id;
        })
        .then((userId) => {
          if (location.state !== null) {
            navigate(pathname, { replace: true });
          } else {
            navigate(`/${location.pathname.split("/")[1]}/kanban/1`, {
              replace: true,
            });
          }
          return userId;
        })
        .catch((error) => {
          setStatus(STATUS.failure);
          setLoading(false);
        });
    };
    doPost();
  };
  const Email = /^\w+([-.]\w+)*@\w+([.-]\w+)*\.\w{2,4}$/;
  const emailValidator = (_, value) => {
    if (!value || Email.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject("您输入的邮箱格式不正确！");
  };
  useEffect(() => {
    reqParks().then((res) => {
      const filterPark =
        res.data && res.data.find((item) => item.short_name === parkName);
      if (filterPark) {
        setPark(filterPark);
        setParkFn(filterPark.park_fn);
        setIsShow(STATUS.success);
      }
    });
  }, [parkName, location]);

  if (isShow === STATUS.success) {
    return (
      <>
        {park && (
          <section
            style={{
              backgroundImage: `url(${park.bg_img_url})`,
            }}
            className={styles.background}
          >
            <div className={styles.container}>
              <div className={styles.jumbo}>
                <div className={styles.slogan}>
                  <div className={styles.logo}>
                    <img src={park.logo_img_url} alt="logo" />
                  </div>
                  <div className={styles.primary}>
                    
                    {
                      park?.sub_title2 && (
                        <span className={styles.sub}>{park.sub_title2}</span>
                      )
                    }
                    <br />
                    <span className={styles.strong}>
                      {park.park_role ?? "园区品牌管理系统"}
                    </span>
                  </div>

                  <div className={styles.secondary}>
                    {park.sub_title ??
                      "为园区品牌服务赋能，为企业提供高质量的品牌服务"}
                  </div>
                </div>

                <div className={styles.login}>
                  <div className={styles.title}>登录</div>
                  <div className={styles.welcome}>
                    {status !== STATUS.failure ? (
                      <div>
                        本系统由
                        <Link to="https://www.biaoxq.com" target="_blank">
                          环球互通
                        </Link>
                        提供技术支持
                      </div>
                    ) : (
                      <Alert
                        message="用户名或密码错误，请重新登录"
                        type="error"
                      />
                    )}
                  </div>
                  <Form onFinish={onFinish}>
                    <Form.Item
                      name="username"
                      rules={[
                        { required: true, message: "邮箱地址不能为空" },
                        {
                          validator: emailValidator,
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        prefix={<MailOutlined />}
                        placeholder="邮箱"
                      />
                    </Form.Item>
                    <Form.Item name="password">
                      <Input.Password
                        size="large"
                        prefix={<LockOutlined />}
                        placeholder="密码"
                      />
                    </Form.Item>
                    <Form.Item>
                      <Button
                        loading={loading}
                        htmlType="submit"
                        type="primary"
                        size="large"
                        block
                      >
                        登录
                      </Button>
                    </Form.Item>
                    <div>
                      <Link to={`/${parkName}/password-recovery`}>
                        忘记了密码？
                      </Link>
                    </div>
                    {/* <div>
                <Link to="/register">没有账号?立即注册!</Link>
              </div> */}
                  </Form>
                </div>
              </div>
            </div>
          </section>
        )}

        {parkFn.length > 0 ? (
          <ParkFn park={park} parkFn={parkFn} />
        ) : (
          <section>
            <div className={styles.container}>
              <div className={styles.title}>
                <div className={styles.sub}>系统功能介绍</div>
                <h2>
                  {park && park.park_function_introduce
                    ? park.park_function_introduce
                    : `${park && park.park_role}的“五项功能”`}
                </h2>
              </div>
              <div className={styles.cards}>
                <div className={styles.cardItem}>
                  <div className={styles.thumb}>
                    <img src="/icon/chart-mixed-regular.svg" alt="图标" />
                  </div>
                  <div className={styles.title}>数据统计</div>
                  <div className={styles.description}>
                    以最直观的方式，呈现
                    {park && park.park_role}
                    入驻企业的各种品牌统计数据，为
                    {park && park.park_role}
                    企业服务提供运营方向
                  </div>
                  <div className={styles.action}>
                    <Button type="primary" size="large">
                      了解详情
                    </Button>
                  </div>
                </div>
                <div className={styles.cardItem}>
                  <div className={styles.thumb}>
                    <img
                      src="/icon/magnifying-glass-chart-regular.svg"
                      alt="图标"
                    />
                  </div>
                  <div className={styles.title}>数据检索</div>
                  <div className={styles.description}>
                    掌握{park && park.park_role}
                    入驻企业的关键品牌信息，查阅企业的品牌经营状态，为招商决策注入更多信心
                  </div>
                  <div className={styles.action}>
                    <Button type="primary" size="large">
                      了解详情
                    </Button>
                  </div>
                </div>
                <div className={styles.cardItem}>
                  <div className={styles.thumb}>
                    <img src="/icon/monitor-waveform-regular.svg" alt="图标" />
                  </div>
                  <div className={styles.title}>监测预警</div>
                  <div className={styles.description}>
                    全天候监测{park && park.park_role}
                    入驻企业的品牌状态，关注企业潜在品牌问题，树立
                    {park && park.park_role}
                    品牌服务的专业形象
                  </div>
                  <div className={styles.action}>
                    <Button type="primary" size="large">
                      了解详情
                    </Button>
                  </div>
                </div>
                <div className={styles.cardItem}>
                  <div className={styles.thumb}>
                    <img src="/icon/user-tie-hair-regular.svg" alt="图标" />
                  </div>
                  <div className={styles.title}>品牌赋能</div>
                  <div className={styles.description}>
                    提供五大类品牌服务功能，覆盖
                    {park && park.park_role}
                    入驻企业的各类品牌需求，显著提升
                    {park && park.park_role}企业服务能力
                  </div>
                  <div className={styles.action}>
                    <Button type="primary" size="large">
                      了解详情
                    </Button>
                  </div>
                </div>
                <div className={styles.cardItem}>
                  <div className={styles.thumb}>
                    <img src="/icon/comment-smile-regular.svg" alt="图标" />
                  </div>
                  <div className={styles.title}>咨询服务</div>
                  <div className={styles.description}>
                    搭建园企互动平台，即时了解
                    {park && park.park_role}
                    入驻企业的品牌需求，成为
                    {park && park.park_role}
                    企业服务工作的有力抓手
                  </div>
                  <div className={styles.action}>
                    <Button type="primary" size="large">
                      了解详情
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}

        <footer className={styles.footer}>
          <Footer />
        </footer>
      </>
    );
  } else {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
      </div>
    );
  }
}

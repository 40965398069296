import { Row, Col, Card, Skeleton } from "antd";
import PageHeader from "../../components/PageHeader";
import styles from "./empowerment.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useContext, useState } from "react";

import { reqEmpowerment } from "../../service/empowerment";
import { UserContext } from "../../context";
import { STATUS } from "../../constants";
import { range } from "lodash";

export default function Index() {
  const navigate = useNavigate();
  const { parkId, parkName } = useContext(UserContext);
  const [status, setStatus] = useState(STATUS.idle);
  const [empowerments, setEmpowerment] = useState([]);
  const location = useLocation();
  const goto = (id) => navigate(`/${parkName}/empowerment/${id}`);

  useEffect(() => {
    if (parkId) {
      const doPost = () => {
        reqEmpowerment(parkId).then((res) => {
          setEmpowerment(res.data);
          setStatus(STATUS.success);
        });
      };
      if (status === STATUS.idle) {
        doPost();
      }
    }
  }, [parkId, status]);
  useEffect(() => {
    setStatus(STATUS.idle);
  }, [parkId, location]);
  return (
    <>
      <PageHeader
        title="品牌赋能"
        description="赋能园区16项品牌服务能力，提升园区品牌服务水平。"
      />
      <Row gutter={[16, 16]}>
        {empowerments && empowerments.length > 0
          ? empowerments
              .sort((a, b) => {
                const aHasValue = a.img_info_url;
                const bHasValue = b.img_info_url;
                return !!bHasValue - !!aHasValue;
              })
              .map((item, index) => (
                <Col span={6} key={item.id}>
                  <Skeleton loading={status === STATUS.success ? false : true}>
                    <Card
                      loading={status === STATUS.success ? false : true}
                      hoverable
                      cover={
                        <img
                          className={styles.cover}
                          src={item.img_url}
                          alt="封面图"
                        />
                      }
                      actions={["查看详情"]}
                      onClick={() => goto(item.brand_id)}
                    >
                      <Card.Meta
                        className={styles.meta}
                        title={item.title}
                        description={
                          <div>
                            {item.content.split(";")[0]}
                            <br />
                            {item.content.split(";")[1]}
                          </div>
                        }
                      />
                    </Card>
                  </Skeleton>
                </Col>
              ))
          : range(16).map((item, index) => (
              <Col span={6} key={index}>
                <Card
                  style={{
                    height: 420,
                  }}
                >
                  <Skeleton.Image
                    avatar
                    style={{
                      width: 290,
                      height: 200,
                      marginBottom: 40,
                    }}
                    active
                  ></Skeleton.Image>
                  <Skeleton active />
                </Card>
              </Col>
            ))}
        {}
      </Row>
    </>
  );
}

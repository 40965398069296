import supabase from "../index";

export const reqCounts = async (rpcName, parkId) => {
  const { data } = await supabase.rpc(rpcName, {
    query_id: parkId,
  });
  const result = {
    data: data ?? 0,
  };
  return result;
};

export const reqRiskCounts = async (parkId) => {
  const { error, count } = await supabase
    .from("company")
    .select("*", { count: "exact" })
    .eq("park_id", parkId)
    .neq("abnormal_items", "[]");

  if (error) {
    console.error("获取数据失败:", error.message);
  }
  const result = {
    count: count ?? 0,
  };
  return result;
};
export const reqChartsInfo = async (parkId, num, cloumn) => {
  const query = supabase
    .from("data_board")
    .select(`*`, { count: "exact" })
    .eq("park_id", parkId)
    .eq("page_num", num)
    .eq("cloumn", cloumn);

  const { data, count, error } = await query;
  if (error) {
    console.error("获取数据失败:", error.message);
  }
  const result = {
    data: data ? data.map((item) => JSON.parse(item.context)) : [],
    count: count ?? 0,
  };
  return result;
};

export const reqKanBanFilterInfo = async (parkId, num) => {
  const query = supabase
    .from("data_board")
    .select(`*`, { count: "exact" })
    .eq("park_id", parkId)
    .eq("page_num", num);

  const { data, count, error } = await query;
  if (error) {
    console.error("获取数据失败:", error.message);
  }
  const result = {
    data: data
      ? data.map((item) => ({ ...item, context: JSON.parse(item.context) }))
      : [],
    count: count ?? 0,
  };
  return result;
};

import { STATUS } from "../../constants";

import { Link, useLocation, useNavigate } from "react-router-dom";
import qs from "query-string";
import { useEffect, useState, useContext } from "react";

import ShPageModel from "../../components/ShPageModel";
import PageHeader from "../../components/PageHeader";
import { reqCompaniesRiskLists } from "../../service/query/companies";
import { UserContext } from "../../context";
import { reqAxiosriksLists } from "../../api/lists";
import { Typography } from "antd";

export default function Index() {
  const [status, setStatus] = useState(STATUS.idle);
  const [CompaniesList, setCompaniesList] = useState([]);
  const [total, setTotal] = useState(1);
  const location = useLocation();
  const { parkId, parkName } = useContext(UserContext);
  const [sort1, setSort1] = useState("");
  const [sort2, setSort2] = useState("");
  const [sort3, setSort3] = useState("");

  const handleStatus = (status) => {
    setStatus(status);
  };
  const navigate = useNavigate();
  const [query, setQuery] = useState({});

  // 声明一个状态变量用于保存选中的行
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  // 声明一个状态变量用于保存 id 的数组
  const [monitorIds, setMonitorIds] = useState([]);

  // 当复选框状态改变时触发
  const onSelectChange = (newSelectedRowKeys, selectedRows, dataSource, rowKey) => {
    // console.log(`selectedRowKeys: ${newSelectedRowKeys}`, 'selectedRows: ', selectedRows);

    // 获取当前页面的选中行的 id
    const ids = selectedRows.map(row => row.id);

    // 从数据源中提取出每一项的行键（默认为 'id'），生成当前页面所有行的键数组
    const currentPageKeys = dataSource.map(item => item[rowKey ?? 'id']);

    // 更新选中的行键数组：
    // 1. 保留当前选中行键中那些在当前页面中的行键，并且这些行键仍然被新选中的行键包含。
    // 2. 保留当前选中行键中那些不在当前页面中的行键（这些行键不会被新选中的行键影响）。
    const updatedSelectedRowKeys = selectedRowKeys.filter(key =>
      currentPageKeys.includes(key) ? newSelectedRowKeys.includes(key) : true
    );

    // 更新选中的 id 数组：
    // 1. 保留当前选中 id 中那些在当前页面中的 id，并且这些 id 仍然被新选中的行数据包含。
    // 2. 保留当前选中 id 中那些不在当前页面中的 id（这些 id 不会被新选中的行数据影响）。
    const updatedMonitorIds = monitorIds.filter(id =>
      currentPageKeys.includes(dataSource.find(item => item.id === id)?.[rowKey ?? 'id']) ? ids.includes(id) : true
    );

    // 遍历新选中的行键数组，将不在 updatedSelectedRowKeys 中的行键添加进去
    newSelectedRowKeys.forEach(key => {
      if (!updatedSelectedRowKeys.includes(key)) {
        updatedSelectedRowKeys.push(key);
      }
    });

    // 遍历新选中的 id 数组，将不在 updatedMonitorIds 中的 id 添加进去
    ids.forEach(id => {
      if (!updatedMonitorIds.includes(id)) {
        updatedMonitorIds.push(id);
      }
    });

    // 更新状态
    setSelectedRowKeys(updatedSelectedRowKeys);
    setMonitorIds(updatedMonitorIds);
  };

  // 表格行选择配置
  const rowSelection = {
    selectedRowKeys,  // 当前选中的行键数组
    // 当行选择变化时，调用 onSelectChange 函数，并传递新选中的行键、新选中的行数据、当前页面的数据源和行键字段（默认为 'id'）
    onChange: (newSelectedRowKeys, selectedRows) => onSelectChange(newSelectedRowKeys, selectedRows, CompaniesList, 'id'),
  };

  // 清空复选框选中状态
  const resetSelection = () => {
    // 清空复选框选中状态
    setSelectedRowKeys([]);
    // 清空 id 数组
    setMonitorIds([]);
  };

  const monitoringTableConfig = {
    nameList: [
      {
        title: "企业名称",
        width: 258,
        fixed: "left",
        dataIndex: "format_name",
        render: (data, record) => (
          <Link
            to={{
              pathname: `/${parkName}/monitoring/${record.id}`,
              search: qs.stringify({ company_id: record.id }),
            }}
          >
            {data}
          </Link>
        ),
      },
      {
        title: "统一社会信用代码",
        dataIndex: "reg_no",
      },
      {
        title: "所属行业",
        dataIndex: "domains",
        render: (data) =>
          data && JSON.parse(data).map((item) => <div key={item}>{item}</div>),
      },
      {
        title: "经营风险",
        dataIndex: "abnormal_count",
        align: "center",
        render: (data) =>
          data > 0 ? (
            <Typography.Text type="danger" strong>
              {data}
            </Typography.Text>
          ) : (
            ""
          ),
        sorter: true,
        sortOrder: sort1,
      },
      {
        title: "商标预警",
        dataIndex: "risk_trademark_count",
        align: "center",
        render: (data) =>
          data > 0 ? (
            <Typography.Text type="danger" strong>
              {data}
            </Typography.Text>
          ) : (
            ""
          ),
        sorter: true,
        sortOrder: sort2,
      },
      {
        title: "专利预警",
        dataIndex: "risk_patent_count",
        align: "center",
        render: (data) =>
          data > 0 ? (
            <Typography.Text type="danger" strong>
              {data}
            </Typography.Text>
          ) : (
            ""
          ),
        sorter: true,
        sortOrder: sort3,
      },
    ],
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const newQuery = {
      // query 在 useEffect中已经被替换为新的值
      ...query,
      sortFiled: sorter.field,
      sortOrder: sorter.order,
    };
    let newUrl = `?${Object.entries(newQuery)
      .map(([key, value]) => `${key}=${value ?? ""}`)
      .join("&")}`;
    navigate(newUrl);
  };

  useEffect(() => {
    let parsedQuery = qs.parse(location.search.slice(1), {
      parseNumbers: true,
    });

    setSort1(
      parsedQuery.sortFiled === "abnormal_count" ? parsedQuery.sortOrder : ""
    );
    setSort2(
      parsedQuery.sortFiled === "risk_trademark_count"
        ? parsedQuery.sortOrder
        : ""
    );
    setSort3(
      parsedQuery.sortFiled === "risk_patent_count" ? parsedQuery.sortOrder : ""
    );

    setQuery(parsedQuery);

    if (parkId) {
      const parsedQuery = qs.parse(location.search.slice(1), {
        parseNumbers: true,
      });
      const doPost = () => {
        reqCompaniesRiskLists(parsedQuery, parkId).then((res) => {
          setCompaniesList(res.data);
          setTotal(res.count);
          setStatus(STATUS.success);
        });
      };
      if (status === STATUS.idle) {
        doPost();
      }
    }
  }, [location, parkId, status]);
  useEffect(() => {
    setStatus(STATUS.idle);
  }, [parkId, location]);
  return (
    <>
      <PageHeader
        title="监测预警"
        description="针对入驻园区企业的经营状态，商标、专利等知识产权情况进行检测，并提供预警信息。"
      />

      <ShPageModel
        placeholder="企业名称/统一社会信用代码"
        changeStatus={handleStatus}
        status={status}
        config={monitoringTableConfig}
        dataSource={CompaniesList}
        total={total}
        reqExport={reqAxiosriksLists}
        handleTableChange={handleTableChange}
        rowSelection={rowSelection} // 复选框: 传递 rowSelection 属性
        monitorIds={monitorIds} // 传递 monitorIds
        resetSelection={resetSelection} // 清空复选框: 传递 resetSelection 属性
      />
    </>
  );
}

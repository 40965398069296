import { createClient } from "@supabase/supabase-js"; // 从 @supabase/supabase-js 导入 createClient 函数

// 根据当前的 NODE_ENV 环境变量来选择合适的 Supabase URL
const supabaseUrl = process.env.NODE_ENV === 'development'
  ? process.env.REACT_APP_SUPABASE_URL_DEV // 如果是开发环境，使用开发环境的 URL
  : process.env.REACT_APP_SUPABASE_URL_PROD; // 否则，使用生产环境的 URL

// 根据当前的 NODE_ENV 环境变量来选择合适的 Supabase 密钥
const supabaseKey = process.env.NODE_ENV === 'development'
  ? process.env.REACT_APP_SUPABASE_KEY_DEV // 如果是开发环境，使用开发环境的密钥
  : process.env.REACT_APP_SUPABASE_KEY_PROD; // 否则，使用生产环境的密钥

// 使用选定的 URL 和密钥创建 Supabase 客户端
const supabase = createClient(supabaseUrl, supabaseKey);

export default supabase; // 导出创建的 Supabase 客户端实例





// import { createClient } from "@supabase/supabase-js";

// // 测试数据库
// const supabase = createClient(
//   "https://bdosijyfedqgntwjnwim.supabase.co",
//   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJkb3NpanlmZWRxZ250d2pud2ltIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTg3NjEwNDYsImV4cCI6MjAzNDMzNzA0Nn0.eXHWXhBWpGE4dAM4E3qQpOonOvHj71l6HWFXnkOVbOw"
// );

// // 正式数据库
// // const supabase = createClient(
// //   "https://zevgwqoenxkhkvornmdw.supabase.co",
// //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Inpldmd3cW9lbnhraGt2b3JubWR3Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODY1MzU3NTIsImV4cCI6MjAwMjExMTc1Mn0.UdjdfE_8Q4JiUC8KdFYC-zAwJJDrfiO6mlUkEdQKA_U"
// // );

// // const supabase = createClient(
// //   "http://121.43.154.134:8000/",
// //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyAgCiAgICAicm9sZSI6ICJhbm9uIiwKICAgICJpc3MiOiAic3VwYWJhc2UtZGVtbyIsCiAgICAiaWF0IjogMTY0MTc2OTIwMCwKICAgICJleHAiOiAxNzk5NTM1NjAwCn0.dc_X5iR_VP_qT0zsiyj_I_OZ2T9FtRU2BBNWN8Bu4GE"
// // );
// export default supabase;

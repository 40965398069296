import { Col, Form, Select } from "antd";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { UserContext } from "../../../context";
import { reqDomains } from "../../../service/query/companies";
import qs from "query-string";
export default function RiskFilter({ form }) {
  const { pathname, search } = useLocation();
  const { parkId } = useContext(UserContext);
  const [show, setShow] = useState(false);
  const [domains, setDomains] = useState([]);

  useEffect(() => {
    if (parkId && pathname.indexOf("/monitoring") !== -1) {
      setShow(true);
      reqDomains(parkId).then((res) => {
        setDomains(res.data);
      });
      let parsedQuery = qs.parse(search.slice(1), {
        parseNumbers: true,
      });
      Object.entries(parsedQuery).forEach(([key, value]) => {
        value && form.setFieldValue(key, value);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parkId, pathname, search]);
  return (
    <>
      {show && (
        <>
          <Col>
            <Form.Item name="riskType">
              <Select
                onChange={() => form.submit()}
                placeholder="风险类型"
                allowClear
                showSearch
                style={{ minWidth: "160px" }}
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                options={[
                  {
                    value: 1,
                    label: "经营风险",
                  },
                  {
                    value: 2,
                    label: "商标风险",
                  },
                  {
                    value: 3,
                    label: "专利风险",
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name="riskIndustory">
              <Select
                onChange={() => form.submit()}
                placeholder="所属行业"
                allowClear
                showSearch
                style={{ minWidth: "160px" }}
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                options={(domains || []).map((d) => ({
                  value: JSON.parse(d)[0],
                  label: JSON.parse(d)[0],
                }))}
              />
            </Form.Item>
          </Col>
        </>
      )}
    </>
  );
}

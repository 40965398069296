import {
  Button,
  Col,
  Modal,
  Row,
  Select,
  Form,
  Radio,
  DatePicker,
  Input,
  notification,
  Tooltip,
} from "antd";
import { useForm } from "antd/es/form/Form";
import { useContext, useState } from "react";
import { STATUS } from "../../../../constants";
import { UserContext } from "../../../../context";
import { activity_status, activity_type } from "../../../../intlClass";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";
import { reqUpdateActivity } from "../../../../service/member/activity/details";
export default function EditActivity({
  onStatusChange,
  data,
  title = "活动配置",
}) {
  const [form] = useForm();
  const [open, setOpen] = useState(false);
  const { parkId } = useContext(UserContext);
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isShowFee, setShowFee] = useState(false);

  const onChangeFee = (e) => {
    setShowFee(e.target.value);
  };
  const onFinish = async () => {
    try {
      const value = await form.validateFields();
      setLoading(true);
      const activityData = {
        meeting_date: value.meeting_date,
        status: value.status,
        meeting_title: value.meeting_title,
        issue_type: value.issue_type,
        meeting_fee: value.rates === 0 ? 0 : value.meeting_fee,
        park_id: parkId,
        content: value.meeting_content,
        id: data.id,
      };
      await reqUpdateActivity(activityData).then((res) => {
        api.success({
          message: "活动修改成功",
          duration: 1,
          placement: "top",
          style: {
            marginTop: "3rem",
          },
        });
        setOpen(false);
        setLoading(false);
        onStatusChange(STATUS.idle);
      });
    } catch (error) {}
  };

  return (
    <>
      {contextHolder}

      <div>
        <Button ghost type="test" onClick={() => setOpen(true)}>
          编辑
        </Button>
      </div>
      <Modal
        title={title}
        forceRender
        open={open}
        onOk={onFinish}
        onCancel={() => setOpen(false)}
        width={720}
        confirmLoading={loading}
        destroyOnClose={true}
      >
        <Row>
          <Col span={20}>
            <Form wrapperCol={{ span: 16 }} labelCol={{ span: 6 }} form={form}>
              <Form.Item
                initialValue={data.meeting_title}
                name="meeting_title"
                label="活动主题"
                rules={[
                  {
                    required: true,
                    message: "请输入活动主题",
                  },
                ]}
              >
                <Input placeholder="请输入活动主题" />
              </Form.Item>
              <Form.Item
                initialValue={data.content}
                name="meeting_content"
                label="主要内容"
              >
                <TextArea
                  rows={4}
                  maxLength={200}
                  placeholder="请输入活动主题"
                />
              </Form.Item>
              <Form.Item
                initialValue={dayjs(data.meeting_date)}
                name="meeting_date"
                label="活动时间"
                rules={[
                  {
                    required: true,
                    message: "请选择活动时间",
                  },
                ]}
              >
                <DatePicker />
              </Form.Item>
              <Form.Item
                initialValue={data.issue_type}
                name="issue_type"
                label="活动类型"
                rules={[
                  {
                    required: true,
                    message: "请选择活动类型",
                  },
                ]}
              >
                <Radio.Group
                  options={activity_type.map((item) => {
                    return {
                      label: item.title,
                      value: item.id,
                    };
                  })}
                ></Radio.Group>
              </Form.Item>
              <Form.Item
                initialValue={data.meeting_fee === 0 ? 0 : 1}
                name="rates"
                label="是否收费"
                rules={[
                  {
                    required: true,
                    message: "请选择活动类型",
                  },
                ]}
              >
                <Radio.Group
                  onChange={onChangeFee}
                  options={[
                    {
                      value: 1,
                      label: "收费",
                    },
                    {
                      value: 0,
                      label: "免费",
                    },
                  ]}
                ></Radio.Group>
              </Form.Item>
              <Form.Item
                initialValue={data.meeting_fee === 0 ? "" : data.meeting_fee}
                name="meeting_fee"
                label="活动费用"
                rules={[
                  {
                    required: form.getFieldValue("rates"),
                    message: "请输入活动费用",
                  },
                  // 费用格式规则
                  {
                    pattern:
                      /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/,
                    message: "费用格式错误",
                  },
                ]}
              >
                <Input
                  disabled={!form.getFieldValue("rates")}
                  placeholder="请输入费用"
                />
              </Form.Item>
              <Row>
                <Col span="21">
                  <Form.Item
                    labelCol={{ span: 7 }}
                    label="活动状态"
                    name={"status"}
                    initialValue={data.status}
                    rules={[
                      {
                        required: true,
                        message: "请选择活动状态",
                      },
                    ]}
                  >
                    <Select
                      options={activity_status.map((item) => ({
                        value: item.id,
                        label: item.title,
                      }))}
                    ></Select>
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Tooltip
                    title={
                      <>
                        <p>活动状态：</p>
                        <p>
                          已发送: <br />
                          已发布当前活动通知且活动未开始
                        </p>
                        <p>
                          进行中:
                          <br />
                          活动进行当日
                          <br />
                          （00:00:00~23:59:59）
                        </p>
                        <p>
                          已完成:
                          <br />
                          活动已经结束
                        </p>
                      </>
                    }
                  >
                    <Button type="primary" shape="circle" ghost>
                      ?
                    </Button>
                  </Tooltip>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

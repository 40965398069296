import { useContext, useEffect, useState } from "react";
import ShPageModel from "../../../components/ShPageModel";
import { STATUS } from "../../../constants";
import { useLocation, useParams, Link } from "react-router-dom";
// import { reqCompanyWithKeyWords } from "../../../service/empowerment/createmodel";
// import { reqInsertMember } from "../../../service/member/vip";
import {
  HandlerRender1,
  HandlerRender2,
  HandlerRender3,
} from "./hook/handleRender";
import AddMember from "./cpn/addMember";
import dayjs from "dayjs";
import qs from "query-string";
import { reqMember } from "../../../service/member/vip";
import { UserContext } from "../../../context";
import { fee_status } from "../../../intlClass";
// import { Button } from "antd";
import { Badge, Button } from "antd";

export default function VipList() {
  const [status, setStatus] = useState(STATUS.idle);
  const { id } = useParams();
  const { parkId, parkName } = useContext(UserContext);
  const [total, setTotal] = useState(0);
  const { search } = useLocation();
  const [viplist, setViplist] = useState([]);
  // const [data, setData] = useState([]);

  // 声明一个状态变量用于保存选中的行
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  // 声明一个状态变量用于保存 id 的数组
  const [memberIds, setMemberIds] = useState([]);

  // 当复选框状态改变时触发
  const onSelectChange = (newSelectedRowKeys, selectedRows, dataSource, rowKey) => {
    // console.log(`selectedRowKeys: ${newSelectedRowKeys}`, 'selectedRows: ', selectedRows);

    // 获取当前页面的选中行的 id
    const ids = selectedRows.map(row => row.id);

    // 从数据源中提取出每一项的行键（默认为 'id'），生成当前页面所有行的键数组
    const currentPageKeys = dataSource.map(item => item[rowKey ?? 'id']);

    // 更新选中的行键数组：
    // 1. 保留当前选中行键中那些在当前页面中的行键，并且这些行键仍然被新选中的行键包含。
    // 2. 保留当前选中行键中那些不在当前页面中的行键（这些行键不会被新选中的行键影响）。
    const updatedSelectedRowKeys = selectedRowKeys.filter(key =>
      currentPageKeys.includes(key) ? newSelectedRowKeys.includes(key) : true
    );

    // 更新选中的 id 数组：
    // 1. 保留当前选中 id 中那些在当前页面中的 id，并且这些 id 仍然被新选中的行数据包含。
    // 2. 保留当前选中 id 中那些不在当前页面中的 id（这些 id 不会被新选中的行数据影响）。
    const updatedMemberIds = memberIds.filter(id =>
      currentPageKeys.includes(dataSource.find(item => item.id === id)?.[rowKey ?? 'id']) ? ids.includes(id) : true
    );

    // 遍历新选中的行键数组，将不在 updatedSelectedRowKeys 中的行键添加进去
    newSelectedRowKeys.forEach(key => {
      if (!updatedSelectedRowKeys.includes(key)) {
        updatedSelectedRowKeys.push(key);
      }
    });

    // 遍历新选中的 id 数组，将不在 updatedMemberIds 中的 id 添加进去
    ids.forEach(id => {
      if (!updatedMemberIds.includes(id)) {
        updatedMemberIds.push(id);
      }
    });

    // 更新状态
    setSelectedRowKeys(updatedSelectedRowKeys);
    setMemberIds(updatedMemberIds);
  };

  // 表格行选择配置
  const rowSelection = {
    selectedRowKeys,  // 当前选中的行键数组
    // 当行选择变化时，调用 onSelectChange 函数，并传递新选中的行键、新选中的行数据、当前页面的数据源和行键字段（默认为 'id'）
    onChange: (newSelectedRowKeys, selectedRows) => onSelectChange(newSelectedRowKeys, selectedRows, viplist, 'id'),
  };

  // 清空复选框选中状态
  const resetSelection = () => {
    // console.log("调用了清空复选框选中状态");
    // 清空复选框选中状态
    setSelectedRowKeys([]);
    // 清空 id 数组
    setMemberIds([]);
  };

  const VipTableConfig = {
    nameList: [
      {
        // 表示该列的标题，即列头显示的文字
        title: "会员名称",
        // 数据索引，用于从数据源中提取相应的数据字段
        dataIndex: "member_name",
        // 渲染函数，用于自定义单元格内容的显示
        render: (data, record) => (
          // Link 组件用于在不重新加载页面的情况下导航到另一个路由
          <Link
            // to 属性指定导航的目标路径和查询参数
            to={{
              // pathname 属性设置目标路径，其中 parkName 是动态的部分
              pathname: `/${parkName}/query/companies/${record.company_id}`,
              // search 属性设置查询参数，这里将 company_id 作为查询参数添加到路径中
              search: qs.stringify({ company_id: record.company_id }),
            }}
            // state 属性将当前记录（record）作为状态传递给目标路由
            state={record}
          >
            {/* 显示的数据，即从数据源中提取的 member_name 字段的值 */}
            {data}
          </Link>
        ),
      },
      {
        title: "会费状态",
        dataIndex: "fee_status",
        sorter: (a, b) => a.fee_status - b.fee_status, // 添加排序功能
        render(data) {
          const feeStatus = fee_status.find((item) => item.id === data);
          let color;
          switch (data) {
            case 1:
              color = 'green';
              break;
            case 2:
              color = 'red';
              break;
            case 3:
              color = 'blue';
              break;
            case 4:
              color = 'orange';
              break;
            default:
              color = 'grey';
          }
          return (
            <Badge color={color} text={feeStatus ? feeStatus.title : '未知状态'} />
          );
        },
      },
      {
        title: "入会时间",
        dataIndex: "join_date",
        sorter: (a, b) => dayjs(a.join_date).unix() - dayjs(b.join_date).unix(), // 添加排序功能
        render(data) {
          return data ? dayjs(data).format("YYYY-MM-DD") : "-";
        },
      },
      {
        title: "姓名",
        dataIndex: "contacts",
        render: (contacts) => (contacts && contacts.length > 0 ? contacts[0].name : ""),
      },
      {
        title: "手机号码",
        dataIndex: "contacts",
        render: (contacts) => (contacts && contacts.length > 0 ? contacts[0].phone : ""),
      },
      {
        title: "操作",
        render(data, record) {
          return (
            <HandlerRender1
              onStatusChange={handleChildStatusChange}
              data={data}
              record={{
                ...record,
                join_date: record.join_date ? dayjs(record.join_date) : "",
              }}
              config={VipTableConfig}
            />
          );
        },
      },
    ],
  };
  const VipTableConfig2 = {
    nameList: [
      {
        title: "申请用户",
        dataIndex: "member_name",
      },

      {
        title: "提交时间",
        dataIndex: "created_at",
        render(data) {
          return data ? dayjs(data).format("YYYY-MM-DD") : "-";
        },
      },
      {
        title: "手机号码",
        dataIndex: "phone",
      },
      {
        title: "操作",
        render(data, record) {
          return (
            <HandlerRender2
              onStatusChange={handleChildStatusChange}
              data={data}
            />
          );
        },
      },
    ],
  };
  const VipTableConfig3 = {
    nameList: [
      {
        title: "申请用户",
        dataIndex: "member_name",
      },

      {
        title: "提交时间",
        dataIndex: "created_at",
        render(data, record) {
          return data ? dayjs(data).format("YYYY-MM-DD") : "-";
        },
      },
      {
        title: "手机号码",
        dataIndex: "phone",
      },
      {
        title: "操作",
        render(data, record) {
          return (
            <HandlerRender3
              onStatusChange={handleChildStatusChange}
              data={data}
            />
          );
        },
      },
    ],
  };
  function handleChildStatusChange(newStatus) {
    setStatus(newStatus);
  }
  const eiditButtons = () => {
    return id === "1" ? (
      <AddMember
        onStatusChange={handleChildStatusChange}
        memberIds={memberIds} // 传递 memberIds
        resetSelection={resetSelection} // 清空复选框: 传递 resetSelection 属性
      />
    ) : (
      ""
    );
  };
  const filterConfig = (id) => {
    switch (id) {
      case "1":
        return VipTableConfig;
      case "2":
        return VipTableConfig2;
      case "3":
        return VipTableConfig3;

      default:
        return { nameList: [] };
    }
  };
  useEffect(() => {
    let parsedQuery = qs.parse(search.slice(1), {
      parseNumbers: true,
    });
    // if (parkId) {
    //   reqCompanyWithKeyWords(parkId).then((res) => {
    //     setData(res.data);
    //   });
    // }
    const doPost = () => {
      reqMember(parsedQuery, parkId, id).then((res) => {
        // console.log("res--会员管理数据", res);
        setViplist(res.data);
        setTotal(res.count);
        setStatus(STATUS.success);
      });
    };
    if (parkId && status === STATUS.idle) {
      doPost();
    }
  }, [id, parkId, status, search]);
  useEffect(() => {
    if (status === STATUS.success) {
      setStatus(STATUS.idle);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);
  // const handleClick = () => {
  //   const filterData = data.map((item, index) => ({
  //     company_id: item.id,
  //     member_name: item.format_name,
  //     park_id: parkId,
  //     status: 1,
  //     fee_status: 1,
  //     join_date: dayjs(),
  //   }));
  //   filterData.map((item) => reqInsertMember(item));
  // };
  return (
    <div>
      {/* <div>
        <Button onClick={() => handleClick()}>添加会员</Button>
      </div> */}
      <ShPageModel
        config={filterConfig(id)}
        status={status}
        dataSource={viplist}
        rowKey={"id"}
        placeholder={"会员编号/会员名称"}
        total={total}
        eiditButtons={eiditButtons}
        // 只有在 id 等于 "1" 时才传递以下三个属性
        rowSelection={id === "1" ? rowSelection : undefined} // 复选框: 传递 rowSelection 属性
      />
    </div>
  );
}

import { createContext, useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { reqParkName } from "./service/home/login";
const RoleContext = createContext();

function useRole() {
  const [role, setRole] = useState();
  const location = useLocation();
  const parkName = location?.pathname.split("/").filter(Boolean)[0];

  return {
    role,
    async fetchRole(parkname = parkName) {
      return await reqParkName(parkname).then((res) => {
        if (res.data.length > 0) {
          setRole(res.data[0].role);
        }
      });
    },
  };
}

export function RoleProvider({ children }) {
  const parkRole = useRole();
  const { role, fetchRole } = parkRole;
  if (role === undefined) {
    fetchRole();
  }
  return (
    <RoleContext.Provider value={parkRole}>{children}</RoleContext.Provider>
  );
}
export default function RoleConsumer() {
  return useContext(RoleContext);
}

export function RequireRole({ children }) {
  const { role } = RoleConsumer();

  return <>{role === 1 && children}</>;
}

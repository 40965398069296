import {
  Row,
  Col,
  Anchor,
  Typography,
  Descriptions,
  Tag,
  Avatar,
  List,
  Spin,
  Button,
  Image,
} from "antd";
import { Link, useLocation } from "react-router-dom";

import {
  NumberOutlined,
  PaperClipOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import PageHeader from "../../components/PageHeader";
import CreateModal from "./Create.Modal";
import styles from "./consulting.module.css";
import { reqOrderInfoWithId, reqComments } from "../../service/consulting";
import { reqParkName, reqDown } from "../../service/consulting";
import { useEffect, useContext, useState } from "react";
import { UserContext } from "../../context";
import { STATUS } from "../../constants";
import { clazzes } from "../../intlClass";
import dayjs from "dayjs";
import { reqTmgoodInfoNo } from "../../service/management/transaction";

export default function Details() {
  const location = useLocation();
  const { parkName, parkId } = useContext(UserContext);
  const [status, SetStatus] = useState(STATUS.idle);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [comments, setComments] = useState([]);
  const [parkname, setParkName] = useState("");
  const [orderInfo, SetOrderInfo] = useState([]);
  const [goodInfo, setGoodInfo] = useState();

  const { pathname } = location;
  const orderId = pathname.split("/").filter(Boolean)[2];
  const showStatus = (status) => {
    switch (status) {
      case 0:
        return <Tag color="grey">待受理</Tag>;
      case 1:
        return <Tag color="yellow">受理中</Tag>;
      case 2:
        return <Tag color="#1B9C85">已完成</Tag>;
      case 3:
        return <Tag color="red">已取消</Tag>;
      default:
        return "";
    }
  };
  const handleDown = (e, name, oldName) => {
    e.preventDefault();
    reqDown(name).then((res) => {
      const url = window.URL.createObjectURL(res.data);
      const link = document.createElement("a");
      link.href = url;
      link.download = oldName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    });
  };
  useEffect(() => {
    if (parkId && orderId) {
      reqParkName(parkId).then((res) => {
        setParkName(res.data[0].name);
      });
      reqOrderInfoWithId(parkId, orderId)
        .then((res) => {
          SetOrderInfo(res.data[0]);
          return res.data[0].goods_id;
        })
        .then((id) => {
          if (id) {
            reqTmgoodInfoNo(id).then((res) => {
              setGoodInfo(res.data[0]);
            });
          }
        });
      reqComments(parkId, orderId).then((res) => {
        setComments(res.data);
        SetStatus(STATUS.success);
      });
    }
  }, [parkId, orderId, isModalOpen]);
  return (
    <Spin spinning={status === STATUS.success ? false : true}>
      <PageHeader
        title={
          orderInfo && orderInfo.brand_empowerment
            ? orderInfo.brand_empowerment.title + "业务咨询"
            : ""
        }
        description="查阅来自企业品牌相关的咨询内容，第一时间了解企业需求，向企业输出专业的品牌服务。"
        breadcrumb={[
          { title: <Link to={`/${parkName}/consulting`}>监测预警</Link> },
          {
            title: ` ${
              orderInfo && orderInfo.brand_empowerment
                ? orderInfo.brand_empowerment.title + "业务咨询"
                : ""
            }`,
          },
        ]}
      />
      <Row gutter={32}>
        <Col span={18}>
          <Typography.Title className={styles.title} id="基本信息" level={5}>
            <NumberOutlined />
            基本信息
          </Typography.Title>
          <Descriptions
            column={2}
            className={styles.descriptions}
            labelStyle={{ whiteSpace: "nowrap" }}
            bordered
          >
            <Descriptions.Item label="咨询单号">
              {orderInfo && orderInfo.id
                ? `HQHT${
                    dayjs(orderInfo.created_time).format("YYMMDD") +
                    orderInfo.id
                  }`
                : ""}
            </Descriptions.Item>
            <Descriptions.Item label="咨询业务名称">
              <Avatar
                style={{ marginRight: ".5rem", backgroundColor: "#333333" }}
              >
                S
              </Avatar>
              {orderInfo && orderInfo.brand_empowerment
                ? orderInfo.brand_empowerment.title
                : ""}
            </Descriptions.Item>
            {goodInfo && (
              <Descriptions.Item label="咨询商标">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Image
                    width={64}
                    style={{ border: "1px solid #ccc" }}
                    src={`https://biaoxq-oss.oss-cn-beijing.aliyuncs.com/${goodInfo.request_no}.jpg`}
                    alt="封面图"
                  />
                  <div style={{ marginLeft: "1rem" }}>
                    <div>{goodInfo.mark_name}</div>
                    <div>
                      {"第" +
                        goodInfo.good_kind +
                        "类-" +
                        clazzes[goodInfo.good_kind - 1]?.title}
                    </div>
                  </div>
                </div>
              </Descriptions.Item>
            )}
            <Descriptions.Item label="服务状态">
              {showStatus(orderInfo && orderInfo.status)}
            </Descriptions.Item>
            <Descriptions.Item label="咨询时间">
              {orderInfo && dayjs(orderInfo.created_time).format("YYYY-MM-DD")}
            </Descriptions.Item>
            <Descriptions.Item label="最后更新时间">
              {orderInfo && dayjs(orderInfo.update_time).format("YYYY-MM-DD")}
            </Descriptions.Item>
          </Descriptions>
          <Typography.Title className={styles.title} id="咨询人" level={5}>
            <NumberOutlined />
            咨询人
          </Typography.Title>
          <Descriptions
            column={2}
            className={styles.descriptions}
            labelStyle={{ whiteSpace: "nowrap" }}
            bordered
          >
            <Descriptions.Item label="咨询人">
              {orderInfo && orderInfo.name}
            </Descriptions.Item>
            <Descriptions.Item label="手机">
              {orderInfo && orderInfo.tel}
            </Descriptions.Item>
            <Descriptions.Item label="咨询人职务">
              {orderInfo && orderInfo.duties}
            </Descriptions.Item>
            <Descriptions.Item label="咨询人公司">
              {orderInfo && orderInfo.company_name}
            </Descriptions.Item>
          </Descriptions>

          <div className={styles.toolbar}>
            <Typography.Title className={styles.title} id="沟通记录" level={5}>
              <NumberOutlined />
              沟通记录
            </Typography.Title>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setIsModalOpen(true)}
            >
              添加记录
            </Button>
            <CreateModal
              open={isModalOpen}
              onOk={() => setIsModalOpen(false)}
              onCancel={() => setIsModalOpen(false)}
              orderId={orderId}
            />
          </div>
          <div className={styles.section}>
            <List
              itemLayout="vertical"
              size="large"
              dataSource={comments}
              renderItem={(item) => (
                <List.Item
                  key={item.id}
                  extra={
                    <Typography.Text type="secondary">
                      {dayjs(item.created_at).format("YYYY-MM-DD hh:mm:ss")}
                    </Typography.Text>
                  }
                >
                  <List.Item.Meta
                    avatar={
                      <Avatar size="large">
                        {item.role_flag === 0 ? "客服" : orderInfo.name}
                      </Avatar>
                    }
                    description={
                      item.role_flag === 0 ? parkname : orderInfo.company_name
                    }
                    title={item.role_flag === 0 ? "客服" : orderInfo.name}
                  />
                  <div>{item.content}</div>
                  {item.order_file.length > 0 && (
                    <div className={styles.attachments}>
                      {item.order_file.map((item) => (
                        <Link
                          onClick={(e) =>
                            handleDown(e, item.file_name, item.old_name)
                          }
                          key={item.id}
                          to={item.href}
                        >
                          <PaperClipOutlined
                            style={{ marginRight: ".25rem" }}
                          />
                          {item.old_name}
                        </Link>
                      ))}
                    </div>
                  )}
                </List.Item>
              )}
            />
          </div>
          <Typography.Title className={styles.title} id="受理记录" level={5}>
            <NumberOutlined />
            受理记录
          </Typography.Title>
          <div className={styles.section}>
            <List
              renderItem={(item) =>
                item.tiemstamp ? (
                  <List.Item>
                    <List.Item.Meta
                      description={dayjs(item.tiemstamp).format("YYYY-MM-DD")}
                    />
                    <div>{item.title}</div>
                  </List.Item>
                ) : (
                  <></>
                )
              }
              dataSource={[
                {
                  tiemstamp: orderInfo && orderInfo.created_time,
                  title: "提交申请",
                },
                {
                  tiemstamp: orderInfo && orderInfo.end_time,
                  title: "已受理",
                },
              ]}
            />
          </div>
        </Col>
        <Col span={6}>
          <Anchor
            offsetTop={96}
            items={[
              { key: "1", href: "#基本信息", title: "基本信息" },
              { key: "2", href: "#商标信息", title: "商标信息" },
              { key: "3", href: "#咨询人", title: "咨询人" },
              { key: "4", href: "#沟通记录", title: "沟通记录" },
              { key: "5", href: "#受理记录", title: "受理记录" },
            ]}
          />
        </Col>
      </Row>
    </Spin>
  );
}

import { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import qs from "query-string";
import { reqTradeMarksList } from "../../../service/query/trademarks";
import { Image, Popover } from "antd";
import { Link } from "react-router-dom";

import { STATUS } from "../../../constants";
import { clazzes } from "../../../intlClass";
import { UserContext } from "../../../context";

import { reqAxiostrademarksLists } from "../../../api/lists";
import PageHeader from "../../../components/PageHeader";
import ShPageModel from "../../../components/ShPageModel";

export default function Trademarks() {
  const [trademarksList, setTrademarksList] = useState([]);
  const navigate = useNavigate();
  const [status, setStatus] = useState(STATUS.idle);
  const [total, setTotal] = useState(1);
  const location = useLocation();
  const { parkId, parkName } = useContext(UserContext);
  const [applySort, setApplySort] = useState("");
  const [regSort, setRegSort] = useState("");
  const [query, setQuery] = useState({});
  const handleStatus = (status) => {
    setStatus(status);
  };

  // 声明一个状态变量用于保存选中的行
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  // 声明一个状态变量用于保存 id_tm 的数组
  const [selectedIdTm, setSelectedIdTm] = useState([]);

  // 当复选框状态改变时触发
  const onSelectChange = (newSelectedRowKeys, selectedRows, dataSource, rowKey) => {
    // console.log(`selectedRowKeys: ${newSelectedRowKeys}`, 'selectedRows: ', selectedRows);

    // 获取当前页面的选中行的 id_tm
    const idTms = selectedRows.map(row => row.id_tm);

    // 从数据源中提取出每一项的行键（默认为 'id'），生成当前页面所有行的键数组
    const currentPageKeys = dataSource.map(item => item[rowKey ?? 'id']);

    // 更新选中的行键数组：
    // 1. 保留当前选中行键中那些在当前页面中的行键，并且这些行键仍然被新选中的行键包含。
    // 2. 保留当前选中行键中那些不在当前页面中的行键（这些行键不会被新选中的行键影响）。
    const updatedSelectedRowKeys = selectedRowKeys.filter(key =>
      currentPageKeys.includes(key) ? newSelectedRowKeys.includes(key) : true
    );

    // 更新选中的 id_tm 数组：
    // 1. 保留当前选中 id_tm 中那些在当前页面中的 id_tm，并且这些 id_tm 仍然被新选中的行数据包含。
    // 2. 保留当前选中 id_tm 中那些不在当前页面中的 id_tm（这些 id_tm 不会被新选中的行数据影响）。
    const updatedSelectedIdTm = selectedIdTm.filter(idTm =>
      currentPageKeys.includes(dataSource.find(item => item.id_tm === idTm)?.[rowKey ?? 'id']) ? idTms.includes(idTm) : true
    );

    // 遍历新选中的行键数组，将不在 updatedSelectedRowKeys 中的行键添加进去
    newSelectedRowKeys.forEach(key => {
      if (!updatedSelectedRowKeys.includes(key)) {
        updatedSelectedRowKeys.push(key);
      }
    });

    // 遍历新选中的 id_tm 数组，将不在 updatedSelectedIdTm 中的 id_tm 添加进去
    idTms.forEach(idTm => {
      if (!updatedSelectedIdTm.includes(idTm)) {
        updatedSelectedIdTm.push(idTm);
      }
    });

    // 更新状态
    setSelectedRowKeys(updatedSelectedRowKeys);
    setSelectedIdTm(updatedSelectedIdTm);
  };

  // 表格行选择配置
  const rowSelection = {
    selectedRowKeys,  // 当前选中的行键数组
    // 当行选择变化时，调用 onSelectChange 函数，并传递新选中的行键、新选中的行数据、当前页面的数据源和行键字段（默认为 'id_tm'）
    onChange: (newSelectedRowKeys, selectedRows) => onSelectChange(newSelectedRowKeys, selectedRows, trademarksList, 'id_tm'),
  };

  // 清空复选框选中状态
  const resetSelection = () => {
    // 清空复选框选中状态
    setSelectedRowKeys([]);
    // 清空 id_tm 数组
    setSelectedIdTm([]);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const newQuery = {
      // query 在 useEffect中已经被替换为新的值
      ...query,
      sortFiled: sorter.field,
      sortOrder: sorter.order,
    };
    let newUrl = `?${Object.entries(newQuery)
      .map(([key, value]) => `${key}=${value ?? ""}`)
      .join("&")}`;
    navigate(newUrl);
  };

  const trademarksTableConfig = {
    nameList: [
      {
        title: "商标名称",
        dataIndex: "name",
        ellipsis: true,
        render: (data, record) => (
          <Link
            to={{
              pathname: `/${parkName}/query/trademarks/${record.reg_number}`,
              search: qs.stringify({ reg_number: record.reg_number }),
            }}
          >
            {data}
          </Link>
        ),
      },
      {
        title: "商标",
        dataIndex: "image_url",
        render: (data) => {
          return (
            <Popover
              placement="right"
              title={<Image preview={false} width={256} src={data} />}
            >
              <Image width={96} src={data} />
            </Popover>
          );
        },
      },
      {
        title: "注册号",
        dataIndex: "reg_number",
      },
      {
        title: "分类",
        dataIndex: "type_num",
        render: (data) => clazzes[data - 1].title,
      },
      {
        title: "法律状态",
        dataIndex: "status",
      },
      {
        title: "注册人",
        dataIndex: "company",
      },
      {
        title: "申请时间",
        dataIndex: "apply_date",
        sorter: true,
        sortOrder: applySort,
      },
      {
        title: "注册时间",
        dataIndex: "reg_pubdate",
        sorter: true,
        sortOrder: regSort,
      },
    ],
  };
  useEffect(() => {
    const parsedQuery = qs.parse(location.search.slice(1), {
      parseNumbers: true,
    });
    setApplySort(
      parsedQuery.sortFiled === "apply_date" ? parsedQuery.sortOrder : ""
    );
    setRegSort(
      parsedQuery.sortFiled === "reg_pubdate" ? parsedQuery.sortOrder : ""
    );

    setQuery(parsedQuery);

    if (parkId && status === STATUS.idle) {
      const doPost = async () => {
        await reqTradeMarksList(parsedQuery, parkId).then((res) => {
          setTrademarksList(res.data);
          setTotal(res.count);
          setStatus(STATUS.success);
        });
      };
      if (status === STATUS.idle) {
        doPost();
      }
    }
  }, [location, parkId, status]);

  useEffect(() => {
    setStatus(STATUS.idle);
  }, [parkId, location]);

  return (
    <>
      <PageHeader
        title="商标数据检索"
        description="检索入驻园区企业的相关商标信息。"
      />

      <ShPageModel
        rowKey={"id_tm"}
        placeholder="商标名称/注册号"
        changeStatus={handleStatus}
        status={status}
        config={trademarksTableConfig}
        dataSource={trademarksList}
        total={total}
        reqExport={reqAxiostrademarksLists}
        handleTableChange={handleTableChange}
        rowSelection={rowSelection} // 复选框: 传递 rowSelection 属性
        selectedIdTm={selectedIdTm} // 传递 selectedIdTm
        resetSelection={resetSelection} // 清空复选框: 传递 resetSelection 属性
      />
    </>
  );
}

import supabase from "../.."

// 获取通知数据
export const getNotice = async (parsedQuery, parkId) => {
  const {
    pageSize = 10, // 每页条数，默认为10
    current = 1, // 当前页码，默认为1
    keywords, // 关键词搜索
  } = parsedQuery;

  const start = (current - 1) * pageSize; // 计算分页起始位置
  const end = start + pageSize - 1; // 计算分页结束位置

  // 构建查询 member 表的基础查询
  let query = supabase
    .from("park_broadcast")
    .select("*", { count: "exact" }) // 查询所有字段，并返回总记录数
    .eq("park_id", parkId) // 过滤 park_id
    .order("update_time", { ascending: false }); // 按照 update_time 从最新到最旧排序

  // 如果有关键词搜索，则添加关键词搜索条件
  if (keywords) {
    query.like("title", `%${keywords}%`); // 模糊匹配 title 字段
  }

  // 执行查询并获取数据
  const { data, error, count } = await query.range(start, end);

  // 如果有错误，则抛出错误
  if (error) {
    console.error("获取数据失败:", error.message);
  }

  // 构建返回结果
  const result = {
    data: data ?? [],
    count: count ?? 0,
  };
  return result;
}

// 添加通知
export const addNotice = async (noticeData) => {
  try {
    // 第一步：插入 park_broadcast 表并获取插入的 id
    const { data: broadcastData, error: broadcastError } = await supabase
      .from("park_broadcast")
      .insert([
        {
          park_id: noticeData.parkId,  // 园区id
          title: noticeData.title,  // 通知标题
          status: noticeData.status,  // 通知状态
          broadcast_time: noticeData.broadcast_time,  // 通知时间
          info: noticeData.info,  // 通知内容
        },
      ])
      .select("id") // 获取插入后的 id
      .single(); // 确保返回单个数据

    if (broadcastError) {
      console.error("添加通知失败:", broadcastError.message);
      throw new Error(broadcastError.message);
    }

    const broadcastId = broadcastData.id;

    // 第二步：查询 member 表中与 park_id 对应的所有 id 值
    const { data: memberData, error: memberError } = await supabase
      .from("member")
      .select("id")
      .eq("park_id", noticeData.parkId)
      .eq("status", 1) // 状态为1的会员
      .eq("is_delete", 1); // 未删除的会员

    if (memberError) {
      console.error("查询会员ID失败:", memberError.message);
      throw new Error(memberError.message);
    }

    const memberBroadcastData = memberData.map(member => ({
      member_id: member.id,
      broadcast_id: broadcastId
    }));

    // 第三步：将 member_id 和 broadcast_id 一起插入 member_broadcast 表
    const { error: memberBroadcastError } = await supabase
      .from("member_broadcast")
      .insert(memberBroadcastData);

    if (memberBroadcastError) {
      console.error("插入会员通知失败:", memberBroadcastError.message);
      throw new Error(memberBroadcastError.message);
    }

    return broadcastData;
  } catch (error) {
    console.error("操作失败:", error.message);
    throw error;
  }
};

// 上传通知图片文件
export const uploadNoticeFile = async (file) => {

  // 获取图片格式
  const format = file.name.split(".").pop();

  // 生成唯一的文件名：时间戳 + 随机数
  const uniqueFileName = `${file.lastModified}_${Math.random().toString(36).substring(2, 11)}.${format}`;

  const { data, error } = await supabase.storage
    .from('notice_files')
    .upload(uniqueFileName, file, {
      cacheControl: '3600',
      upsert: true
    });

  if (error) {
    console.error("上传通知图片文件失败:", error.message);
    throw new Error(error.message);
  }

  return data;
}

// 修改通知
export const updateNotice = async (noticeData) => {
  // console.log(noticeData, '修改通知数据');

  const { data, error } = await supabase
    .from("park_broadcast")
    .update(noticeData)
    .eq("id", noticeData.id)
  if (error) {
    console.error("修改通知失败:", error.message);
    throw new Error(error.message);
  }

  return data;
}

// 更改通知状态
export const updateNoticeStatus = async (statusData) => {
  // console.log(statusData, '更改通知状态');

  // 更新状态为新的值
  const { data, error } = await supabase
    .from("park_broadcast")
    .update({ status: statusData.status })
    .eq("id", statusData.id);

  if (error) {
    console.error("更改通知状态失败:", error.message);
    throw new Error(error.message);
  }

  return data;
};

// 批量更改通知状态
export const updateNoticeStatusBatch = async (statusData) => {
  // console.log(statusData, '批量更改通知状态');

  // 更新状态为新的值
  const { data, error } = await supabase
    .from("park_broadcast")
    .update({ status: statusData.status })
    .in("id", statusData.noticeIds)

  if (error) {
    console.error("批量更改通知状态失败:", error.message);
    throw new Error(error.message);
  }

  return data;
}

import supabase from "../..";
// 从member数据库获取数据
export const reqMemberFees = async (parsedQuery, parkId, id) => {
  const {
    pageSize = 10,
    current = 1,
    keywords,
    startDate,
    endDate,
  } = parsedQuery;
  const start = (current - 1) * pageSize;
  const end = start + pageSize - 1;
  let query = supabase
    .from("member_fees")
    .select(
      "id,pay_date,price,explain,member!inner(company_id,member_name,status)",
      {
        count: "exact",
      }
    )
    .eq("park_id", parkId);
  if (id === "1") {
    query.order("pay_date", { ascending: false });
  }
  if (keywords) {
    query.like("member.member_name", `%${keywords}%`);
  }
  if (startDate && endDate && id === "1") {
    query.or(`and(pay_date.gt.${startDate},pay_date.lt.${endDate})`);
  }

  const { data, error, count } = await query.range(start, end);
  if (error) {
    throw new Error(error.message);
  }
  const result = {
    data: data ?? [],
    error: error,
    count: count,
  };
  return result;
};

export const reqMemberList = async (parkId) => {
  let query = supabase
    .from("member")
    .select("id,member_name", {
      count: "exact",
    })
    .eq("park_id", parkId)
    .eq("status", 1);
  const { data, error } = await query;
  if (error) {
    console.log("error :>> ", error);
  }
  const result = {
    data: data ?? [],
  };

  return result;
};

export const reqInsertMemberFee = async (memberData) => {
  let query = supabase.from("member_fees").insert([memberData]).select();
  const { data, error } = await query;

  if (error) {
    throw new Error(error.message);
  }
  const result = {
    data: data ?? [],
  };
  return result;
};

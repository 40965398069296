import React, { useState, useRef, useEffect } from 'react';
import { Button, Col, DatePicker, Form, Input, Modal, Row, Select, Space, Radio, notification } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import styles from "../index.module.css";
import { addNotice } from "../../../../service/member/notice";
import { STATUS } from "../../../../constants";
import { updateNoticeStatusBatch } from "../../../../service/member/notice";

import ReactQuill from 'react-quill'; // 导入react-quill模块
import 'react-quill/dist/quill.snow.css'; // 导入react-quill样式
import ImageUpload from '../hook/ImageUpload'; // 导入自定义图片上传模块

export default function AddNotice({
  parkId,
  onStatusChange, // 通知状态改变
  noticeIds, // 通知id数组
  resetSelection, // 清空复选框
}) {
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [selectedNotice, setSelectedNotice] = useState(null);
  const [api, contextHolder] = notification.useNotification();
  // 声明一个状态变量，用于管理按钮的加载状态
  const [loading, setLoading] = useState(false);

  const [value, setValue] = useState(''); // 创建一个 state，用于存储编辑器中的文本内容
  const quillContainerRef = useRef(null); // 使用 useRef 创建对 ReactQuill 组件的引用，以便在组件加载时获取编辑器实例

  const onFinish = async () => {
    try {
      const values = await form.validateFields();
      // console.log("添加通知数据：", values);
      const noticeData = {
        ...values,
        parkId: parkId,
        info: value,
      }
      // console.log(noticeData, '表单数据');

      await addNotice(noticeData).
        then((res) => {
          // console.log(res, '通知添加成功');
          api.success({
            message: "通知新增成功",
            placement: "top",
            style: {
              marginTop: "3rem",
            },
          });
          onStatusChange(STATUS.idle); // 重置状态刷新表格数据
          form.resetFields(); // 清空表单内容
          setValue(''); // 清空 Quill 编辑器内容
          setOpen(false); // 关闭模态框
        }).catch((err) => {
          console.log(err, '通知添加失败');
        })
    } catch (error) { }
  };

  const handleCancel = () => {
    form.resetFields(); // 清空表单内容
    setValue(''); // 清空 Quill 编辑器内容
    setOpen(false); // 关闭模态框
  };

  // 定义 Quill 编辑器的模块配置，包括工具栏的功能
  const modules = {
    toolbar: [
      [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }], // 标题和字体选项
      [{ 'list': 'ordered' }, { 'list': 'bullet' }], // 有序列表和无序列表
      ['bold', 'italic', 'underline'], // 加粗、斜体、下划线按钮
      ['link', 'image'], // 添加链接和图片按钮
      [{ 'color': [] }, { 'background': [] }], // 字体颜色和背景颜色选择器
      [{ 'align': [] }], // 对齐方式
      ['clean'] // 清除格式按钮
    ],
  };

  useEffect(() => {
    if (open && quillContainerRef.current) { // 每次模态框打开时绑定图片上传功能
      // 获取 Quill 编辑器的实例，并传递给自定义的图片上传模块
      const quill = quillContainerRef.current.getEditor();
      ImageUpload(quill); // 绑定图片上传功能
    }
  }, [open]); // 每次模态框打开时触发

  // 批量设置通知状态
  const updateNoticesStatus = async (status) => {
    setLoading(true); // 启用加载状态
    try {
      const result = await updateNoticeStatusBatch({ status, noticeIds });
      resetSelection(); // 调用清空复选框方法
      onStatusChange(STATUS.idle); // 重置状态刷新表格数据
      // console.log(`通知状态(${status === 1 ? '发布' : '撤回'})更新成功`, result);
    } catch (error) {
      console.error(`通知状态(${status === 1 ? '发布' : '撤回'})更新失败`, error);
    } finally {
      setLoading(false);  // 关闭加载状态
    }
  }

  // 处理执行按钮点击事件，根据 selectedNotice 调用相应的函数
  const handleExecute = () => {
    if (selectedNotice === '发布') {
      updateNoticesStatus(1);
    } else if (selectedNotice === '撤回') {
      updateNoticesStatus(0);
    }
    setSelectedNotice(null); // 重置下拉菜单的值
  }

  return (
    <>
      {contextHolder}
      <div className={styles.addMember}>
        <div>
          <Space>
            {/* 下拉菜单 */}
            <Select
              placeholder="批量操作"
              style={{ width: 200 }}
              onChange={(value) => setSelectedNotice(value)}
              value={selectedNotice} // 绑定 selectedNotice 状态
              options={[
                { value: "发布", label: <><CheckCircleOutlined /> 批量发布</> },
                { value: "撤回", label: <><CloseCircleOutlined /> 批量撤回</> }
              ]}
            />
            <Button
              type="primary"
              onClick={handleExecute}
              disabled={!selectedNotice || noticeIds.length === 0} // 禁用按钮，如果没有选择操作或者没有选择通知
              loading={loading}
            >
              执行
            </Button>
          </Space>
        </div>
        <div>
          {/* 发布通知按钮 */}
          <Button type="primary" onClick={() => setOpen(true)}>
            新增通知
          </Button>
        </div>
      </div>
      <Modal
        title="新增通知"
        forceRender
        open={open}
        onOk={() => onFinish()} // 点击确定提交表单
        onCancel={handleCancel} // 点击取消关闭模态框
        width={1200}
        confirmLoading={!open}
        destroyOnClose={true}
      >
        <Row gutter={32}>
          <Col span={20}>
            <Form
              wrapperCol={{ span: 19 }}
              labelCol={{ span: 5 }}
              form={form}
              name="validateOnly"
              onFinish={onFinish} // 绑定表单提交处理
            >
              <Form.Item
                name="title"
                label="标题"
                rules={[{ required: true, message: "请输入通知标题" }]}
              >
                <Input placeholder="请输入标题" />
              </Form.Item>
              <Form.Item
                label="状态"
                name="status"
                initialValue={1}  // 设置默认选中的值
                rules={[
                  {
                    required: true,
                    message: "请选择通知状态!",
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value={1}>发布</Radio>
                  <Radio value={0}>草稿</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                label="通知时间"
                name="broadcast_time"
                rules={[{ required: true, message: "请填写通知时间" }]}
              >
                <DatePicker />
              </Form.Item>
              <Form.Item
                label="通知内容"
                name="info"
              >
                <ReactQuill
                  theme="snow" // 设置 Quill 编辑器的主题为 'snow'
                  value={value} // 将编辑器内容与组件 state 绑定
                  onChange={setValue} // 当编辑器内容变化时更新 state
                  modules={modules} // 为编辑器传入自定义的工具栏模块
                  ref={quillContainerRef} // 使用 ref 引用 ReactQuill 组件
                  style={{ height: '22vw' }} // 设置编辑器的高度
                />
                {/* <p>你输入的内容是: {value}</p> 显示当前编辑器中的文本内容 */}
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../../context";
import { reqDelActivyFee } from "../../../../service/member/activity/details";
import { Modal } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { STATUS } from "../../../../constants";
import { reqUpdateActivity } from "../../../../service/member/activity";
const useActivity = () => {
  const { confirm } = Modal;

  const { parkName } = useContext(UserContext);
  const navigate = useNavigate();

  const goActivityDetail = (meeting_id) => {
    navigate(`/${parkName}/activity/2/${meeting_id}`);
  };

  const deleteActivityFee = async (data, onStatusChange) => {
    confirm({
      title: `您确定要移除 ${data.member.member_name}嘛`,
      icon: <ExclamationCircleFilled />,
      onOk() {
        reqDelActivyFee(data).then((res) => {
          onStatusChange(STATUS.idle);
        });
      },
    });
  };
  const deleteActivity = async (data, onStatusChange) => {
    confirm({
      title: `您确定要移除《${data.meeting_title}》活动么`,
      icon: <ExclamationCircleFilled />,
      onOk() {
        reqUpdateActivity({ id: data.id, is_show: 0 }).then((res) => {
          onStatusChange(STATUS.idle);
        });
      },
    });
  };
  return {
    goActivityDetail,
    deleteActivityFee,
    deleteActivity,
  };
};

export default useActivity;

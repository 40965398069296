import supabase from "..";

export const reqPatentsList = async (parsedQuery, parkId) => {
  const defaultQuery = {
    pageSize: 10,
    current: 1,
    keywords: "",
    patentType: "",
    patentStatus: "",
    patentCom: "",
    patentTypeStatus: "",
    sortFiled: "",
    sortOrder: "",
  };
  // 合并默认查询参数和用户传递的查询参数
  parsedQuery = { ...defaultQuery, ...parsedQuery };
  const { pageSize, current, keywords } = parsedQuery;
  const start = (current - 1) * pageSize;
  const end = start + pageSize - 1;

  let query = supabase
    .from("park_patent")
    .select(`*,patent!inner(*)`, { count: "exact" })
    .eq("park_id", parkId);

  if (parsedQuery.sortOrder && parsedQuery.sortFiled === "request_date") {
    query.order("request_date_timestamp", {
      ascending: parsedQuery.sortOrder === "ascend" ? true : false,
    });
  }
  if (parsedQuery.patentTypeStatus === 1) {
    query.eq("patent.authorize_tag", 0);
  }
  if (parsedQuery.patentTypeStatus === 2) {
    query.or("authorize_tag.eq.1,authorize_tag.eq.2", {
      foreignTable: "patent",
    });
  }
  if (parsedQuery.patentType) {
    query.eq("patent.type_name", parsedQuery.patentType);
  }
  if (parsedQuery.patentStatus) {
    query.like("patent.last_status", `%${parsedQuery.patentStatus}%`);
  }
  if (parsedQuery.patentCom) {
    query.like("patent.patent_person", `%${parsedQuery.patentCom}%`);
  }
  if (keywords) {
    query.or(`name.like.%${keywords}%,number.like.%${keywords}%`, {
      foreignTable: "patent",
    });
  }

  const { error, data, count } = await query.range(start, end);

  if (error) {
    console.error("获取数据失败:", error.message);
  }
  const result = {
    data: data ? data.map((item) => item.patent) : [],
    count: count ?? 0,
  };
  return result;
};

// 专利详情
export const reqPatentInfo = async (id_pt) => {
  const { data, error } = await supabase
    .from("patent")
    .select("*")
    .eq("id_pt", id_pt);
  const result = {
    data: data ?? [],
    error: error,
  };
  if (error) {
    console.error("获取数据失败:", error.message);
  }
  return result;
};

// 获取专利status
export const reqPatentStatus = async (parkId) => {
  const { data, error } = await supabase.rpc("get_patent_statuses", {
    parkid: parkId,
  });
  if (error) {
    console.error("获取数据失败:", error.message);
  }

  const result = {
    data: data ? data.filter((item) => item.last_status) : [],
  };
  return result;
};
// 时间筛选
export const reqPatentRange = async () => {
  const result = await supabase.from("patent").select("request_date");
  const { error } = result;
  if (error) {
    console.error("获取数据失败:", error.message);
  }
  return result;
};

import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Radio,
  notification,
  Table,
} from "antd";
import { fee_status } from "../../../../intlClass";
import { useContext, useState } from "react";
import styles from "../index.module.css";
import { reqUpdateMember } from "../../../../service/member/vip";
import { UserContext } from "../../../../context";
import { STATUS } from "../../../../constants";
export default function EditMember({ record, onStatusChange }) {
  const [open, setOpen] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  const { parkId } = useContext(UserContext);

  const [form] = Form.useForm();
  const onFinish = async () => {
    try {
      const values = await form.validateFields();
      // console.log(values, 'values值');
      // 请求接口，只需传递指定的数据
      const memberData = {
        fee_status: values.fee_status,
        join_date: values.join_date,
        park_id: parkId,
        company_id: record.company_id,
        status: 1,
      };
      await reqUpdateMember(memberData).then(() => {
        api.success({
          message: "会员信息修改成功",
          placement: "top",
          style: {
            marginTop: "3rem",
          },
        });
        onStatusChange(STATUS.idle);
        setOpen(false);
      });
    } catch (error) { }
  };

  // 定义表格的列
  const columns = [
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
      render: text => text
    },
    {
      title: '手机',
      dataIndex: 'phone',
      key: 'phone',
      render: text => text
    }
  ];

  return (
    <>
      <div className={styles.buttons}>
        <a onClick={() => setOpen(true)}>
          编辑
        </a>
      </div>
      <Modal
        title={'修改会员信息信息'}
        forceRender
        open={open}
        onOk={() => onFinish()}
        onCancel={() => setOpen(false)}
        width={720}
        confirmLoading={!open}
        destroyOnClose={true}
      >
        {contextHolder}
        <Row gutter={32}>
          <Col span={20}>
            <Form
              wrapperCol={{ span: 19 }}
              initialValues={record}
              labelCol={{ span: 5 }}
              form={form}
              className={styles.modalTitle}
            >
              <Form.Item
                label="会员名称"
                name="member_name"
              >
                <span>{record.member_name}</span>
              </Form.Item>
              <Form.Item label="联系人信息">
                <Table
                  columns={columns}
                  dataSource={record.contacts}
                  pagination={false}
                  rowKey="id"
                  size="small"
                />
              </Form.Item>
              <Form.Item
                label="会费状态"
                name="fee_status"
                rules={[
                  {
                    required: true,
                    message: "请选择会费状态",
                    // validateTrigger: ["onChange"],
                  },
                ]}
              >
                <Radio.Group onChange={() => form.submit()}>
                  {fee_status.map((item) => (
                    <Radio key={item.id} value={item.id}>
                      {item.title}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
              <Form.Item
                label="入会时间"
                name="join_date"
                rules={[{ required: true, message: "请填写入会时间" }]}
              >
                <DatePicker />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

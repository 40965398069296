import { useState } from "react";
import { Space, Tag, Input, Spin } from "antd";
import styles from "./Tags.module.css";
import { STATUS } from "../constants";
import { PlusOutlined } from "@ant-design/icons";
import { reqUpCompanyTags } from "../service/query/companies";

export default function Tags({ dataSource, status, setStatus }) {
  const [addable, setAddable] = useState(false);
  const id_com = dataSource[0]?.id_com;

  const fetchUpdate = async (updatedTags) => {
    await reqUpCompanyTags(id_com, updatedTags.join(";")).then((res) => {
      setStatus(STATUS.idle);
    });
  };

  const handleUpdateTags = async (value) => {
    let updatedTags = [];
    if (dataSource[0]?.com_tags) {
      updatedTags = [...dataSource[0]?.com_tags.split(";"), value.target.value];
    } else {
      updatedTags = [value.target.value];
    }
    fetchUpdate(updatedTags);
    setAddable(false);
  };

  const handleClose = (e, item) => {
    e.preventDefault();
    const updatedTags = dataSource[0]?.com_tags
      .split(";")
      .filter(Boolean)
      .filter((t) => t !== item);
    fetchUpdate(updatedTags);
  };

  return (
    <Spin spinning={status === STATUS.success ? false : true}>
      <div className={styles.root}>
        <Space>
          {dataSource.length > 0 &&
            dataSource[0].com_tags !== null &&
            dataSource[0]?.com_tags
              .split(";")
              .filter(Boolean)
              .map((item, index) => (
                <Tag
                  closable={true}
                  onClose={(e) => handleClose(e, item)}
                  color="#2db7f5"
                  key={item}
                >
                  {item}
                </Tag>
              ))}
          {!addable ? (
            <Tag className={styles.add} onClick={() => setAddable(true)}>
              <PlusOutlined /> 添加标签
            </Tag>
          ) : (
            <Input
              autoFocus
              onBlur={() => setAddable(false)}
              onPressEnter={(value) => handleUpdateTags(value)}
              className={styles.input}
              size="small"
              placeholder="标签"
            />
          )}
        </Space>
      </div>
    </Spin>
  );
}
// eslint-disable-next-line no-lone-blocks
{
  /* <>
              <Tag color="green" closable>
                规上企业
              </Tag>
              <Tag color="green" closable>
                示范试点
              </Tag>
              {!addable ? (
                <Tag className={styles.add} onClick={() => setAddable(true)}>
                  <PlusOutlined /> 添加标签
                </Tag>
              ) : (
                <Input
                  autoFocus
                  onBlur={() => setAddable(false)}
                  onPressEnter={() => setAddable(false)}
                  className={styles.input}
                  size="small"
                  placeholder="标签"
                />
              )}
            </> */
}

import { Link, useLocation } from "react-router-dom";
import { Button, Typography, Space, Tabs, Spin, Skeleton, Card } from "antd";
import {
  CheckCircleOutlined,
  HistoryOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import PageHeader from "../../components/PageHeader";
import CreateModal from "./Create.Modal";

import styles from "./empowerment.module.css";
import { reqEmpowermentInfo } from "../../service/empowerment";
import { STATUS } from "../../constants";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context";

export default function Details() {
  const location = useLocation();
  const [status, setStatus] = useState(STATUS.idle);
  const [empowermentInfo, setEmpowermentInfo] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const empowermentId = location.pathname.split("/").filter(Boolean)[2];
  const { parkName } = useContext(UserContext);

  useEffect(() => {
    reqEmpowermentInfo(empowermentId).then((res) => {
      setEmpowermentInfo(res.data[0]);
      setStatus(STATUS.success);
    });
  }, [empowermentId, parkName]);
  return (
    <div className={styles.root}>
      <PageHeader
        breadcrumb={[
          { title: <Link to={`/${parkName}/empowerment`}>品牌赋能</Link> },
          { title: `${empowermentInfo.title ?? ""}` },
        ]}
      />
      <Spin spinning={status === STATUS.success ? false : true}>
        <div className={styles.header}>
          <div className={styles.cover}>
            {empowermentInfo.title ? (
              <img src={empowermentInfo.img_url} alt="封面" />
            ) : (
              <Skeleton.Image active style={{ width: 350, height: 250 }} />
            )}

            <div className={styles.meta}>
              <p>服务代码：{empowermentInfo.id}</p>
              <p>温馨提示：本服务由环球互通提供支持</p>
            </div>
          </div>
          <div className={styles.content}>
            <Typography>
              <Typography.Title level={2}>
                {empowermentInfo.title ? (
                  empowermentInfo.title
                ) : (
                  <Skeleton.Input active />
                )}
              </Typography.Title>
              <Typography.Paragraph type="secondary">
                {empowermentInfo.content ? (
                  empowermentInfo.content.split(";").map((item, index) => (
                    <div key={index}>
                      <span>{item}</span>
                      <br />
                    </div>
                  ))
                ) : (
                  <Card bordered={false}>
                    <Skeleton paragraph={{ rows: 4 }} active />
                  </Card>
                )}
              </Typography.Paragraph>
            </Typography>
            <div className={styles.highlights}>
              <div className={styles.stamp} />
              {empowermentInfo.introduction
                ? empowermentInfo.introduction.split(";").map((item, index) => (
                    <div className={styles.item} key={index}>
                      <CheckCircleOutlined />
                      {item}
                      <br />
                    </div>
                  ))
                : ""}
            </div>
            <div className={styles.actions}>
              <Typography>
                <Typography.Paragraph type="secondary">
                  <HistoryOutlined style={{ marginRight: ".25rem" }} />
                  最近一周内有3位客户咨询过
                </Typography.Paragraph>
              </Typography>
              <Space>
                <Button
                  type="primary"
                  icon={<MessageOutlined />}
                  size="large"
                  onClick={() => setIsModalOpen(true)}
                >
                  立即咨询
                </Button>
                <Button type="primary" size="large" ghost>
                  咨询电话：400-006-2018
                </Button>
                <CreateModal
                  open={isModalOpen}
                  onOk={() => setIsModalOpen(false)}
                  onCancel={() => setIsModalOpen(false)}
                  brand_id={empowermentInfo.id}
                  title={empowermentInfo.title ?? ""}
                  img_url={empowermentInfo.img_url ?? ""}
                />
              </Space>
            </div>
          </div>
        </div>
        <div className={styles.body}>
          <Tabs
            items={[
              {
                key: "1",
                label: "服务介绍",
                children: (
                  <div className={styles.illustration}>
                    {empowermentInfo.title ? (
                      <img
                        style={{ width: "60%" }}
                        src={empowermentInfo.img_info_url}
                        alt="封面"
                      />
                    ) : (
                      <Skeleton.Image
                        active
                        style={{ width: "600px", height: "500px" }}
                      />
                    )}
                  </div>
                ),
              },
            ]}
          />
        </div>
      </Spin>
    </div>
  );
}

import { Col, Form, Select } from "antd";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { UserContext } from "../../../context";
import { clazzes } from "../../../intlClass";

import qs from "query-string";
import { reqCompanyWithKeyWords } from "../../../service/empowerment/createmodel";
import { reqTradeStatus } from "../../../service/query/trademarks";
export default function TradeFilter({ form }) {
  const { parkId } = useContext(UserContext);
  const { pathname, search } = useLocation();
  const [show, setShow] = useState(false);
  const [tradeStatus, setTradeStatus] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  useEffect(() => {
    if (parkId && pathname.indexOf("/query/trademarks") !== -1) {
      setShow(true);
      reqCompanyWithKeyWords(parkId).then((res) => {
        setCompanyList(res.data);
      });
      reqTradeStatus(parkId).then((res) => {
        setTradeStatus(res.data);
      });
      let parsedQuery = qs.parse(search.slice(1), {
        parseNumbers: true,
      });
      Object.entries(parsedQuery).forEach(([key, value]) => {
        value && form.setFieldValue(key, value);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parkId, pathname, search]);
  return (
    <>
      {show && (
        <>
          <Col>
            <Form.Item name="type_num">
              <Select
                placeholder="商标类别"
                onChange={() => form.submit()}
                allowClear
                showSearch
                style={{ minWidth: "160px" }}
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                options={clazzes.map((d) => ({
                  value: d.id,
                  label: d.title,
                }))}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name="tradeType">
              <Select
                placeholder="商标类型"
                onChange={() => form.submit()}
                allowClear
                showSearch
                style={{ minWidth: "120px" }}
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                options={[
                  {
                    value: "驰名商标",
                    label: "驰名商标",
                  },
                  {
                    value: "马德里商标",
                    label: "马德里商标",
                  },
                  {
                    value: "海关备案",
                    label: "海关备案",
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name="tradeStatus">
              <Select
                placeholder="商标状态"
                onChange={() => form.submit()}
                allowClear
                showSearch
                style={{ minWidth: "160px" }}
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                options={tradeStatus.map((d) => ({
                  value: d.status,
                  label: d.status,
                }))}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name="tradeCom">
              <Select
                onChange={() => form.submit()}
                allowClear
                showSearch
                placeholder="注册人"
                style={{ minWidth: "160px" }}
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                options={(companyList || []).map((d) => ({
                  value: d.format_name,
                  label: d.format_name,
                }))}
              />
            </Form.Item>
          </Col>
        </>
      )}
    </>
  );
}

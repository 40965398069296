import { Button, Divider, Dropdown } from "antd";
import useActivity from "./ActivityFn";
import EditFee from "../cpn/editFee";
import EditActivity from "../cpn/editActivity";

export function ActivityRender1({ onStatusChange, data, record }) {
  const { goActivityDetail, deleteActivity } = useActivity();
  const items = [
    {
      key: "1",
      label: <EditActivity onStatusChange={onStatusChange} data={data} />,
    },
    {
      key: "2",
      label: (
        <Button
          style={{ width: "100%" }}
          onClick={() => deleteActivity(data, onStatusChange)}
          type="test"
        >
          删除
        </Button>
      ),
    },
  ];
  return (
    <>
      <Button type="link" onClick={() => goActivityDetail(data.id)}>
        查看
      </Button>
      <Divider type="vertical" />
      <Dropdown
        trigger={["click"]}
        menu={{
          items,
        }}
        placement="topRight"
        arrow={{
          pointAtCenter: true,
        }}
      >
        <Button type="link">更多</Button>
      </Dropdown>
    </>
  );
}

export function ActivityRender2({ onStatusChange, data }) {
  const { deleteActivityFee } = useActivity();
  const items = [
    {
      key: "1",
      label: (
        <Button
          type="link"
          onClick={() => deleteActivityFee(data, onStatusChange)}
        >
          删除
        </Button>
      ),
    },
  ];
  return (
    <>
      <EditFee onStatusChange={onStatusChange} data={data} />
      <Divider type="vertical" />
      <Dropdown
        trigger={["click"]}
        menu={{
          items,
        }}
        placement="topRight"
        arrow={{
          pointAtCenter: true,
        }}
      >
        <Button type="link">更多</Button>
      </Dropdown>
    </>
  );
}

import { Col, Form, DatePicker } from "antd";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { UserContext } from "../../../context";
import qs from "query-string";
import dayjs from "dayjs";
export default function FinanceFilter({ rangeChange, form }) {
  const { RangePicker } = DatePicker;
  const { pathname, search } = useLocation();
  const { parkId } = useContext(UserContext);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (parkId && pathname.indexOf("/finance/1") !== -1) {
      setShow(true);
      let parsedQuery = qs.parse(search.slice(1), {
        parseNumbers: true,
      });
      Object.entries(parsedQuery).forEach(([key, value]) => {
        if (key === "FinanceRecordRangetime") {
          value &&
            form.setFieldValue(key, [
              dayjs(parsedQuery.startDate, "YYYY-MM-DD"),
              dayjs(parsedQuery.endDate, "YYYY-MM-DD"),
            ]);
        } else {
          value && form.setFieldValue(key, value);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parkId, pathname, search]);
  return (
    <>
      {show && (
        <>
          <Col>
            <Form.Item name="FinanceRecordRangetime">
              <RangePicker
                format={"YYYY-MM-DD"}
                placeholder={["缴费起始时间", "缴费截止时间"]}
                onChange={rangeChange}
              />
            </Form.Item>
          </Col>
        </>
      )}
    </>
  );
}

import { Button, Result } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

export default function Forbidden() {
  const navigate = useNavigate();
  const location = useLocation();

  const pathname = location.state?.referral?.pathname;

  const parkName = pathname?.split("/").filter(Boolean)[0];
  const goto = (parkName) => {
    navigate(`/${parkName !== "forbidden" ? parkName : ""}`, {
      state: { referral: { pathname: pathname } },
    });
  };
  return (
    <div>
      <Result
        status="error"
        title="很抱歉，你没有访问权限"
        subTitle="请检查你访问的地址是否正确？"
        extra={[
          <Button key={1} onClick={() => goto(parkName)} type="primary">
            重新登录
          </Button>,
          <Button
            disabled={window.location.hostname !== "park.biaoxq.com"}
            key={2}
            onClick={() => navigate("/")}
            type="primary"
            ghost
          >
            选择其他园区
          </Button>,
        ]}
      ></Result>
    </div>
  );
}

import { Form, Input, Modal, Upload, Row, Col, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import styles from "./Create.Modal.module.css";
import { reqInsComment } from "../../service/consulting";
import { useContext, useState } from "react";
import { UserContext } from "../../context";
import {
  reqUpload,
  reqUploadName,
  reqDelete,
  reqDeleteFiles,
} from "../../service/consulting";

export default function CreateModal({ open, onOk, onCancel, orderId }) {
  const [form] = Form.useForm();
  const { parkId } = useContext(UserContext);
  const [fileList, setFileList] = useState([]);
  const [content, setContent] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [isHidden, setIsHidden] = useState(false);

  const onFinish = () => {
    const value = form.getFieldsValue();
    reqInsComment(value.content, parkId, orderId).then((res) => {
      reqUploadName(fileList, res.data[0].id);
      onOk();
    });
  };
  const onHandleCancel = (fileList) => {
    reqDeleteFiles(fileList).then((res) => {
      setFileList([]);
    });
    onCancel();
  };

  const onValuesChange = (value) => {
    setContent(value.content);
  };
  const props = {
    name: "file",
    multiple: true,
    customRequest: (value) => {
      setIsHidden(true);
      reqUpload(value.file).then((res) => {
        value.onSuccess();
        setIsHidden(false);
      });
    },
    onChange(info) {
      const { status } = info.file;
      setFileList(info.fileList);
      if (status !== "uploading") {
      }
      if (status === "done") {
        messageApi.open({
          type: "success",
          content: `${info.file.name} 文件上传成功`,
        });
      } else if (status === "error") {
        messageApi.open({
          type: "error",
          content: `${info.file.name} 文件上传失败`,
        });
      }
    },
    onRemove(file) {
      reqDelete(file);
    },
  };
  return (
    <Modal
      title="添加沟通记录"
      forceRender
      open={open}
      onOk={onFinish}
      onCancel={() => onHandleCancel(fileList)}
      width={720}
      destroyOnClose={true}
      okButtonProps={{
        disabled: content === "" || isHidden,
      }}
    >
      {contextHolder}
      <Row gutter={32} className={styles.root}>
        <Col span={24}>
          {/* <Alert className={styles.alert} type="info" message="请填写联系方式，咨询顾问将很快与其联系。" showIcon /> */}

          <Form onValuesChange={onValuesChange} layout="vertical" form={form}>
            <Form.Item label="内容" name="content" initialValue={""}>
              <Input.TextArea placeholder="" rows={6} />
            </Form.Item>
            <Form.Item label="上传附件">
              <Upload.Dragger {...props}>
                <p className="ant-upload-drag-icon">
                  <UploadOutlined />
                </p>
                <p className="ant-upload-text">可以点击或拖拽文件来上传</p>
                <p className="ant-upload-hint">
                  可以同时上传多个文件，文件类型支持图片、Office文档等
                </p>
              </Upload.Dragger>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
}

import { useRef, useEffect } from "react";
import Chart from "chart.js/auto";
import { Spin } from "antd";
import { STATUS } from "../../constants";
import { memo } from "react";

const Chart6 = memo(({ kanbanFilterInfo, status }) => {
  const element = useRef();
  const filterData = kanbanFilterInfo.filter((item) => item.cloumn === 6);
  const allyear = Array.from(
    { length: 5 },
    (_, index) => new Date().getFullYear() - 4 + index
  );
  useEffect(() => {
    let dataCounts = [];
    if (filterData.length > 0) {
      const datas = filterData[0].context
        .filter((item) => allyear.includes(Number.parseInt(item.year)))
        .sort((a, b) => Number.parseInt(a.year) - Number.parseInt(b.year));
      dataCounts = allyear.map((year) => {
        const item = datas.find((obj) => obj.year === year);
        return item ? item.count : 0;
      });
    }
    const chart = new Chart(element.current, {
      type: "line",
      data: {
        labels: allyear,
        datasets: [
          {
            data: dataCounts,
            fill: true,
            borderColor: "#264653",
          },
        ],
      },
      options: {
        scales: {
          y: {
            min: 0,
            ticks: { stepSize: 1 },
          },
        },
        aspectRatio: 1.5,
        layout: {
          // padding: 16
        },
        plugins: {
          legend: false,
        },
      },
    });
    return () => chart.destroy();
  }, [filterData, allyear]);
  return (
    <Spin spinning={status === STATUS.idle}>
      <canvas ref={element} />
    </Spin>
  );
});

export default Chart6;

import { useRef, useEffect, useContext } from "react";
import Chart from "chart.js/auto";
import { palette1 } from "./palette";
import { Spin } from "antd";
import { STATUS } from "../../constants";
import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context";

const Chart16 = memo(({ softtotal, kanbanFilterInfo, status }) => {
  const element = useRef();
  const navigate = useNavigate();
  const { parkName } = useContext(UserContext);

  const filterData = kanbanFilterInfo.filter((item) => item.cloumn === 1);
  useEffect(() => {
    let labels = [];
    let counts = [];

    if (filterData.length > 0) {
      labels = filterData[0].context.map((item) => item.type_name);
      counts = filterData[0].context.map((item) => item.count);
      labels.push("软件著作权");
      counts.push(softtotal);
    }
    const chart = new Chart(element.current, {
      type: "pie",
      data: {
        labels: labels,
        datasets: [
          {
            data: counts,
            backgroundColor: palette1,
          },
        ],
      },
      options: {
        aspectRatio: 1.5,
        layout: {
          padding: 16,
        },
        plugins: {
          legend: { position: "right" },
        },
        onClick: (event, elements) => {
          const clickedIndex = elements[0]?.index;
          if (clickedIndex !== undefined) {
            const clickedLabel = labels[clickedIndex];
            if (clickedLabel !== "软件著作权") {
              navigate(
                `/${parkName}/query/copyrights?cpType=${clickedLabel}&pageSize=10&current=1&keywords=`
              );
            } else {
              navigate(`/${parkName}/query/software`);
            }
          }
        },
      },
    });
    return () => chart.destroy();
  }, [filterData, softtotal]);
  return (
    <Spin spinning={status === STATUS.idle}>
      <canvas ref={element} />
    </Spin>
  );
});

export default Chart16;

const baseDesConfig = {
  title: "基本信息",
  column: 2,
  nameList: [
    {
      label: "商标",
      span: 1,
      dataIndex: "image_url",
    },
    {
      label: "商标名称",
      span: 1,
      dataIndex: "name",
    },
    {
      label: "分类",
      span: 1,
      dataIndex: "type_num",
    },
    {
      label: "申请号/注册号",
      span: 1,
      dataIndex: "reg_number",
    },
    {
      label: "商标类型",
      span: 1,
      dataIndex: "type_num_name",
    },
    {
      label: "商标状态",
      span: 1,
      dataIndex: "status",
    },
    {
      label: "申请时间",
      span: 1,
      dataIndex: "apply_date",
    },
    {
      label: "申请人",
      span: 1,
      dataIndex: "company",
    },
    {
      label: "申请人地址",
      span: 2,
      dataIndex: "address_cn",
    },
    {
      label: "初审公告期号",
      span: 1,
      dataIndex: "first_pubno",
    },
    {
      label: "初审公告日期",
      span: 1,
      dataIndex: "first_pubdate",
    },
    {
      label: "注册公告期号",
      span: 1,
      dataIndex: "reg_pubno",
    },
    {
      label: "注册公告日期",
      span: 1,
      dataIndex: "reg_pubdate",
    },
    {
      label: "商标种类",
      span: 2,
      dataIndex: "trademark_kind",
    },
    {
      label: "专用权期限",
      span: 2,
      dataIndex: "period",
    },
    {
      label: "类似群组/商品/服务",
      span: 2,
      dataIndex: "products",
    },
  ],
};
export default baseDesConfig;

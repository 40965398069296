import { Col, Form, Select } from "antd";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { UserContext } from "../../../context";
import qs from "query-string";
import { reqCompanyWithKeyWords } from "../../../service/empowerment/createmodel";
export default function CpsFilter({ form }) {
  const { pathname, search } = useLocation();
  const { parkId } = useContext(UserContext);
  const [show, setShow] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  useEffect(() => {
    if (parkId && pathname.indexOf("/query/software") !== -1) {
      setShow(true);
      reqCompanyWithKeyWords(parkId).then((res) => {
        setCompanyList(res.data);
      });
      let parsedQuery = qs.parse(search.slice(1), {
        parseNumbers: true,
      });
      Object.entries(parsedQuery).forEach(([key, value]) => {
        value && form.setFieldValue(key, value);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parkId, pathname, search]);
  return (
    <>
      {show && (
        <>
          <Col>
            <Form.Item name="cpsCom">
              <Select
                onChange={() => form.submit()}
                allowClear
                showSearch
                placeholder="登记人"
                style={{ minWidth: "160px" }}
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                options={(companyList || []).map((d) => ({
                  value: d.format_name,
                  label: d.format_name,
                }))}
              />
            </Form.Item>
          </Col>
        </>
      )}
    </>
  );
}

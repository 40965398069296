import React, { useContext, useEffect, useState } from "react";
import styles from "./query.module.css";
import ShForm from "../ShForm";
import ShPagination from "../ShPagination/shPagination";
import ShTable from "../ShTable";
import { STATUS } from "../../constants";
import { DownloadOutlined, DownOutlined, VerticalAlignBottomOutlined, LoadingOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Button, Dropdown, Modal, Spin, message } from "antd";
import { useLocation } from "react-router-dom";
import qs from "query-string";
import { UserContext } from "../../context";
import { reqParkName } from "../../service/home/login";

export default function ShPageModel({
  changeStatus,
  config,
  dataSource,
  total,
  status,
  placeholder,
  rowKey,
  tench,
  reqExport,  // 接口请求：接受 reqExport 作为属性
  handleTableChange,
  eiditButtons,
  rowSelection, // 复选框: 接受 rowSelection 作为属性
  resetSelection, // 清空复选框: 接受 resetSelection 作为属性
  companyIds, // 接受 companyIds 作为属性--公司
  selectedIdTm, // 接受 selectedIdTm 作为属性--商标
  idPts, // 接受 idPts 作为属性--专利
  idCps, // 接受 idCps 作为属性--著作权
  idCss, // 接受 idCss 作为属性--软件著作权
  monitorIds, // 接受 monitorIds 作为属性--监测预警
  ids, // 接受 ids 作为属性--品牌交易
}) {
  const [dataSourceWithTench, setDataSourceWithTench] = useState([]);
  const { parkId, parkName } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [park, setPark] = useState();
  const { pathname, search } = useLocation();
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (tench && dataSource) {
      const data = dataSource.map((item, index) => ({
        ...item,
        tech_ent: tench.filter((tech_item) => item.id === tech_item.company_id),
      }));
      setDataSourceWithTench(data);
    }
  }, [tench, dataSource]);

  // 显示等待对话框
  const showLoadingModal = () => {
    Modal.info({
      // 设置对话框的标题为 "导出报表"
      title: '导出报表',
      // 设置对话框的内容
      content: (
        <div>
          {/* 使用 LoadingOutlined 图标和提示文本 */}
          <LoadingOutlined style={{ marginRight: '0.5rem' }} />
          <span>正在导出中，请稍候.....</span>
        </div>
      ),
      // 隐藏确定按钮
      okButtonProps: { style: { display: 'none' } },
      // 禁止通过关闭按钮关闭对话框
      closable: false,
      // 禁止通过点击遮罩层关闭对话框
      maskClosable: false,
      // 确保对话框在屏幕中央显示
      centered: true,
    });
  };

  // 处理导出报表请求
  const handleDownload = (parkId) => {
    // 解析查询字符串，将字符串解析为对象
    const parsedQuery = qs.parse(search?.slice(1), {
      parseNumbers: true,
    });

    // 通用函数，用于将数组添加到解析后的对象
    const addArrayToQuery = (array, key) => {
      if (Array.isArray(array) && array.length > 0) {
        parsedQuery[key] = array;
      }
    };

    // 添加数组到查询字符串中
    addArrayToQuery(companyIds, 'companyIds');
    addArrayToQuery(selectedIdTm, 'selectedIdTm');
    addArrayToQuery(idPts, 'idPts');
    addArrayToQuery(idCps, 'idCps');
    addArrayToQuery(idCss, 'idCss');
    addArrayToQuery(monitorIds, 'monitorIds');
    addArrayToQuery(ids, 'ids');

    setLoading(true); // 设置加载状态
    showLoadingModal(); // 显示等待对话框

    // 发起导出报表请求
    reqExport(parkId, parsedQuery)
      .then((res) => {
        const contentDispositionHeader = res.headers.get("content-disposition");
        if (contentDispositionHeader) {
          const match = contentDispositionHeader.match(/filename\*=(.*)/);
          if (match) {
            const encodedFilename = match[1].trim();
            const decodedFilename = decodeURIComponent(encodedFilename);
            let decodedParkFilename = decodedFilename;
            if (park) {
              decodedParkFilename = park.name + "_" + decodedFilename;
            }

            const downloadUrl = URL.createObjectURL(res.data);
            const downloadLink = document.createElement("a");
            downloadLink.download = decodedParkFilename;
            downloadLink.href = downloadUrl;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            URL.revokeObjectURL(downloadUrl);

            // 调用清空复选框方法
            resetSelection();
          } else {
            throw new Error('Filename not found in content-disposition header');
          }
        } else {
          throw new Error('Content-disposition header is missing');
        }
      })
      .catch((error) => {
        // 处理下载失败情况
        messageApi.open({
          type: 'error',
          content: '导出失败，请重试',
        });
        console.error("下载失败：", error);
      })
      .finally(() => {
        setLoading(false); // 取消加载状态
        Modal.destroyAll(); // 关闭等待对话框
      });
  };

  useEffect(() => {
    if (parkName) {
      reqParkName(parkName).then((res) => {
        if (res.data.length > 0) {
          setPark(res.data[0]);
        }
      });
    }
  }, [parkName, pathname]);

  // 判断是否有选中的项目
  const hasSelectedItems = () => {
    const selections = [companyIds, selectedIdTm, idPts, idCps, idCss, monitorIds, ids];
    return selections.some(selection => Array.isArray(selection) && selection.length > 0);
  }

  // 创建下拉菜单项
  const menuItems = [
    {
      key: '1',
      label: (
        <Button
          // 如果状态不是成功或者没有选中任何行时，禁用按钮
          disabled={status !== STATUS.success || !hasSelectedItems()}
          loading={loading}
          type="primary"
          icon={<VerticalAlignBottomOutlined />}
          onClick={() => handleDownload(parkId)}
        >
          指定导出
        </Button>
      )
    },
    {
      key: '2',
      label: (
        <Button
          // 如果状态不是成功，禁用按钮
          disabled={status !== STATUS.success}
          loading={loading}
          type="primary"
          icon={<DownloadOutlined />}
          onClick={() => handleDownload(parkId)}
        >
          批量导出
        </Button>
      )
    }
  ];

  return (
    <>
      {/* Ant Design message API 的上下文容器，用于全局提示消息 */}
      {contextHolder}
      {/* 如果为notice页面则不渲染以下内容 */}
      {pathname.indexOf("/notice") === -1 && (
        <div className={styles.actions}>
          <ShForm changeStatus={changeStatus} placeholder={placeholder} />
          <div>
            {pathname.indexOf("/audit") !== -1 ||
              pathname.indexOf("/auditdetail") !== -1 ||
              pathname.indexOf("/vip") !== -1 ||
              pathname.indexOf("/activity") !== -1 ||
              pathname.indexOf("/notice") !== -1 ||
              pathname.indexOf("/finance") !== -1 ? (
              ""
            ) : (
              <Dropdown menu={{ items: menuItems }}>
                <Button type="default">
                  导出报表 <DownOutlined />
                </Button>
              </Dropdown>
            )}
          </div>
        </div>
      )}
      <div className={styles.section}>
        <Spin spinning={status === STATUS.success ? false : true}>
          <div style={{ marginBottom: "1rem" }}>
            {eiditButtons && eiditButtons()}
          </div>
          <ShTable
            rowSelection={rowSelection} // 复选框: 传递 rowSelection 属性
            rowKey={rowKey}
            config={config}
            dataSource={tench ? dataSourceWithTench : dataSource}
            handleTableChange={handleTableChange}
          />
          <ShPagination total={total} changeStatus={changeStatus} />
        </Spin>
      </div>
    </>
  );
}

export const STATUS = {
  idle: "idle",
  loading: "loading",
  success: "success",
  failure: "failure",
};
export const patentType = [
  "发明专利申请公布后的撤回",
  "专利权人的姓名或者名称、地址的变更",
  "其他有关事项避免重复授权放弃专利权",
  "-",
  "实用新型专利更正",
  "授权",
  "专利申请的视为撤回",
  "专利权的终止(专利权有效期届满)",
  "专利权的终止(专利权的主动放弃)",
  "专利权的保全及其解除",
  "公布",
  "专利权的终止未缴年费专利权终止",
  "宣告专利权部分无效审查结论公告",
  "被视为撤回的申请",
  "其他有关事项(避免重复授权放弃专利权)",
  "专利权的视为放弃",
  "地址不明的通知",
  "发明专利公报更正",
  "发明专利申请公布后的视为撤回",
  "专利申请权、专利权的转移",
  "专利申请的驳回",
  "文件的公告送达",
  "专利权质押合同登记的生效、变更及注销",
  "专利权的终止",
  "视为撤回的专利申请",
  "避免重复授权放弃专利权",
  "专利权的主动放弃",
  "实质审查的生效",
  "专利权的无效、部分无效宣告(专利权全部无效)",
  "外观设计专利公报更正",
  "专利权的终止(未缴年费专利权终止)",
  "实用新型专利公报更正",
  "专利申请权、专利权的转移专利权的转移",
  "专利实施许可合同备案的生效、变更及注销",
  "专利权的终止专利权有效期届满",
  "发明专利申请公布后的驳回",
  "著录事项变更",
  "实质审查请求",
];

export const tradeStatus = [
  "注册",
  "注册公告",
  "等待实质审查",
  "注册商标被权利人注销",
  "驳回复审中",
  "注册商标",
  "-",
  "注册商标被撤销",
  "其他情形",
  "撤销/无效宣告申请审查中",
  "商标注册申请撤回",
  "期满未续展注销",
  "其他",
  "初审公告",
  "异议中",
  "申请被驳回、不予受理等，该商标已失效",
];

const baseDesConfig = {
  title: "基本信息",
  column: 2,
  nameList: [
    {
      label: "名称",
      span: 2,
      dataIndex: "patent_name",
    },
    {
      label: "类型",
      span: 1,
      dataIndex: "type_name",
    },
    {
      label: "法律状态",
      span: 1,
      dataIndex: "last_status",
    },
    {
      label: "申请日期",
      span: 1,
      dataIndex: "request_date",
    },
    {
      label: "申请号",
      span: 1,
      dataIndex: "request_num",
    },
    {
      label: "IPC/LOC分类",
      span: 1,
      dataIndex: "category_num",
    },
    {
      label: "授权公告号",
      span: 1,
      dataIndex: "outhor_num",
    },
    {
      label: "授权公告时间",
      span: 1,
      dataIndex: "authorize_date",
    },
    {
      label: "公布公告日",
      span: 1,
      dataIndex: "outhor_date"
    },
    {
      label: "申请（专利权）人",
      span: 2,
      dataIndex: "patent_person",
    },
    {
      label: "发明人 / 设计人",
      span: 2,
      dataIndex: "designer",
    },
  ],
};
export default baseDesConfig;

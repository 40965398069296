import { useRef, useEffect, useContext } from "react";
import Chart from "chart.js/auto";
import { palette1 } from "./palette";
import { Spin } from "antd";
import { clazzes } from "../../intlClass";
import { memo } from "react";
import { STATUS } from "../../constants";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context";

const Chart7 = memo(({ kanbanFilterInfo, status }) => {
  const element = useRef();
  const filterData = kanbanFilterInfo.filter((item) => item.cloumn === 1);
  const navigate = useNavigate();
  const { parkName } = useContext(UserContext);
  useEffect(() => {
    let labels = [];
    let counts = [];

    if (filterData.length > 0) {
      const sortData = filterData[0].context
        .sort((a, b) => b.count - a.count)
        .slice(0, 20);
      labels = sortData
        .map((item) => JSON.parse(item.type_num))
        .map((item) => clazzes[item - 1].title);
      counts = sortData.map((item) => JSON.parse(item.count));
    }

    const chart = new Chart(element.current, {
      type: "bar",
      data: {
        labels: labels,
        datasets: [
          {
            data: counts,
            backgroundColor: palette1,
          },
        ],
      },
      options: {
        aspectRatio: 1,
        maintainAspectRatio: false,
        layout: {
          // padding: 16
        },
        plugins: {
          legend: false,
        },
        onClick: (event, elements) => {
          const clickedIndex = elements[0]?.index;
          if (clickedIndex !== undefined) {
            const clickedLabel = labels[clickedIndex];
            const clazzesId = clazzes.find(
              (item) => item.title === clickedLabel
            ).id;

            navigate(
              `/${parkName}/query/trademarks?type_num=${clazzesId}&pageSize=10&current=1&keywords=`
            );
          }
        },
      },
    });
    return () => chart.destroy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData]);
  return (
    <Spin spinning={status === STATUS.idle}>
      <canvas ref={element} />
    </Spin>
  );
});

export default Chart7;

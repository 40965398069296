import { Button, Menu, Modal, Row, Form, Input, Col } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import {
  CaretDownOutlined,
  LogoutOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import styles from "./Header.module.css";
import { useContext, useEffect, useState } from "react";
import { reqParkName, reqUpdateUser } from "../service/home/login";
import ParkModal from "./parkModal";
import { UserContext } from "../context";
import RoleConsumer from "../providerRoute";
export default function Header() {
  const { confirm } = Modal;
  const { role } = RoleConsumer();

  const navigate = useNavigate();
  const location = useLocation();
  const [park, setPark] = useState();
  const { parkName, admin } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const userEmail = JSON.parse(
    localStorage.getItem("sb-zevgwqoenxkhkvornmdw-auth-token")
  )?.user;
  const showConfirm = () => {
    confirm({
      title: "您确定要退出吗?",
      icon: <ExclamationCircleFilled />,
      onOk() {
        localStorage.clear();
        navigate(`/${parkName}`);
      },
    });
  };
  const handleChangePsd = () => {
    setOpen(true);
  };
  const onHandleCancel = () => {
    setOpen(false);
    form.resetFields();
  };
  const onFinish = ({ email, newPassword }) => {
    setLoading(true);
    reqUpdateUser(email, newPassword).then((res) => {
      setLoading(false);
      if (res.error === null) {
        setOpen(false);
        Modal.success({
          content: "修改成功",
          onOk() {
            localStorage.clear();
            navigate(`/${parkName}`);
          },
          keyboard: false,
        });
      } else if (res.error.status === 422) {
        Modal.warning({
          title: "修改失败",
          content: (
            <div>
              <p>新密码不能和旧密码相同</p>
            </div>
          ),
          keyboard: false,
        });
      }
    });
  };
  const validatePassword = (_, value) => {
    if (!value || form.getFieldValue("newPassword") === value) {
      return Promise.resolve();
    }
    return Promise.reject(new Error("两次输入的密码不一致"));
  };
  const items = [
    {
      key: `/${parkName}/kanban`,
      label: (
        <span>
          数据统计
          <CaretDownOutlined style={{ marginLeft: ".25rem" }} />
        </span>
      ),
      children: [
        {
          key: `/${parkName}/kanban/1`,
          label: "企业数据统计",
        },
        { key: `/${parkName}/kanban/2`, label: "商标数据统计" },
        { key: `/${parkName}/kanban/3`, label: "专利数据统计" },
        { key: `/${parkName}/kanban/4`, label: "著作权数据统计" },
      ],
    },
    {
      key: `/${parkName}/query`,
      label: (
        <span>
          数据检索
          <CaretDownOutlined style={{ marginLeft: ".25rem" }} />
        </span>
      ),
      children: [
        {
          key: `/${parkName}/query/companies`,
          label: "企业数据检索",
        },
        {
          key: `/${parkName}/query/trademarks`,
          label: "商标数据检索",
        },
        {
          key: `/${parkName}/query/patents`,
          label: "专利数据检索",
        },
        {
          key: `/${parkName}/query/copyrights`,
          label: "著作权数据检索",
        },
        {
          key: `/${parkName}/query/software`,
          label: "软件著作权数据检索",
        },
      ],
    },
    { key: `/${parkName}/monitoring`, label: "监测预警" },
    { key: `/${parkName}/empowerment`, label: "品牌赋能" },
    { key: `/${parkName}/consulting`, label: "业务咨询" },
    { key: `/${parkName}/audit`, label: "用户审计" },
    role === 1 && {
      key: `/${parkName}/member`,
      label: (
        <span>
          会员服务
          <CaretDownOutlined style={{ marginLeft: ".25rem" }} />
        </span>
      ),
      children: [
        {
          key: `/${parkName}/vip`,
          label: <span>会员管理</span>,
        },
        // {
        //   key: `/${parkName}/finance`,
        //   label: <span>会费管理</span>,
        // },
        // {
        //   key: `/${parkName}/activity`,
        //   label: <span>活动管理</span>,
        // },
        {
          key: `/${parkName}/notice`,
          label: <span>通知管理</span>,
        }
      ],
    },
  ];

  if (admin === 0) {
    items.push({
      key: `/${parkName}/management`,
      label: (
        <span>
          业务管理
          <CaretDownOutlined style={{ marginLeft: ".25rem" }} />
        </span>
      ),
      children: [
        {
          key: `/${parkName}/brandserve`,
          label: "品牌服务",
        },
        {
          key: `/${parkName}/transaction`,
          label: "商标交易",
        },
      ],
    });
  }
  const flatten = (items) => {
    const flattened = [];
    items.forEach((item) => {
      flattened.push(item.key);
      if (item.children) {
        flattened.push(...flatten(item.children));
      }
    });
    return flattened;
  };
  const selectedKeys = flatten(items).filter((item) =>
    location.pathname.startsWith(item)
  );
  useEffect(() => {
    if (parkName) {
      reqParkName(parkName).then((res) => {
        if (res.data.length > 0) {
          setPark(res.data[0]);
        }
      });
    }
  }, [parkName]);

  return (
    <div className={styles.root}>
      <div className={styles.logo}>
        <img src={park && park.logo_img_url} alt="logo" />
      </div>
      <Menu
        style={{ flex: 1 }}
        theme="dark"
        mode="horizontal"
        selectedKeys={selectedKeys}
        items={items}
        onClick={({ key }) => {
          navigate(key);
        }}
      />
      <ParkModal />
      <Button
        ghost
        type="primary"
        onClick={() => handleChangePsd()}
        style={{ marginRight: "1rem" }}
      >
        修改密码
      </Button>
      <Modal
        open={open}
        confirmLoading={loading}
        onOk={() => form.submit()}
        title="修改密码"
        onCancel={() => onHandleCancel()}
      >
        <Row gutter={32}>
          <Col span={24}>
            <Form
              onFinish={onFinish}
              form={form}
              wrapperCol={{ offset: 1, span: 16 }}
              labelCol={{ span: 4 }}
            >
              <Form.Item
                label={"账号"}
                name={"email"}
                initialValue={userEmail && userEmail.email}
              >
                <Input disabled={true} />
              </Form.Item>
              <Form.Item
                name="newPassword"
                label="新密码"
                rules={[
                  { required: true, message: "新密码不能为空" },
                  { min: 6, message: "密码长度不能少于6位数" },
                ]}
              >
                <Input.Password placeholder="请输入新的密码" />
              </Form.Item>
              <Form.Item
                name="confirmPassword"
                label="确认密码"
                rules={[
                  { required: true, message: "两次输入的密码不一致" },
                  { validator: validatePassword },
                ]}
              >
                <Input.Password placeholder="请再输入一遍新的密码" />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
      <Button
        type="primary"
        icon={<LogoutOutlined />}
        htmlType="submit"
        onClick={() => showConfirm()}
      >
        退出登录
      </Button>
    </div>
  );
}

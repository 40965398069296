import supabase from "..";
export const reqUsers = async (parsedQuery, parkId) => {
  if (Object.keys(parsedQuery).length === 0) {
    parsedQuery = {
      pageSize: 10,
      current: 1,
      keywords: "",
    };
  }
  const { pageSize, current, keywords } = parsedQuery;
  const start = (current - 1) * pageSize;
  const end = start + pageSize - 1;

  let query = supabase
    .from("user")
    .select(`id,name,mail,phone,user_logs(created_at)`, { count: "exact" })
    .eq("park_id", parkId)
    .order("id", { ascending: false });
  if (keywords) {
    query.or(`name.like.%${keywords}%,mail.like.%${keywords}%`);
  }
  const { data, error, count } = await query.range(start, end);
  if (error) {
    console.error("获取数据失败:", error.message);
  }
  const result = {
    data: data ?? [],
    count: count ?? 0,
  };
  return result;
};

export const reqAudits = async (parsedQuery, park_id, user_id) => {
  if (Object.keys(parsedQuery).length === 0) {
    parsedQuery = {
      pageSize: 10,
      current: 1,
      keywords: "",
      startDate: "",
      endDate: "",
    };
  }
  const { pageSize, current, keywords } = parsedQuery;
  const start = (current - 1) * pageSize;
  const end = start + pageSize - 1;
  let query = supabase
    .from("user_logs")
    .select(`*,user(name)`, { count: "exact" })
    .eq("park_id", park_id)
    .eq("user_id", user_id)
    .order("created_at", { ascending: false });
  if (keywords) {
    query.or(`name.like.%${keywords}%,mail.like.%${keywords}%`);
  }
  if (parsedQuery.startDate && parsedQuery.endDate) {
    query.or(
      `and(created_at.gt.${parsedQuery.startDate},created_at.lt.${parsedQuery.endDate})`
    );
  }
  const { data, error, count } = await query.range(start, end);

  if (error) {
    console.error("获取数据失败:", error.message);
  }
  const result = {
    data: data ?? [],
    count: count ?? 0,
  };
  return result;
};

import shRequest from "..";
export const reqAxiosCommentsLists = (parkId, parsedQuery) => {
  return shRequest.post({
    url: `/excel_company?park_id=${parkId}`,
    data: parsedQuery,
  });
};
export const reqAxiostrademarksLists = (parkId, parsedQuery) => {
  return shRequest.post({
    url: `/excel_trademark?park_id=${parkId}`,
    data: parsedQuery,
  });
};
export const reqAxiospatentsLists = (parkId, parsedQuery) => {
  return shRequest.post({
    url: `/excel_patent?park_id=${parkId}`,
    data: parsedQuery,
  });
};
export const reqAxiossoftsLists = (parkId, parsedQuery) => {
  return shRequest.post({
    url: `/excel_copyright_soft?park_id=${parkId}`,
    data: parsedQuery,
  });
};
export const reqAxioscopyrightsLists = (parkId, parsedQuery) => {
  return shRequest.post({
    url: `/excel_copyright?park_id=${parkId}`,
    data: parsedQuery,
  });
};
export const reqAxiosordersLists = (parkId) => {
  return shRequest.post({
    url: `/excel_order?park_id=${parkId}`,
  });
};
export const reqAxiosriksLists = (parkId, parsedQuery) => {
  return shRequest.post({
    url: `/excel_company_risk?park_id=${parkId}`,
    data: parsedQuery,
  });
};
export const reqAxiostransactionLists = (parkId, parsedQuery) => {
  return shRequest.post({
    url: `/excel_transaction?park_id=${parkId}`,
    data: parsedQuery,
  });
};

export const reqAxiosSmsLists = (parkId, parsedQuery) => {
  return shRequest.post({
    url: `/send_sms?park_id=${parkId}`,
    data: parsedQuery,
  });
}

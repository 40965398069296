import { useEffect, useContext, useState } from "react";
import { UserContext } from "../../context";
import { STATUS } from "../../constants";
import PageHeader from "../../components/PageHeader";
import { reqUsers } from "../../service/audit";
import { Link, useLocation } from "react-router-dom";
import ShPageModel from "../../components/ShPageModel";

import qs from "query-string";
export default function Audit() {
  const { search, pathname } = useLocation();

  const { parkId, parkName } = useContext(UserContext);
  const [status, setStatus] = useState(STATUS.idle);
  const [users, setUsers] = useState([]);
  const [total, setTotal] = useState(1);
  const usersConfig = {
    nameList: [
      {
        title: "用户名",
        dataIndex: "name",
        key: "name",
        render: (data, record) => (
          <Link
            to={{
              pathname: `/${parkName}/auditdetail/${record.id}`,
            }}
          >
            {data}
          </Link>
        ),
      },
      {
        title: "邮箱",
        dataIndex: "mail",
      },
      {
        title: "手机",
        dataIndex: "phone",
      },
    ],
  };

  useEffect(() => {
    let parsedQuery = qs.parse(search.slice(1), {
      parseNumbers: true,
    });
    if (parkId) {
      reqUsers(parsedQuery, parkId).then((res) => {
        setUsers(res.data);
        setTotal(res.count);
        setStatus(STATUS.success);
      });
    }
  }, [parkId, search]);
  useEffect(() => {
    setStatus(STATUS.idle);
  }, [pathname, search]);
  return (
    <>
      <PageHeader title="用户审计" description="用于记录园区用户的操作。" />

      <ShPageModel
        status={status}
        config={usersConfig}
        dataSource={users}
        total={total}
        rowKey={"id"}
        placeholder={"用户名/邮箱"}
      />
    </>
  );
}

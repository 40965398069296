import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../../context";
import { Modal } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import dayjs from "dayjs";
import {
  reqDeleteMember,
  reqUpdateMember,
} from "../../../../service/member/vip";
import { STATUS } from "../../../../constants";
const useDetail = (com_id) => {
  const navigate = useNavigate();
  const { confirm } = Modal;
  const { parkName } = useContext(UserContext);
  const goToUserDetail = (com_id) => {
    navigate(`/${parkName}/query/companies/${com_id}?company_id=${com_id}`);
  };
  // 退会
  const showConfirm = (data, onStatusChange) => {
    confirm({
      title: "退会确认",
      icon: <ExclamationCircleFilled />,
      content: `您确定要将 ${data.member_name} 退会吗？`,
      onOk() {
        reqDeleteMember({
          company_id: data.company_id,
          park_id: data.park_id,
        }).then(() => {
          onStatusChange(STATUS.idle);
        });
      },
    });
  };
  const handleAgree = (data, onStatusChange) => {
    confirm({
      title: `您确定将 ${data.member_name} 加入会员？`,
      icon: <ExclamationCircleFilled />,
      onOk() {
        const memberData = {
          ...data,
          join_date: dayjs(),
          status: 1,
        };
        reqUpdateMember(memberData).then(() => {
          onStatusChange(STATUS.idle);
        });
      },
    });
  };
  const handleRefuse = (data, onStatusChange) => {
    confirm({
      title: `您确定拒绝 ${data.member_name} 加入会员？`,
      icon: <ExclamationCircleFilled />,
      onOk() {
        const memberData = {
          ...data,
          status: 3,
        };
        reqUpdateMember(memberData).then(() => {
          onStatusChange(STATUS.idle);
        });
      },
    });
  };

  const handleDelete = (data, onStatusChange) => {
    confirm({
      title: `您确定删除该记录？`,
      icon: <ExclamationCircleFilled />,
      onOk() {
        const memberData = {
          ...data,
          status: 0,
        };
        reqUpdateMember(memberData).then(() => {
          onStatusChange(STATUS.idle);
        });
      },
    });
  };
  return {
    goToUserDetail,
    handleAgree,
    handleRefuse,
    showConfirm,
    handleDelete,
  };
};

export default useDetail;

import {
  Select,
  Form,
  Row,
  Radio,
  Button,
  Col,
  DatePicker,
  Input,
  notification,
} from "antd";
import { useForm } from "antd/es/form/Form";
import { useLocation, useNavigate } from "react-router-dom";
import { reqMember } from "../../../service/member/vip";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context";
import { STATUS } from "../../../constants";
import { activity_type } from "../../../intlClass";
import {
  reqInsertActivity,
  reqInsertMembers,
} from "../../../service/member/activity/details";

// 阿里云短信接口
import { reqAxiosSmsLists } from '../../../api/lists'

export default function Issue() {
  const [form] = useForm();
  const { search } = useLocation();
  const { TextArea } = Input;
  const { parkId, parkName } = useContext(UserContext);
  const [status, setStatus] = useState(STATUS.idle);
  const [isShowFee, setShowFee] = useState(false);
  const [memberList, setMemberList] = useState([]);
  // 接收人电话列表
  const [userPhoneList, setUserPhoneList] = useState([])
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();

  const onFinish = async () => {
    try {
      setLoading(true);
      const value = await form.validateFields();
      // console.log(value, 'value值');
      const activityData = {
        meeting_date: value.meeting_date,
        status: 1,
        meeting_title: value.meeting_title,
        issue_type: value.meeting_type,
        meeting_fee: value.rates === 0 ? 0 : value.meeting_fee,
        park_id: parkId,
        content: value.meeting_content,
      };
      await reqInsertActivity(activityData).then((res) => {
        // console.log(res, 'res值');
        const memberData = value.members.map((item) => ({
          member_id: item,
          meeting_id: res.id,
          fee_status: res.meeting_fee === 0 ? 3 : 2,
        }));
        reqInsertMembers(memberData).then(() => {
          setLoading(false);

          // 发送短信
          reqAxiosSmsLists(parkId, {
            phoneNumberList: userPhoneList,
          }).then(res => {
            // console.log('短信发送成功', res);
          }).catch(err => {
            // console.log('短信发送失败', err);
          });
          api.success({
            message: "会议添加成功",
            duration: 1,
            placement: "top",
            style: {
              marginTop: "3rem",
            },
            onClose: () => {
              navigate(`/${parkName}/activity/2/${res.id}`);
            },
          });
        });
      });

    } catch (error) { }
  };
  const handleAdd = () => {
    // console.log(memberList, '当前园区会员信息');
    // 使用 map 方法提取每个成员的 phone 属性值并存入新数组中，并且使用 Set 数据结构来确保电话号码的唯一性，并转换为数组形式
    const phoneList = [...new Set(memberList.map(item => item.phone))];
    // 将提取的电话号码存入 userPhoneList 数组中
    setUserPhoneList(phoneList);
    form.setFieldValue(
      "members",
      memberList.map((item) => item.id)
    );
  };
  const onChangeFee = (e) => {
    setShowFee(e.target.value);
  };
  // 下拉列表选值时函数
  const onChangeRecipient = (e, option) => {
    // console.log(option, '当前选中的接收人信息');
    // 从选中的选项中提取 phone 属性并存储到数组中，并且使用 Set 数据结构来确保电话号码的唯一性，并转换为数组形式
    const selectedPhones = [...new Set(option.map(option => option.phone))];
    // 使用 setUserPhoneList 更新状态
    setUserPhoneList(selectedPhones);
  }

  useEffect(() => {
    const doPost = async () => {
      await reqMember({ pageSize: 1000 }, parkId, 1).then((res) => {
        // console.log(res, '当前园区会员信息');
        setMemberList(res.data);
      });
    };
    if (parkId && status === STATUS.idle) {
      doPost();
    }
  }, [parkId, status]);

  useEffect(() => {
    if (status === STATUS.success) {
      setStatus(STATUS.idle);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);
  return (
    <>
      {contextHolder}
      <Form form={form} onFinish={onFinish}>
        <Row>
          <Col span={8}>
            <Form.Item
              wrapperCol={{ span: 16 }}
              labelCol={{ span: 6 }}
              name="members"
              label="接收人"
              rules={[
                {
                  required: true,
                  message: "请选择接收人",
                },
              ]}
            >
              <Select
                allowClear
                style={{ maxHeight: 200, overflow: "auto" }}
                mode="multiple"
                placeholder="请选择接收人"
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                options={memberList.map((item) => ({
                  value: item.id,
                  label: item.member_name,
                  phone: item.phone,
                }))}
                onChange={onChangeRecipient}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Button onClick={() => handleAdd()}>选择所有人</Button>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Form.Item
              wrapperCol={{ span: 16 }}
              labelCol={{ span: 6 }}
              name="meeting_type"
              label="活动类型"
              rules={[
                {
                  required: true,
                  message: "请选择活动类型",
                },
              ]}
            >
              <Radio.Group
                options={activity_type.map((item) => {
                  return {
                    label: item.title,
                    value: item.id,
                  };
                })}
              ></Radio.Group>
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item
              name="meeting_date"
              label="活动时间"
              rules={[
                {
                  required: true,
                  message: "请选择活动时间",
                },
              ]}
            >
              <DatePicker />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              labelCol={{ span: 2 }}
              name="meeting_title"
              label="活动主题"
              rules={[
                {
                  required: true,
                  message: "请输入活动主题",
                },
              ]}
            >
              <Input placeholder="请输入活动主题" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              labelCol={{ span: 2 }}
              name="meeting_content"
              label="主要内容"
            >
              <TextArea
                rows={15}
                maxLength={200}
                placeholder="请输入活动主题"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Form.Item
              labelCol={{ span: 6 }}
              name="rates"
              label="是否收费"
              rules={[
                {
                  required: true,
                  message: "请选择活动类型",
                },
              ]}
            >
              <Radio.Group
                onChange={onChangeFee}
                options={[
                  {
                    value: 1,
                    label: "收费",
                  },
                  {
                    value: 0,
                    label: "免费",
                  },
                ]}
              ></Radio.Group>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="meeting_fee"
              label="活动费用"
              rules={[
                {
                  required: isShowFee,
                  message: "请输入活动费用",
                },
                // 费用格式规则
                {
                  pattern:
                    /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/,
                  message: "费用格式错误",
                },
              ]}
            >
              <Input disabled={!isShowFee} placeholder="请输入费用" />
            </Form.Item>
          </Col>
        </Row>
        <Row align={"middle"} gutter={48} justify={"end"}>
          <Col span={3}>
            <Form.Item>
              <Button
                block
                size="large"
                loading={loading}
                type="primary"
                htmlType="submit"
              >
                发 布
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
}

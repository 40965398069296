import { Pagination } from "antd";
import qs from "query-string";
import styles from "./shPagination.module.css";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function ShPagination({ total }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [query, setQuery] = useState({
    pageSize: 10,
    current: 1,
    keywords: "",
  });
  const handleCurrentChange = (current, pageSize) => {
    const newUrl = `?${Object.entries(query)
      .map(([key, value]) => {
        if (key === "current") {
          return `${key}=${current}`;
        }
        if (key === "pageSize") {
          return `${key}=${pageSize}`;
        }
        return `${key}=${value ?? ""}`;

      })
      .join("&")}`;
    navigate(newUrl);
  };

  useEffect(() => {
    let parsedQuery = qs.parse(location.search.slice(1), {
      parseNumbers: true,
    });
    setQuery((pre) => ({ ...pre, ...parsedQuery }));
  }, [location]);

  return (
    <div className={styles.root}>
      <Pagination
        total={total}
        current={query.current}
        pageSize={query.pageSize}
        onChange={handleCurrentChange}
        onShowSizeChange={handleCurrentChange}
        showTotal={(total) => `共 ${total} 条`}
      />
    </div>
  );
}

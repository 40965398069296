const softwareTableConfig = {
  title: "软件著作权",
  nameList: [
    {
      title: "软件著作权名称",
      width: 280,
      fixed: "left",
      dataIndex: "name",
    },
    {
      title: "登记号",
      dataIndex: "number",
    },
    {
      title: "创作时间",
      dataIndex: "success_date",
    },
    {
      title: "发布时间",
      dataIndex: "approval_date",
    },
    {
      title: "登记人",
      dataIndex: "company",
    },
    {
      title: "登记时间",
      dataIndex: "first_date",
    },
  ],
};
export default softwareTableConfig;

import { Tabs } from "antd";
import styles from "./index.module.css";
import FinanceList from "./FinanceList";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../../../context";
export default function Finance() {
  const { parkName } = useContext(UserContext);
  const [activeKey, setActiveKey] = useState(1);
  const { pathname } = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const onChange = (key) => {
    navigate(`/${parkName}/finance/${key}`);
  };
  const items = [
    {
      key: "1",
      label: "会费管理",
      children: <FinanceList />,
    },
    // {
    //   key: "2",
    //   label: "转账记录",
    //   children: <RecordList />,
    // },
  ];
  useEffect(() => {
    setActiveKey(id);
  }, [pathname, id]);
  return (
    <div className={styles.root}>
      <Tabs
        destroyInactiveTabPane={true}
        activeKey={activeKey}
        items={items}
        onChange={onChange}
      />
    </div>
  );
}

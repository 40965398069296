import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, Input, Button, Space, Row, Col } from "antd";
import { ClearOutlined, SearchOutlined } from "@ant-design/icons";
import qs from "query-string";
import { UserContext } from "../../context";

import FinanceFilter from "./cpn/FinanceFilter";
import FinanceRecordFilter from "./cpn/FinanceRecordFilter";
import ComFilter from "./cpn/ComFilter";
import TnFilter from "./cpn/TnFilter";
import RiskFilter from "./cpn/RiskFilter";
import CpsFilter from "./cpn/CpsFilter";
import CpFilter from "./cpn/CpFilter";
import PtFilter from "./cpn/PtFilter";
import TradeFilter from "./cpn/TradeFilter";
import VipFilter from "./cpn/VipFilter";
import VipFilter2 from "./cpn/VipFilter2";
import AuditDetailFilter from "./cpn/AuditDetailFilter";
import ActivityFilter from "./cpn/ActivityFilter";

function ShForm({ placeholder }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { parkId } = useContext(UserContext);
  const { pathname, search } = location;
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [sortF, setSortF] = useState("");
  const [sortO, setSortO] = useState("");
  const [form] = Form.useForm();

  const onFinish = (value) => {
    let newUrl = `?${Object.entries(value)
      .map(([key, value]) => `${key}=${value ?? ""}`)
      .join("&")}&sortFiled=${sortF}&sortOrder=${sortO}&pageSize=10&current=1`;
    if (
      pathname.includes("/transaction") ||
      pathname.includes("/auditdetail") ||
      pathname.includes("/vip/1") ||
      pathname.includes("/vip/2") ||
      pathname.includes("/vip/3") ||
      pathname.includes("/finance/1") ||
      pathname.includes("/finance/2") ||
      pathname.includes("/activity/2")
    ) {
      newUrl += `&startDate=${startDate}&endDate=${endDate}`;
    }

    navigate(newUrl);
  };

  const handleClear = () => {
    form.resetFields();
    form.submit();
    setStartDate("");
    setEndDate("");
  };

  const rangeChange = (dates, dateString) => {
    setStartDate(dateString[0]);
    setEndDate(dateString[1]);
    form.submit();
  };

  useEffect(() => {
    if (parkId) {
      let parsedQuery = qs.parse(search.slice(1), {
        parseNumbers: true,
      });
      setSortF(parsedQuery.sortFiled ?? "");
      setSortO(parsedQuery.sortOrder ?? "");
      parsedQuery.keywords &&
        form.setFieldValue("keywords", parsedQuery.keywords);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, search, parkId, location]);

  return (
    <>
      <Form form={form} onFinish={onFinish}>
        <Row gutter={24}>
          <Col>
            <Form.Item name="keywords">
              <Input style={{ width: "12rem" }} placeholder={placeholder} />
            </Form.Item>
          </Col>
          <ComFilter form={form} />
          <TradeFilter form={form} />
          <PtFilter form={form} />
          <CpFilter form={form} />
          <CpsFilter form={form} />
          <RiskFilter form={form} />
          <TnFilter rangeChange={rangeChange} form={form} />
          <AuditDetailFilter rangeChange={rangeChange} form={form} />
          <FinanceFilter rangeChange={rangeChange} form={form} />
          <FinanceRecordFilter rangeChange={rangeChange} form={form} />
          <VipFilter rangeChange={rangeChange} form={form} />
          <VipFilter2 rangeChange={rangeChange} form={form} />
          <ActivityFilter rangeChange={rangeChange} form={form} />
        </Row>
        <Row>
          <Col>
            <Form.Item style={{ marginBottom: "0rem" }}>
              <Space>
                <Button
                  type="primary"
                  htmlType="submit"
                  icon={<SearchOutlined />}
                >
                  检索
                </Button>
                <Button
                  type="primary"
                  icon={<ClearOutlined />}
                  ghost
                  onClick={() => handleClear()}
                >
                  清空
                </Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default ShForm;

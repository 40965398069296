import { Link, useLocation } from "react-router-dom";
import { Form, Input, Button, message } from "antd";
import { MailOutlined } from "@ant-design/icons";
import styles from "./index.module.css";
import { useEffect, useState } from "react";
import {
  reqResetPasswordForEmail,
  reqParkName,
} from "../../service/home/login";

export default function Recovery() {
  const [messageApi, contextHolder] = message.useMessage();
  const [park, setPark] = useState();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const parkName = location?.pathname.split("/").filter(Boolean)[0];

  const success = (message) => {
    messageApi.open({
      type: "success",
      content: message,
      duration: 10,
    });
  };

  const failure = () => {
    messageApi.open({
      type: "error",
      content: "邮件发送频繁,60秒后再试",
      duration: 10,
    });
  };

  const verify = async (values) => {
    setLoading(true);
    try {
      await reqResetPasswordForEmail(
        values.email,
        parkName,
        window.location.host
      ).then(() => {
        setLoading(false);
        success("邮件已发送");
      });
    } catch (error) {
      failure();
    }
  };

  useEffect(() => {
    if (parkName) {
      reqParkName(parkName).then((res) => {
        if (res.data.length > 0) {
          setPark(res.data[0]);
        }
      });
    }
  }, [parkName]);
  return (
    <>
      {contextHolder}
      <section
        style={{
          backgroundImage: `url(${park ? park.bg_img_url : ""})`,
        }}
        className={styles.background}
      >
        <div className={styles.container}>
          <div className={styles.jumbo}>
            <div className={styles.login}>
              <div className={styles.title}>验证邮箱</div>
              <div className={styles.welcome}>
                <div>请输入需要重置密码的邮箱</div>
              </div>

              <Form onFinish={verify}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      type: "email",
                      required: true,
                      message: "请输入有效的邮箱地址",
                    },
                  ]}
                >
                  <Input
                    size="large"
                    prefix={<MailOutlined />}
                    placeholder="邮箱"
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    loading={loading}
                    htmlType="submit"
                    type="primary"
                    size="large"
                    block
                  >
                    提交
                  </Button>
                </Form.Item>
                <div>
                  <Link to={`/${parkName}`}>返回并重新登录</Link>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

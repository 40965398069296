import { useRef, useEffect, useContext } from "react";
import Chart from "chart.js/auto";
import { palette1 } from "./palette";
import { Spin } from "antd";
import { memo } from "react";
import { STATUS } from "../../constants";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context";

const Chart1 = memo(({ kanbanFilterInfo, status }) => {
  const element = useRef();
  const navigate = useNavigate();
  const { parkName } = useContext(UserContext);
  const filterData = kanbanFilterInfo.filter((item) => item.cloumn === 1);

  useEffect(() => {
    let labels = [];
    let counts = [];
    if (filterData.length > 0) {
      const sortData = filterData[0].context
        .sort((a, b) => b.count - a.count)
        .slice(0, 20);
      labels = sortData.map((item) => JSON.parse(item.domains)[0]);
      counts = sortData.map((item) => item.count);
    }

    const chart = new Chart(element.current, {
      type: "bar",
      data: {
        labels: labels,
        datasets: [
          {
            data: counts,
            backgroundColor: palette1,
          },
        ],
      },
      options: {
        aspectRatio: 1,
        maintainAspectRatio: false,
        layout: {},
        plugins: {
          legend: false,
        },
        onClick: (event, elements) => {
          const clickedIndex = elements[0]?.index;
          if (clickedIndex !== undefined) {
            const clickedLabel = labels[clickedIndex];
            navigate(
              `/${parkName}/query/companies?industory=${clickedLabel}&pageSize=10&current=1&keywords=`
            );
          }
        },
      },
    });
    return () => chart.destroy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData]);
  return (
    <Spin spinning={status === STATUS.idle}>
      <canvas ref={element} />
    </Spin>
  );
});

export default Chart1;

import supabase from "../..";
// 从member数据库获取数据
export const reqMember = async (parsedQuery, parkId, id) => {
  const {
    pageSize = 10, // 每页条数，默认为10
    current = 1, // 当前页码，默认为1
    keywords, // 关键词搜索
    fee_status, // 费用状态
    startDate, // 起始日期
    endDate, // 结束日期
  } = parsedQuery;

  const start = (current - 1) * pageSize; // 计算分页起始位置
  const end = start + pageSize - 1; // 计算分页结束位置

  // 构建查询 member 表的基础查询
  let query = supabase
    .from("member")
    .select("*", { count: "exact" }) // 查询所有字段，并返回总记录数
    .eq("park_id", parkId) // 过滤 park_id
    .eq("status", id) // 过滤 status
    .eq("is_delete", 1); // 过滤 is_delete 等于 1

  // 根据 id 进行排序
  if (id === "1") {
    query.order("join_date", { ascending: false }); // 如果 id 为 1，则按 join_date 降序排序
  } else {
    query.order("created_at", { ascending: false }); // 否则按 created_at 降序排序
  }

  // 关键词过滤
  if (keywords) {
    query.like("member_name", `%${keywords}%`); // 如果有关键词，则进行模糊查询
  }

  // 费用状态过滤
  if (fee_status) {
    query.eq("fee_status", fee_status); // 如果有费用状态，则进行精确匹配
  }

  // 日期范围过滤（针对 join_date）
  if (startDate && endDate && id === "1") {
    query.or(`and(join_date.gt.${startDate},join_date.lt.${endDate})`); // 如果有起始和结束日期，并且 id 为 1，则按 join_date 过滤
  }

  // 日期范围过滤（针对 created_at）
  if (startDate && endDate) {
    query.or(`and(created_at.gt.${startDate},created_at.lt.${endDate})`); // 如果有起始和结束日期，则按 created_at 过滤
  }

  // 执行查询并获取数据
  const { data: memberData, error: memberError, count } = await query.range(start, end);

  // 如果查询 member 表出错，抛出错误
  if (memberError) {
    throw new Error(memberError.message);
  }

  // 获取所有 company_id
  const companyIds = memberData.map(member => member.company_id);

  // 查询 company 表获取 id_com
  const { data: companyData, error: companyError } = await supabase
    .from("company")
    .select("id, id_com") // 仅查询 id 和 id_com 字段
    .in("id", companyIds); // 过滤 id 在 companyIds 中的记录

  // 如果查询 company 表出错，抛出错误
  if (companyError) {
    throw new Error(companyError.message);
  }

  // 将 company 表数据转换为 Map，方便查找
  const companyMap = companyData.reduce((map, company) => {
    map[company.id] = company.id_com;
    return map;
  }, {});

  // 获取所有 id_com
  const idComs = companyData.map(company => company.id_com);

  // 查询 company_contact 表获取对应的记录
  const { data: contactData, error: contactError } = await supabase
    .from("company_contact")
    .select("*") // 查询所有字段
    .in("id_com", idComs); // 过滤 id_com 在 idComs 中的记录

  // 如果查询 company_contact 表出错，抛出错误
  if (contactError) {
    throw new Error(contactError.message);
  }

  // 将 contactData 转换为 Map，以便按 id_com 分组
  const contactMap = contactData.reduce((map, contact) => {
    if (!map[contact.id_com]) {
      map[contact.id_com] = [];
    }
    map[contact.id_com].push(contact);
    return map;
  }, {});

  // 将 id_com 和对应的 contacts 添加到 memberData 中
  const resultData = memberData.map(member => {
    const id_com = companyMap[member.company_id] || null;
    return {
      ...member, // 保留原有的 member 数据
      id_com, // 添加 id_com 字段
      contacts: contactMap[id_com] || [] // 添加 contacts 字段，如果没有对应的联系人数据，则为 []
    };
  });

  // 构建返回结果
  const result = {
    data: resultData ?? [], // 返回的成员数据
    error: memberError || companyError || contactError, // 返回的错误信息
    count: count, // 返回的记录总数
  };

  return result; // 返回结果
};

// 向member数据库插入用户
export const reqInsertMember = async (memberData) => {
  const { data, count: existingMembersCount } = await reqMemberWithComId(
    memberData
  );
  if (existingMembersCount === 0) {
    let query = supabase.from("member").insert([memberData]).select();
    const { data, error } = await query;

    if (error) {
      throw new Error(error.message);
    }
    return data;
  } else if (data[0].status !== 1) {
    await reqUpdateMemberStatus(memberData);
    return;
  } else {
    throw new Error("会员已存在！");
  }
};

// member中是否存在该用户
export const reqMemberWithComId = async (memberData) => {
  const { data, count } = await supabase
    .from("member")
    .select("status,company_id,park_id", { count: "exact" })
    .eq("park_id", memberData.park_id)
    .eq("company_id", memberData.company_id);
  const result = {
    data,
    count,
  };
  return result;
};

// 向member数据库修改用户
export const reqUpdateMemberStatus = async (memberData) => {
  const { data, error } = await supabase
    .from("member")
    .update(memberData)
    .eq("company_id", memberData.company_id)
    .eq("park_id", memberData.park_id);
  if (error) {
    throw new Error(error.message);
  }
  return data;
};

// 向member数据库删除用户
export const reqDeleteMember = async (memberData) => {
  console.log("memberData :>> ", memberData);
  const { data, error } = await supabase
    .from("member")
    .update({ is_delete: 0, status: 0, fee_status: 2, join_date: null })
    .eq("company_id", memberData.company_id)
    .eq("park_id", memberData.park_id);

  if (error) {
    console.log("error :>> ", error);
  }
  return data;
};

// 向member数据库修改用户
export const reqUpdateMember = async (memberData) => {
  const { data, error } = await supabase
    .from("member")
    .update(memberData)
    .eq("company_id", memberData.company_id)
    .eq("park_id", memberData.park_id);
  if (error) {
    throw new Error(error.message);
  }
  return data;
};

// 获取会员会费状态数量统计
export const reqGetFeeStatusCount = async (parkId) => {
  // 查询 fee_status 为 1 且 park_id 为指定 parkId 的已缴费记录数量
  const { data: feeStatus1, error: error1 } = await supabase
    .from("member") // 指定查询的表为 "member"
    .select("fee_status", { count: "exact" }) // 查询 fee_status 字段，并返回总记录数
    .eq("fee_status", 1) // 过滤条件：fee_status 等于 1
    .eq("park_id", parkId) // 过滤条件：park_id 等于传入的 parkId
    .eq("status", 1) // 过滤条件：status 等于 1
    .eq("is_delete", 1); // 过滤 is_delete 等于 1

  // 如果查询出错，抛出错误
  if (error1) {
    throw new Error(error1.message);
  }

  // 查询 park_id 为指定 parkId 的所有会员记录数量
  const { data: totalMembers, error: error2 } = await supabase
    .from("member") // 指定查询的表为 "member"
    .select("id", { count: "exact" }) // 查询 id 字段，并返回总记录数
    .eq("park_id", parkId) // 过滤条件：park_id 等于传入的 parkId
    .eq("status", 1) // 过滤条件：status 等于 1
    .eq("is_delete", 1); // 过滤 is_delete 等于 1

  // 如果查询出错，抛出错误
  if (error2) {
    throw new Error(error2.message);
  }

  // 返回统计结果，包含 fee_status 为 1 的记录数和所有会员的总数
  return {
    feeStatus1: feeStatus1.length, // fee_status 为 1 的记录数
    totalMembers: totalMembers.length // 所有会员的总数
  };
};

// 批量修改会员状态
export const reqUpdateMembersFeeStatus = async (parkId, memberIds, feeStatus) => {
  const { data, error } = await supabase
    .from("member")
    // 设置更新的字段和值，这里将 fee_status 设置为传入的 feeStatus 值，1（已缴费），2（未缴费）
    .update({ fee_status: feeStatus })
    // 根据传入的 parkId 进行筛选
    .eq("park_id", parkId)
    // 根据传入的 memberIds 数组进行筛选，只更新这些 ID 对应的记录
    .in("id", memberIds);

  // 如果有错误，抛出异常并输出错误信息
  if (error) {
    throw new Error(error.message);
  }

  // 返回结果
  return data;
};

// 批量处理会员退会
export const reqUpdateMembersStatus = async (parkId, memberIds) => {
  const { data, error } = await supabase
    .from("member")
    // 更新 is_delete 字段， 将 is_delete 字段设置为 0（退会）
    .update({ is_delete: 0, status: 0, fee_status: 2, join_date: null })
    .eq("park_id", parkId) // 根据传入的 parkId 进行筛选
    .in("id", memberIds); // 根据传入的 memberIds 数组进行筛选，只更新这些 ID 对应的记录

  // 如果有错误，抛出异常并输出错误信息
  if (error) {
    throw new Error(error.message);
  }

  // 返回结果
  return data;
};

import { useRef, useEffect } from "react";
import Chart from "chart.js/auto";
import { palette1 } from "./palette";
import { Spin } from "antd";
import { STATUS } from "../../constants";
import { memo } from "react";

const Chart2 = memo(({ kanbanFilterInfo, status }) => {
  const element = useRef();
  const filterData = kanbanFilterInfo.filter((item) => item.cloumn === 2);
  useEffect(() => {
    let counts = [];
    if (filterData.length > 0) {
      counts = filterData[0].context;
    }
    const chart = new Chart(element.current, {
      type: "bar",
      data: {
        labels: [
          "5000万及以上",
          "1000~5000万",
          "500~1000万",
          "50~500万",
          "50万以下",
        ],
        datasets: [
          {
            data: [counts.a, counts.b, counts.c, counts.d, counts.e],
            backgroundColor: palette1,
          },
        ],
      },
      options: {
        aspectRatio: 1.5,
        layout: {
          // padding: 16
        },
        plugins: {
          legend: false,
        },
      },
    });
    return () => chart.destroy();
  }, [filterData]);
  return (
    <Spin spinning={status === STATUS.idle}>
      <canvas ref={element} />
    </Spin>
  );
});

export default Chart2;

import { Result, Button } from "antd";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../context";

function Page404() {
  const { parkName } = useContext(UserContext);

  const navigate = useNavigate();
  const onclick = () => {
    navigate(`/${parkName}/kanban/1`);
  };
  return (
    <Result
      status="404"
      title="页面走失了"
      subTitle="很抱歉，你访问的页面不存在"
      extra={
        <Button onClick={() => onclick()} type="primary">
          返回首页
        </Button>
      }
    />
  );
}

export default Page404;

import supabase from "..";
export const reqCompaniesLists = async (parsedQuery, parkId) => {
  const defaultQuery = {
    pageSize: 10,
    current: 1,
    keywords: "",
    sortFiled: "",
    sortOrder: "",
    highTech: "",
    industory: "",
    tags: "",
  };
  // 合并默认查询参数和用户传递的查询参数
  parsedQuery = { ...defaultQuery, ...parsedQuery };
  const { pageSize, current, keywords } = parsedQuery;
  const start = (current - 1) * pageSize;
  const end = start + pageSize - 1;
  let query = supabase
    .from("company")
    .select(
      `id,id_com,format_name,credit_no,domains,reg_no,trademarks,patents,trademarks,copyrights,copyrightSofts,abnormal_items,risk_trademark,risk_patent,com_tags)`,
      { count: "exact" }
    )
    .eq("park_id", parkId);

  if (parsedQuery.highTech) {
    const { data: ids } = await reqTechCompanyWithLevel(
      parsedQuery.highTech,
      parkId
    );
    query.in("id", ids);
  }
  if (parsedQuery.sortOrder) {
    query.order(parsedQuery.sortFiled, {
      ascending: parsedQuery.sortOrder === "ascend" ? true : false,
    });
  } else {
    query.order("id", { ascending: "false" });
  }
  if (parsedQuery.industory) {
    query.like("domains", `%${parsedQuery.industory}%`);
  }
  if (parsedQuery.tags) {
    const str = parsedQuery.tags.replace(",", ";");
    query.like("com_tags", `%${str}%`);
  }
  if (keywords) {
    query.or(`format_name.like.%${keywords}%,credit_no.like.%${keywords}%`);
  }
  const { data, count, error } = await query.range(start, end);
  if (error) {
    console.error("获取数据失败:", error.message);
  }
  const result = {
    data: data ?? [],
    count: count ?? 0,
  };
  return result;
};

// 根据国家级等级别获取符合的公司列表ids
export const reqTechCompanyWithLevel = async (pType, parkId) => {
  const { data, error, count } = await supabase
    .from("park_tech")
    .select("company_id", { count: "exact" })
    .eq("park_id", parkId)
    .eq("type_level", pType);
  const result = {
    data: data ? [...new Set(data.map((item) => item.company_id))] : [],
    error: error,
    count: count ?? 0,
  };
  return result;
};

// 获取公司荣誉
export const reqTechCompany = async (pType, parkId) => {
  const { data, error, count } = await supabase
    .from("park_tech")
    .select("company_id", { count: "exact" })
    .eq("park_id", parkId)
    .eq("type", pType);
  const result = {
    data: data ? [...new Set(data.map((item) => item.company_id))] : [],
    error: error,
    count: count ?? 0,
  };
  return result;
};

// 获取风险数据
export const reqCompaniesRiskLists = async (parsedQuery, parkId) => {
  const defaultQuery = {
    pageSize: 10,
    current: 1,
    keywords: "",
    riskType: "",
    sortFiled: "",
    sortOrder: "",
    riskIndustory: "",
  };
  parsedQuery = { ...defaultQuery, ...parsedQuery };

  const { pageSize, current, keywords } = parsedQuery;
  const start = (current - 1) * pageSize;
  const end = start + pageSize - 1;

  let query = supabase
    .from("company")
    .select(
      `id,id_com,format_name,credit_no,domains,reg_no,trademarks,patents,trademarks,copyrights,copyrightSofts,abnormal_items,risk_trademark,risk_patent,com_tags,risk_trademark_count,risk_patent_count,abnormal_count)`,
      { count: "exact" }
    )
    .eq("park_id", parkId)
    // 查询具有异常计数、专利风险计数或商标风险计数不为零的公司
    .or(
      "abnormal_count.neq.0,or(risk_patent_count.neq.0,risk_trademark_count.neq.0)"
    );
  if (parsedQuery.sortOrder) {
    query.order(parsedQuery.sortFiled, {
      ascending: parsedQuery.sortOrder === "ascend" ? true : false,
    });
  } else {
    query.order("id", { ascending: "false" });
  }

  if (parsedQuery.riskIndustory) {
    query.like("domains", `%${parsedQuery.riskIndustory}%`);
  }
  if (parsedQuery.riskType === 1) {
    query.neq("abnormal_items", "[]");
  }
  if (parsedQuery.riskType === 2) {
    query.neq("risk_trademark", "[]");
  }
  if (parsedQuery.riskType === 3) {
    query.neq("risk_patent", "[]");
  }
  if (keywords) {
    query.or(`format_name.like.%${keywords}%,credit_no.like.%${keywords}%`);
  }

  const { data, count, error } = await query.range(start, end);
  if (error) {
    console.error("获取数据失败:", error.message);
  }

  const result = {
    data: data ?? [],
    count: count ?? 0,
  };
  return result;
};

// 获取domains
export const reqDomains = async (parkId) => {
  const { data, error } = await supabase
    .from("company")
    .select("domains,com_tags")
    .eq("park_id", parkId);
  if (error) {
    console.error("获取数据失败:", error.message);
  }
  const newData = data?.map((item) => item.domains);
  const newTags = data?.map((item) => item.com_tags).filter(Boolean);
  const resultTags = new Set();
  newTags?.forEach((str) => {
    const parts = str.split(";");
    parts?.forEach((part) => {
      resultTags.add(part);
    });
  });
  const result = {
    data: newData ? [...new Set(newData)].filter((item) => item) : [],
    tags: resultTags ? [...resultTags] : [],
    error: error,
  };
  return result;
};

// 获取companyType
export const reqTypes = async (parkId) => {
  const { data, error } = await supabase
    .from("park_tech")
    .select("type_level")
    .eq("park_id", parkId);
  if (error) {
    console.error("获取数据失败:", error.message);
  }
  const newData = data?.map((item) => item.type_level);
  const result = {
    data: newData ? [...new Set(newData)] : [],
    error: error,
  };
  return result;
};

export const reqTech = async (ids) => {
  const { data, count, error } = await supabase
    .from("tech_ent")
    .select("*", { count: "exact" })
    .in("company_id", ids);
  if (error) {
    console.error("获取数据失败:", error.message);
  }
  const result = {
    data: data ?? [],
    count: count ?? 0,
  };
  return result;
};

export const reqBaseTech = async (id) => {
  const { data, count, error } = await supabase
    .from("tech_ent")
    .select("*", { count: "exact" })
    .eq("company_id", id);
  if (error) {
    console.error("获取数据失败:", error.message);
  }
  const result = {
    data: data ?? [],
    count: count ?? 0,
  };
  return result;
};

export const reqUpCompanyTags = async (id_coms, tags) => {
  const { data, error } = await supabase
    .from("company")
    .update({ com_tags: tags })
    .eq("id_com", id_coms);
  if (error) {
    console.error("获取数据失败:", error.message);
  }
  const result = {
    data: data ?? [],
    error: error,
  };
  return result;
};

// 公司基本信息 baseInfo
export const reqCompanyInfo = async (company_id) => {
  const { data, error } = await supabase
    .from("company")
    .select(`*`)
    .eq("id", company_id);

  if (error) {
    console.error("获取数据失败:", error.message);
  }
  const result = {
    data: data ?? [],
  };

  return result;
};

//  商标数据
export const reqTrademarksWithRegNo = async (company_id, current = 1) => {
  const start = (current - 1) * 10;
  const end = start + 9;

  const { data, count, error } = await supabase
    .from("trademark")
    .select("*", { count: "exact" })
    .eq("company_id", company_id)
    .range(start, end);
  if (error) {
    console.error("获取数据失败:", error.message);
  }
  const result = {
    data: data ?? [],
    count: count ?? 0,
  };
  return result;
};

// 专利数据
export const reqPatentsWithRegNo = async (company_id, current = 1) => {
  const start = (current - 1) * 10;
  const end = start + 9;

  const { data, count, error } = await supabase
    .from("patent")
    .select("*", { count: "exact" })
    .eq("company_id", company_id)
    .range(start, end);
  if (error) {
    console.error("获取数据失败:", error.message);
  }
  const result = {
    data: data ?? [],
    count: count ?? 0,
  };

  return result;
};

// 著作权
export const reqCopyRightsWithRegNo = async (company_id, current = 1) => {
  const start = (current - 1) * 10;
  const end = start + 9;

  const { data, count, error } = await supabase
    .from("copyright")
    .select("*", { count: "exact" })
    .eq("company_id", company_id)
    .range(start, end);
  if (error) {
    console.error("获取数据失败:", error.message);
  }
  const result = {
    data: data ?? [],
    count: count ?? 0,
  };
  return result;
};

// 软著
export const reqSoftwaresWithRegNo = async (company_id, current = 1) => {
  const start = (current - 1) * 10;
  const end = start + 9;

  const { data, count, error } = await supabase
    .from("copyright_soft")
    .select("*", { count: "exact" })
    .eq("company_id", company_id)
    .range(start, end);
  if (error) {
    console.error("获取数据失败:", error.message);
  }
  const result = {
    data: data ?? [],
    count: count ?? 0,
  };
  return result;
};

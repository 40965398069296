import supabase from "../index";
export const reqEmpowerment = async (parkId) => {
  const { data, error } = await supabase
    .from("park_brand")
    .select(`*,brand_empowerment!inner(*)`, { count: "exact" })
    .eq("park_id", parkId);

  if (error) {
    console.error("获取数据失败:", error.message);
  }

  const result = {
    data: data
      ? data.map((item) => {
          const { brand_empowerment, ...rest } = item;
          return {
            ...brand_empowerment,
            ...rest,
          };
        })
      : [],
  };
  return result;
};

export const reqEmpowermentInfo = async (id) => {
  const { data, error } = await supabase
    .from("brand_empowerment")
    .select()
    .eq("id", id);

  if (error) {
    console.error("获取数据失败:", error.message);
  }
  const result = {
    data: data ?? [],
  };
  return result;
};

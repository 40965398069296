import { useEffect, useState, useContext } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { Typography, Tag, Button } from "antd";
import qs from "query-string";
import PageHeader from "../../../components/PageHeader";
import ShPageModel from "../../../components/ShPageModel";
import { STATUS } from "../../../constants";
import { UserContext } from "../../../context";
import { reqCompaniesLists, reqTech } from "../../../service/query/companies";
import { reqAxiosCommentsLists } from "../../../api/lists";

export default function Companies() {
  const location = useLocation();
  const navigate = useNavigate();
  const { parkId, parkName } = useContext(UserContext);
  const [status, setStatus] = useState(STATUS.idle);
  const [CompaniesList, setCompaniesList] = useState([]);
  const [tench, setTench] = useState([]);
  const [total, setTotal] = useState(1);
  const [query, setQuery] = useState({});
  const [sort1, setSort1] = useState("");
  const [sort2, setSort2] = useState("");
  const [sort3, setSort3] = useState("");
  const [sort4, setSort4] = useState("");

  // 声明一个状态变量用于保存选中的行
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  // 声明一个状态变量用于保存 id 的数组
  const [companyIds, setCompanyIds] = useState([]);

  // 当复选框状态改变时触发
  const onSelectChange = (newSelectedRowKeys, selectedRows, dataSource, rowKey) => {
    // console.log(`selectedRowKeys: ${newSelectedRowKeys}`, 'selectedRows: ', selectedRows);

    // 获取当前页面的选中行的 id
    const ids = selectedRows.map(row => row.id);

    // 从数据源中提取出每一项的行键（默认为 'id'），生成当前页面所有行的键数组
    const currentPageKeys = dataSource.map(item => item[rowKey ?? 'id']);

    // 更新选中的行键数组：
    // 1. 保留当前选中行键中那些在当前页面中的行键，并且这些行键仍然被新选中的行键包含。
    // 2. 保留当前选中行键中那些不在当前页面中的行键（这些行键不会被新选中的行键影响）。
    const updatedSelectedRowKeys = selectedRowKeys.filter(key =>
      currentPageKeys.includes(key) ? newSelectedRowKeys.includes(key) : true
    );

    // 更新选中的 id 数组：
    // 1. 保留当前选中 id 中那些在当前页面中的 id，并且这些 id 仍然被新选中的行数据包含。
    // 2. 保留当前选中 id 中那些不在当前页面中的 id（这些 id 不会被新选中的行数据影响）。
    const updatedCompanyIds = companyIds.filter(id =>
      currentPageKeys.includes(dataSource.find(item => item.id === id)?.[rowKey ?? 'id']) ? ids.includes(id) : true
    );

    // 遍历新选中的行键数组，将不在 updatedSelectedRowKeys 中的行键添加进去
    newSelectedRowKeys.forEach(key => {
      if (!updatedSelectedRowKeys.includes(key)) {
        updatedSelectedRowKeys.push(key);
      }
    });

    // 遍历新选中的 id 数组，将不在 updatedCompanyIds 中的 id 添加进去
    ids.forEach(id => {
      if (!updatedCompanyIds.includes(id)) {
        updatedCompanyIds.push(id);
      }
    });

    // 更新状态
    setSelectedRowKeys(updatedSelectedRowKeys);
    setCompanyIds(updatedCompanyIds);
  };

  // 表格行选择配置
  const rowSelection = {
    selectedRowKeys,  // 当前选中的行键数组
    // 当行选择变化时，调用 onSelectChange 函数，并传递新选中的行键、新选中的行数据、当前页面的数据源和行键字段（默认为 'id_com'）
    onChange: (newSelectedRowKeys, selectedRows) => onSelectChange(newSelectedRowKeys, selectedRows, CompaniesList, 'id_com'),
  };

  // 清空复选框选中状态
  const resetSelection = () => {
    // console.log("调用了清空复选框选中状态");
    // 清空复选框选中状态
    setSelectedRowKeys([]);
    // 清空 id 数组
    setCompanyIds([]);
  };

  // table的配置文件
  const companiesTableConfig = {
    nameList: [
      {
        title: "企业名称",
        width: 258,
        fixed: "left",
        dataIndex: "format_name",
        render: (data, record) => (
          <Link
            to={{
              pathname: `/${parkName}/query/companies/${record.id}`,
              search: qs.stringify({ company_id: record.id }),
            }}
            state={record}
          >
            {data}
          </Link>
        ),
      },
      {
        title: "统一社会信用代码",
        dataIndex: "credit_no",
      },
      {
        title: "所属行业",
        dataIndex: "domains",
        render: (data) =>
          data && JSON.parse(data).map((item) => <div key={item}>{item}</div>),
      },
      {
        title: "企业荣誉",
        dataIndex: "tech_ent",
        render: (data) => {
          const typeDict = data.reduce((result, item) => {
            if (item.state === "有效" && !result[item.type]) {
              result[item.level + item.type] = true;
            }
            return result;
          }, {});

          return Object.keys(typeDict).map((type) => (
            <Tag color="#97CE74" key={type}>
              {type}
            </Tag>
          ));
        },
      },
      {
        title: "商标",
        align: "center",
        dataIndex: "trademarks",
        render: (data) =>
          data > 0 ? (
            <Typography.Text type="success" strong>
              {data}
            </Typography.Text>
          ) : (
            ""
          ),
        sorter: true,
        sortOrder: sort1,
      },
      {
        title: "专利",
        align: "center",
        dataIndex: "patents",
        render: (data) =>
          data > 0 ? (
            <Typography.Text type="success" strong>
              {data}
            </Typography.Text>
          ) : (
            ""
          ),
        sorter: true,
        sortOrder: sort2,
      },
      {
        title: "著作权",
        align: "center",
        dataIndex: "copyrights",
        render: (data) =>
          data > 0 ? (
            <Typography.Text type="success" strong>
              {data}
            </Typography.Text>
          ) : (
            ""
          ),
        sorter: true,
        sortOrder: sort3,
      },
      {
        title: "软件著作权",
        align: "center",
        dataIndex: "copyrightSofts",
        render: (data) =>
          data > 0 ? (
            <Typography.Text type="success" strong>
              {data}
            </Typography.Text>
          ) : (
            ""
          ),
        sorter: true,
        sortOrder: sort4,
      },
      {
        title: "标签",
        dataIndex: "com_tags",
        render: (data) =>
          data &&
          data
            .split(";")
            .filter(Boolean)
            .map((item) => (
              <Tag color="#2db7f5" key={item}>
                {item}
              </Tag>
            )),
      },
    ],
  };
  // 表格发生变化时出发的函数，传递给form组件的change
  // 切换升降序时，修改url中的两个有关排序的参数
  const handleTableChange = (pagination, filters, sorter) => {
    const newQuery = {
      // query 在 useEffect中已经被替换为新的值
      ...query,
      sortFiled: sorter.field,
      sortOrder: sorter.order,
    };
    let newUrl = `?${Object.entries(newQuery)
      .map(([key, value]) => `${key}=${value ?? ""}`)
      .join("&")}`;
    navigate(newUrl);
  };

  useEffect(() => {
    const parsedQuery = qs.parse(location.search.slice(1), {
      parseNumbers: true,
    });
    // 页面刷新时间设置升降序的三角形的颜色标识
    setSort1(
      parsedQuery.sortFiled === "trademarks" ? parsedQuery.sortOrder : ""
    );
    setSort2(parsedQuery.sortFiled === "patents" ? parsedQuery.sortOrder : "");
    setSort3(
      parsedQuery.sortFiled === "copyrights" ? parsedQuery.sortOrder : ""
    );
    setSort4(
      parsedQuery.sortFiled === "copyrightSofts" ? parsedQuery.sortOrder : ""
    );
    setQuery(parsedQuery);

    if (parkId && status === STATUS.idle) {
      const doPost = () => {
        reqCompaniesLists(parsedQuery, parkId)
          .then((res) => {
            setCompaniesList(res.data);
            setTotal(res.count);
            return res;
          })
          .then((res) => {
            reqTech(res?.data.map((item) => item.id)).then((res) => {
              setTench(res.data);
              setStatus(STATUS.success);
            });
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
            setStatus(STATUS.error);
          });
      };
      doPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, total, parkId, status]);
  // 切换园区时，重新发起请求
  useEffect(() => {
    if (parkId) {
      setStatus(STATUS.idle);
    }
  }, [parkId, location]);
  return (
    <>
      <PageHeader
        title="企业数据检索"
        description="检索入驻园区企业的各类相关数据，包括工商注册信息、企业资质、经营异常信息等。"
      />
      <ShPageModel
        status={status}
        config={companiesTableConfig}
        dataSource={CompaniesList}
        total={total}
        rowKey={"id_com"}
        tench={tench}
        reqExport={reqAxiosCommentsLists}
        handleTableChange={handleTableChange}
        placeholder={"企业名称/统一信用代码"}
        rowSelection={rowSelection} // 复选框: 传递 rowSelection 属性
        companyIds={companyIds} // 传递 companyIds
        resetSelection={resetSelection} // 清空复选框: 传递 resetSelection 属性
      />
    </>
  );
}

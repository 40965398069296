import { Col, Form, Select, DatePicker } from "antd";
import { fee_status } from "../../../intlClass";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { UserContext } from "../../../context";
import qs from "query-string";
import dayjs from "dayjs";
export default function VipFilter({ rangeChange, form }) {
  const { RangePicker } = DatePicker;
  const { pathname, search } = useLocation();
  const { parkId } = useContext(UserContext);
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (parkId && pathname.indexOf("/vip/1") !== -1) {
      setShow(true);
      let parsedQuery = qs.parse(search.slice(1), {
        parseNumbers: true,
      });
      Object.entries(parsedQuery).forEach(([key, value]) => {
        if (key === "FinanceRecordRangetime") {
          value &&
            form.setFieldValue(key, [
              dayjs(parsedQuery.startDate, "YYYY-MM-DD"),
              dayjs(parsedQuery.endDate, "YYYY-MM-DD"),
            ]);
        } else {
          value && form.setFieldValue(key, value);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parkId, pathname, search]);
  return (
    <>
      {show && (
        <>
          <Col>
            <Form.Item name="fee_status">
              <Select
                placeholder="会费状态"
                onChange={() => form.submit()}
                allowClear
                showSearch
                style={{ minWidth: "120px" }}
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                options={fee_status.map((item) => {
                  return {
                    label: item.title,
                    value: item.id,
                  };
                })}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name="FinanceRecordRangetime">
              <RangePicker
                format={"YYYY-MM-DD"}
                placeholder={["入会起始时间", "入会截止时间"]}
                onChange={rangeChange}
              />
            </Form.Item>
          </Col>
        </>
      )}
    </>
  );
}

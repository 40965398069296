import { Col, Form, Select, DatePicker } from "antd";
import { clazzes, shapeFlag } from "../../../intlClass";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { UserContext } from "../../../context";
import qs from "query-string";
import dayjs from "dayjs";
export default function TnFilter({ rangeChange, form }) {
  const { RangePicker } = DatePicker;
  const { pathname, search } = useLocation();
  const { parkId } = useContext(UserContext);
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (parkId && pathname.indexOf("/transaction") !== -1) {
      setShow(true);
      let parsedQuery = qs.parse(search.slice(1));
      Object.entries(parsedQuery).forEach(([key, value]) => {
        if (key === "rangetime") {
          value &&
            form.setFieldValue(key, [
              dayjs(parsedQuery.startDate, "YYYY-MM-DD"),
              dayjs(parsedQuery.endDate, "YYYY-MM-DD"),
            ]);
        } else if (key === "brandtype" || key === "group") {
          value && form.setFieldValue(key, [...(value + "").split(",")]);
        } else {
          value && form.setFieldValue(key, value);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parkId, pathname, search]);
  return (
    <>
      {show && (
        <>
          <Col>
            <Form.Item name="brandtype">
              <Select
                mode="multiple"
                allowClear
                onChange={() => form.submit()}
                placeholder="商标分类"
                showSearch
                style={{ minWidth: "160px" }}
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                options={clazzes.map((d) => ({
                  value: d.id + "",
                  label: d.title,
                }))}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name="group">
              <Select
                mode="tags"
                style={{ minWidth: "160px" }}
                placeholder="关联群组"
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name="character">
              <Select
                placeholder="字符数量"
                allowClear
                onChange={() => form.submit()}
                showSearch
                style={{ minWidth: "160px" }}
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                options={[
                  { value: "1", label: "1个" },
                  { value: "2", label: "2个" },
                  { value: "3", label: "3个" },
                  { value: "4", label: "4个" },
                  { value: "5", label: "5个" },
                  { value: "6", label: "6个及以上" },
                ]}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name="outline">
              <Select
                placeholder="商标样式"
                allowClear
                onChange={() => form.submit()}
                showSearch
                style={{ minWidth: "160px" }}
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                options={
                  shapeFlag &&
                  shapeFlag.map((s) => ({
                    value: s.id,
                    label: s.title,
                  }))
                }
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name="rangetime">
              <RangePicker
                format={"YYYY-MM-DD"}
                placeholder={["注册开始时间", "注册结束时间"]}
                onChange={rangeChange}
              />
            </Form.Item>
          </Col>
        </>
      )}
    </>
  );
}

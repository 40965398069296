import supabase from "..";
// 列表
export const reqOrders = async (parkId) => {
  const { data, error, count } = await supabase
    .from("order")
    .select(`*,brand_empowerment(title)`, { count: "exact" })
    .eq("park_id", parkId);
  if (error) {
    console.error("获取数据失败:", error.message);
  }
  const result = {
    data: data ? data.sort((a, b) => b.id - a.id) : [],
    count: count ?? 0,
  };
  return result;
};
//上传文件
export const reqUpload = async (file) => {
  const format = file.name.split(".").pop();
  const { data, error } = await supabase.storage
    .from("comment_files")
    .upload(`${file.uid + "." + format}`, file, {
      cacheControl: "3600",
      upsert: false,
    });
  if (error) {
    console.log("error :>> ", error);
  }
  const result = {
    data: data ?? [],
  };
  return result;
};
// 删除单个文件
export const reqDelete = async (file) => {
  const filename = file.uid + "." + file.name.split(".").pop();

  const { data, error } = await supabase.storage
    .from("comment_files")
    .remove([`${filename}`]);
  const result = {
    data: data ?? [],
    error,
  };
  return result;
};
// 删除多个文件
export const reqDeleteFiles = async (file) => {
  const filenames = file.map(
    (item) =>
      item.originFileObj.uid + "." + item.originFileObj.name.split(".").pop()
  );
  if (filenames.length === 0) return;
  const { data, error } = await supabase.storage
    .from("comment_files")
    // .remove([`${(filenames[0], filenames[1])}`]);
    .remove(filenames);
  const result = {
    data: data ?? [],
    error,
  };
  return result;
};
//  下载文件
export const reqDown = async (fileName) => {
  const query = await supabase.storage
    .from("public/comment_files")
    .download(`${fileName}`);
  const { data, error } = query;
  if (error) {
    console.log("error :>> ", error);
  }
  const result = {
    data: data ?? [],
  };
  return result;
};
// 记录文件名字
export const reqUploadName = async (file, commentId) => {
  const files = file.map((item) => ({
    file_name: item.uid + "." + item.name.split(".").pop(),
    old_name: item.name,
    comment_id: commentId,
  }));

  const { data, error } = await supabase.from("order_file").insert(files);

  if (error) {
    console.log("error :>> ", error);
  }
  const result = {
    data: data ?? [],
  };
  return result;
};
// 搜索
export const reqOrdersWithId = async (parkId, orderId) => {
  const query = supabase
    .from("order")
    .select("*,brand_empowerment!inner(title)", { count: "exact" })
    .eq("park_id", parkId);
  if (orderId && orderId.startsWith("HQHT")) {
    const id = orderId.slice(10);
    query.eq("id", id);
  } else if (orderId) {
    query.eq("brand_empowerment.title", orderId);
  }
  const { data, error, count } = await query;
  if (error) {
    console.error("获取数据失败:", error.message);
  }
  const result = {
    data: data ?? [],
    count: count ?? 0,
  };
  return result;
};
// 详情
export const reqOrderInfoWithId = async (parkId, orderId) => {
  const { data, error, count } = await supabase
    .from("order")
    .select(`*,brand_empowerment(*)`, { count: "exact" })
    .eq("park_id", parkId)
    .eq("id", orderId);

  if (error) {
    console.error("获取数据失败:", error.message);
  }
  const result = {
    data: data ?? [],
    count: count ?? 0,
  };
  return result;
};
// 查询园区的名字
export const reqParkName = async (parkId) => {
  const { data, error } = await supabase
    .from("park")
    .select("name")
    .eq("id", parkId);

  if (error) {
    console.error("获取数据失败:", error.message);
  }
  const result = {
    data: data ?? [],
  };
  return result;
};

// 查询评论
export const reqComments = async (parkId, orderId) => {
  const { data, error, count } = await supabase
    .from(`comment`)
    .select(`*,order_file(*)`)
    .eq("park_id", parkId)
    .eq("order_id", orderId);

  if (error) {
    console.error("获取数据失败:", error.message);
  }
  const result = {
    data: data ?? [],
    count: count ?? 0,
  };
  return result;
};
// 增加评论
export const reqInsComment = async (content, parkId, orderId, userId = 0) => {
  const { data, error, count } = await supabase
    .from("comment")
    .insert([
      {
        content: content,
        order_id: orderId,
        park_id: parkId,
        role_flag: userId,
      },
    ])
    .select();

  if (error) {
    console.error("获取数据失败:", error.message);
  }
  const result = {
    data: data ?? [],
    count: count ?? 0,
  };
  return result;
};
// 数量
export const reqOrderCount = async (parkId) => {
  const { count: idle } = await supabase
    .from("order")
    .select(`*,brand_empowerment(*)`, { count: "exact" })
    .eq("park_id", parkId)
    .eq("status", 0);
  const { count: loading } = await supabase
    .from("order")
    .select(`*,brand_empowerment(*)`, { count: "exact" })
    .eq("park_id", parkId)
    .eq("status", 1);
  const { count: success } = await supabase
    .from("order")
    .select(`*,brand_empowerment(*)`, { count: "exact" })
    .eq("park_id", parkId)
    .eq("status", 2);
  const { count: cancel } = await supabase
    .from("order")
    .select(`*,brand_empowerment(*)`, { count: "exact" })
    .eq("park_id", parkId)
    .eq("status", 3);

  const counts = {
    idle,
    loading,
    success,
    cancel,
  };
  return counts;
};

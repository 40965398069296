import { Descriptions, Typography, Spin, Image, Tag } from "antd";
import { NumberOutlined } from "@ant-design/icons";
import styles from "./index.module.css";
import { STATUS } from "../../constants";
import { clazzes, shapeFlag, trademarkKinds } from "../../intlClass";
import dayjs from "dayjs";
var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);
export default function ShDescription({ config, dataSource, status }) {
  // console.log(dataSource, 'dataSource -- 参数');
  const { Paragraph } = Typography;
  return (
    <>
      <Typography.Title className={styles.title} id={config.title} level={5}>
        <NumberOutlined />
        {config.title}
      </Typography.Title>
      <Spin spinning={status === STATUS.success ? false : true}>
        <Descriptions
          column={config.column}
          className={styles.descriptions}
          labelStyle={{ whiteSpace: "nowrap" }}
          bordered
        >
          {config.nameList.map((item, index) => (
            <Descriptions.Item label={item.label} key={index} span={item.span}>
              {dataSource.length > 0 &&
                (() => {
                  if (item.dataIndex === "image_url") {
                    return (
                      <Image
                        style={{ border: "1px rgba(0, 0, 0, .1) solid" }}
                        width={100}
                        src={dataSource[0][item.dataIndex]}
                      />
                    );
                  } else if (item.dataIndex === "request_img") {
                    return (
                      <Image
                        style={{ border: "1px rgba(0, 0, 0, .1) solid" }}
                        width={100}
                        src={`https://biaoxq-oss.oss-cn-beijing.aliyuncs.com/${dataSource[0]["request_no"]}.jpg`}
                      />
                    );
                  } else if (item.dataIndex === "new_status") {
                    return (
                      <Tag color="#1B9C85">{dataSource[0][item.dataIndex]}</Tag>
                    );
                  } else if (item.dataIndex === "telephone") {
                    return JSON.parse(dataSource[0].contact)?.telephone;
                  } else if (item.dataIndex === "type_num_name") {
                    return clazzes[dataSource[0]["type_num"] - 1]?.title;
                  } else if (item.dataIndex === "email") {
                    return JSON.parse(dataSource[0].contact)?.email;
                  } else if (item.dataIndex === "good_kind_name") {
                    return clazzes[dataSource[0]["good_kind"] - 1]?.title;
                  } else if (item.dataIndex === "shape_flag") {
                    return shapeFlag[dataSource[0]["shape_flag"] - 1]?.title;
                  } else if (item.dataIndex === "trademark_kind") {
                    return trademarkKinds.find(
                      (item) => item.id === dataSource[0]["trademark_kind"]
                    )?.trademark;
                  } else if (item.dataIndex === "address") {
                    return JSON.parse(dataSource[0].contact)?.address;
                  } else if (item.dataIndex === "tech_ent") {
                    const typeDict = dataSource[0].tech_ent.reduce(
                      (result, item) => {
                        if (item.state === "有效" && !result[item.type]) {
                          result[item.level + item.type] = true;
                        }
                        return result;
                      },
                      {}
                    );
                    return Object.keys(typeDict).map((type) => (
                      <Tag color="#97CE74" key={type}>
                        {type}
                      </Tag>
                    ));
                  } else if (item.dataIndex === "category_num") {
                    return dataSource[0].category_num
                      .split(";")
                      .map((item) => <Tag key={item}>{item}</Tag>);
                  } else if (item.dataIndex === "products") {
                    return JSON.parse(dataSource[0]?.products).map(
                      (item, index) => (
                        <Tag key={index}>
                          {item.num}
                          {item.name}
                        </Tag>
                      )
                    );
                  } else if (item.dataIndex === "group_id") {
                    return (
                      dataSource[0].group_id &&
                      dataSource[0].group_id
                        .split(",")
                        .map((item, index) => <Tag key={index}>{item}</Tag>)
                    );
                  } else if (item.dataIndex === "domains") {
                    return (
                      dataSource[0].domains &&
                      JSON.parse(dataSource[0]?.domains).map((item) => (
                        <div key={item}>{item}</div>
                      ))
                    );
                  } else if (item.dataIndex === "goods_status") {
                    return dataSource[0].goods_status === 1 ? (
                      <Tag color="green">有效</Tag>
                    ) : (
                      <Tag color="red">无效</Tag>
                    );
                  } else if (item.dataIndex === "scope") {
                    return (
                      <Paragraph ellipsis={{ rows: 2, expandable: true }}>
                        {dataSource[0][item.dataIndex]}
                      </Paragraph>
                    );
                  } else if (item.dataIndex === "registration_date") {
                    return dataSource[0][item.dataIndex]
                      ? dayjs
                          .utc(dataSource[0][item.dataIndex])
                          .format("YYYY年MM月DD日")
                      : "-";
                  } else {
                    return <div>{dataSource[0][item.dataIndex]}</div>;
                  }
                })()}
            </Descriptions.Item>
          ))}
        </Descriptions>
      </Spin>
    </>
  );
}

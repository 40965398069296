import { Row, Col, Statistic, Button, Spin } from "antd";
import styles from "./kanban.module.css";
import {
  Chart1,
  Chart2,
  Chart3,
  Chart4,
  // Chart5,
  Chart6,
} from "../../components/charts";
import PageHeader from "../../components/PageHeader";
import { useNavigate } from "react-router-dom";
import { reqKanBanFilterInfo } from "../../service/kanban";
import { memo, useContext, useEffect, useState } from "react";
import { reqRiskCounts } from "../../service/kanban";
import { UserContext } from "../../context";
import { STATUS } from "../../constants";
import dayjs from "dayjs";
import { reqTechCompany } from "../../service/query/companies";
import { reqGetFeeStatusCount } from "../../service/member/vip";

import RoleConsumer from "../../providerRoute";
var utc = require("dayjs/plugin/utc");

const span = { xs: 24, sm: 24, md: 24, lg: 12, xl: 8, xxl: 8 };
const firstSpan = { span: 24 };

const Kanban1 = memo(() => {
  dayjs.extend(utc);

  const [kanbanFilterInfo, setKanBanFilterInfo] = useState([]);
  const [total, setTotal] = useState(0);
  const [status, setStatus] = useState(STATUS.idle);
  const [riskTotal, setRisksTotal] = useState(0);
  const [techTotal, setTechTotal] = useState(0);
  const [dateTime, setDateTime] = useState("");
  const { parkId, parkName } = useContext(UserContext);

  // 声明一个状态变量，用于存储会费状态的统计数量
  const [feeStatusCount, setFeeStatusCount] = useState({ feeStatus1: 0, totalMembers: 0 });
  // 解构出 role 来判断园区/协会
  const { role } = RoleConsumer();

  const navigate = useNavigate();
  const setCounts = (data) => {
    const filterData = data.filter((item) => item.cloumn === 1);
    if (filterData) {
      const counts = filterData[0]?.context
        .map((item) => item.count)
        .reduce((sum, num) => sum + num, 0);
      setTotal(counts ?? 0);
    }
  };

  useEffect(() => {
    if (parkId) {
      const fetchData = () => {
        const fetchRisk = reqRiskCounts(parkId);
        const fetchKanBan = reqKanBanFilterInfo(parkId, 1);
        const fetchTechCount = reqTechCompany("高新企业", parkId);
        const requests = [fetchRisk, fetchKanBan, fetchTechCount];

        // 只有role === 1时，才请求会费状态的统计数量
        if (role === 1) {
          requests.push(reqGetFeeStatusCount(parkId));
        }

        Promise.all(requests).then(
          ([risk, kanban, tech_total, feeStatus]) => {
            setRisksTotal(risk.count);
            setKanBanFilterInfo(kanban.data);
            setDateTime(kanban.data[0]?.created_at);
            setCounts(kanban.data);
            setTechTotal(tech_total.count);
            // 只有role === 1时，才更新会费状态的统计数量
            if (role === 1) {
              setFeeStatusCount(feeStatus);
            }
            setStatus(STATUS.success);
          }
        ).catch(error => {
          console.error("Failed to fetch data:", error);
          setStatus(STATUS.error);
        });
      };
      if (status === STATUS.idle) {
        fetchData();
      }
    }
  }, [parkId, status, role]);
  useEffect(() => {
    if (parkId) {
      setStatus(STATUS.idle);
      setKanBanFilterInfo([]);
    }
  }, [parkId]);
  return (
    <Spin spinning={status === STATUS.success ? false : true}>
      <PageHeader
        title="企业数据统计"
        description="针对入驻园区的企业、高新技术企业、老字号企业、经营异常企业等数据的统计看板。"
      />
      <div className={styles.timestamp}>
        最后更新时间：
        {dateTime && dayjs.utc(dateTime).format("YYYY年MM月DD日 hh:mm:ss")}
      </div>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <div className={styles.section}>
            <div className={styles.statistic}>
              <div className={styles.item}>
                <Statistic title="企业数量" value={total} />
                <Button
                  type="primary"
                  onClick={() => navigate(`/${parkName}/query/companies`)}
                >
                  查看详情
                </Button>
              </div>
              <div className={styles.divider} />
              <div className={styles.item}>
                <Statistic title="高新技术企业数量" value={techTotal} />
                <Button
                  type="primary"
                  onClick={() =>
                    navigate(
                      `/${parkName}/query/companies?highTech=国家级高新企业&pageSize=10&current=1&keywords=`
                    )
                  }
                >
                  查看详情
                </Button>
              </div>
              {/* <div className={styles.divider} />
              <div className={styles.item}>
                <Statistic title="老字号企业数量" value={0} />
                <Button
                  type="primary"
                  onClick={() => navigate(`/${parkName}/query/companies`)}
                >
                  查看详情
                </Button>
              </div> */}
              <div className={styles.divider} />
              <div className={styles.item}>
                <Statistic title="经营异常企业数量" value={riskTotal} />
                <Button
                  type="primary"
                  onClick={() =>
                    navigate(
                      `/${parkName}/monitoring?riskType=1&pageSize=10&current=1&keywords=`
                    )
                  }
                >
                  查看详情
                </Button>
              </div>
              {/* 只有role === 1时，才显示会费状态的统计数量 */}
              {role === 1 && (
                <>
                  <div className={styles.divider} />
                  <div className={styles.item}>
                    <Statistic
                      title="已缴费 / 会员数量"
                      value={`${feeStatusCount.feeStatus1} / ${feeStatusCount.totalMembers}`}
                    />
                    <Button
                      type="primary"
                      onClick={() =>
                        navigate(
                          `/${parkName}/vip/1`
                        )
                      }
                    >
                      查看详情
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
        </Col>
        <Col {...firstSpan}>
          <div className={styles.section}>
            <div className={styles.chart}>
              <h2>企业所属行业（前20名）</h2>
              <Chart1 kanbanFilterInfo={kanbanFilterInfo} status={status} />
            </div>
          </div>
        </Col>
        <Col {...span}>
          <div className={styles.section}>
            <div className={styles.chart}>
              <h2>企业注册资金</h2>
              <Chart2 kanbanFilterInfo={kanbanFilterInfo} status={status} />
            </div>
          </div>
        </Col>
        <Col {...span}>
          <div className={styles.section}>
            <div className={styles.chart}>
              <h2>企业入驻园区数量（近5年）</h2>
              <Chart3 kanbanFilterInfo={kanbanFilterInfo} status={status} />
            </div>
          </div>
        </Col>
        <Col {...span}>
          <div className={styles.section}>
            <div className={styles.chart}>
              <h2>高新技术企业数量（近5年）</h2>
              <Chart4 kanbanFilterInfo={kanbanFilterInfo} status={status} />
            </div>
          </div>
        </Col>
        {/* <Col {...span}>
          <div className={styles.section}>
            <div className={styles.chart}>
              <h2>老字号企业数量（近5年）</h2>
              <Chart5 />
            </div>
          </div>
        </Col> */}
        <Col {...span}>
          <div className={styles.section}>
            <div className={styles.chart}>
              <h2>经营异常企业数量（近5年）</h2>
              <Chart6 kanbanFilterInfo={kanbanFilterInfo} status={status} />
            </div>
          </div>
        </Col>
      </Row>
    </Spin>
  );
});
export default Kanban1;

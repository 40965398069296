import { Typography } from "antd";
const riskPatentConfig = {
  title: "专利风险",
  nameList: [
    {
      title: "时间",
      width: 120,
      fixed: "left",
      dataIndex: "outhor_date",
    },
    {
      title: "风险",
      dataIndex: "risks",
      render: (data) => (
        <Typography.Text type="danger">待缴年费</Typography.Text>
      ),
    },
    {
      title: "专利名称",
      dataIndex: "patent_name",
    },
    {
      title: "申请号",
      dataIndex: "request_num",
    },
    {
      title: "类型",
      dataIndex: "type_name",
    },
  ],
};
export default riskPatentConfig;

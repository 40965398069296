import styles from "../query.module.css";
import { Row, Col, Anchor, Typography, Tag, Table, Avatar, Image, Button } from "antd";
import { NumberOutlined, LinkOutlined } from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import qs from "query-string";
import { gt } from "lodash";
import PageHeader from "../../../components/PageHeader";
import ShDescription from "../../../components/ShDescription";
import ShDetailPageModel from "../../../components/ShDetailPageModel";
import ShRiskPageModel from "../../../components/shRiskPageModel";
import Tags from "../../../components/Tags";
import { STATUS } from "../../../constants";
import { clazzes } from "../../../intlClass";
import baseDesConfig from "./detailsConfig/baseDesConfig"; // 基本信息配置
// import contactDesConfig from "./detailsConfig/contactDesConfig"; // 直接移除该引入
import copyrightsTabelConfig from "./detailsConfig/copyrightsTabelConfig";
import softwareTableConfig from "./detailsConfig/softwareTableConfig";
import riskTableConfig from "./detailsConfig/riskTableConfig";
import {
  reqCompanyInfo,
  reqTrademarksWithRegNo,
  reqPatentsWithRegNo,
  reqCopyRightsWithRegNo,
  reqSoftwaresWithRegNo,
  reqBaseTech,
} from "../../../service/query/companies";

import {
  reqContactsWithRegNo,
} from '../../../service/details/contacts/index';

import delContacts from './detailsHook/deleteContacts'

import { UserContext } from "../../../context";
import RoleConsumer from "../../../../src/providerRoute";

// 导入编辑联系人组件
import EditContacts from "./detailsCpn/editContact";

export default function Company(props) {
  const location = useLocation();
  // 获取传递过来的 record 对象参数
  const record = location.state;
  const [status, setStatus] = useState(STATUS.idle);
  const [tradestatus, setTradeStatus] = useState(STATUS.idle);
  const [ptstatus, setPtStatus] = useState(STATUS.idle);
  const [cpstatus, setCpStatus] = useState(STATUS.idle);
  const [ststatus, setStStatus] = useState(STATUS.idle);
  const [contactStatus, setContactStatus] = useState(STATUS.idle);

  const [companyInfo, setCompanyInfo] = useState([]);
  const [trademarksList, setTrademarksList] = useState([]);
  const [patentsList, setPatentsList] = useState([]);
  const [copyRightsList, setCopyRightsList] = useState([]);
  const [softWaresList, setSoftwaresList] = useState([]);
  const { parkName } = useContext(UserContext);
  // 联系方式列表数组
  const [contactsList, setContactsList] = useState([]);
  // 解构出 role 来判断园区/协会
  const { role } = RoleConsumer();

  const [currentPage, setCurrentPage] = useState(1); // 声明一个响应式变量保存当前页码数

  // 解构出删除联系人方法
  const { delContactsData } = delContacts();

  // 修改页码数的函数
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // 联系方式表格配置
  const contactsTableConfig = {
    title: "联系方式",
    nameList: [
      {
        title: "姓名",
        dataIndex: "name",
      },
      {
        title: "性别",
        dataIndex: "sex",
        render: (text) => (text === 1 ? "男" : text === 0 ? "女" : ""),
      },
      {
        title: "职务 / 头衔",
        dataIndex: "title",
      },
      {
        title: "联系电话",
        dataIndex: "phone",
      },
      {
        title: "备注",
        dataIndex: "remark",
      },
      {
        title: "操作",
        dataIndex: "actions",
        render: (_, record) => (
          <div className={styles.actionButtons}>
            <EditContacts record={record} onStatusChange={contactsStatusChange} />
            <Button type="link" danger onClick={() => delContactsData(record, contactsStatusChange)}>删除</Button>
          </div>
        ),
      },
    ],
  };

  const patentsTableConfig = {
    title: "专利",
    nameList: [
      {
        title: "专利名称",
        width: 280,
        fixed: "left",
        dataIndex: "patent_name",
        render: (data, record) => (
          <Link
            to={{
              pathname: `/${parkName}/query/patents/${record.id_pt}`,
              search: qs.stringify({ id_pt: record.id_pt }),
            }}
          >
            {data}
          </Link>
        ),
      },
      {
        title: "申请号",
        dataIndex: "request_num",
      },
      {
        title: "类型",
        dataIndex: "type_name",
      },
      {
        title: "法律状态",
        dataIndex: "last_status",
      },
      {
        title: "申请人",
        dataIndex: "patent_person",
      },
      {
        title: "申请时间",
        dataIndex: "request_date",
      },
    ],
  };
  const tradeTableConfig = {
    title: "商标",
    nameList: [
      {
        title: "商标",
        width: 120,
        fixed: "left",
        dataIndex: "image_url",
        render: (data, record) => (
          <Image src={data} alt="xiaomi" style={{ width: "5rem" }}></Image>
        ),
      },
      {
        title: "商标名称",
        dataIndex: "name",
        align: "center",
        render: (data, record) => (
          <Link
            to={{
              pathname: `/${parkName}/query/trademarks/${record.reg_number}`,
              search: qs.stringify({ reg_number: record.reg_number }),
            }}
            state={record}
          >
            {data}
          </Link>
        ),
      },
      {
        title: "注册号",
        dataIndex: "reg_number",
      },
      {
        title: "分类",
        dataIndex: "type_num",
        render: (data) => clazzes[data - 1]?.title,
      },
      {
        title: "注册人",
        dataIndex: "company",
      },
      {
        title: "注册时间",
        dataIndex: "apply_date",
      },
    ],
  };

  useEffect(() => {
    const parsedQuery = qs.parse(location.search.slice(1), {
      parseNumbers: true,
    });
    // console.log(parsedQuery, "parsedQuery");

    // 获取商标列表
    const dofetchTrademarks = async () => {
      reqTrademarksWithRegNo(parsedQuery.company_id).then((res) => {
        // console.log(res, 'res -- 商标res数据');
        setTrademarksList(res);
        setTradeStatus(STATUS.success);
      });
    };
    if (tradestatus === STATUS.idle) {
      dofetchTrademarks();
    }

    // 获取专利列表
    const dofetchPatents = async () => {
      reqPatentsWithRegNo(parsedQuery.company_id).then((res) => {
        // console.log(res, 'res -- 专利res数据');
        setPatentsList(res);
        setPtStatus(STATUS.success);
      });
    };
    if (ptstatus === STATUS.idle) {
      dofetchPatents();
    }

    // 获取著作权列表
    const dofetchCopyRights = async () => {
      reqCopyRightsWithRegNo(parsedQuery.company_id).then((res) => {
        // console.log(res, 'res -- 著作权res数据');
        setCopyRightsList(res);
        setCpStatus(STATUS.success);
      });
    };
    if (cpstatus === STATUS.idle) {
      dofetchCopyRights();
    }

    // 获取软著列表
    const dofetchSoftwares = async () => {
      reqSoftwaresWithRegNo(parsedQuery.company_id).then((res) => {
        // console.log(res, 'res -- 软著res数据');
        setSoftwaresList(res);
        setStStatus(STATUS.success);
      });
    };
    if (ststatus === STATUS.idle) {
      dofetchSoftwares();
    }

    // 获取基本信息
    const doPost = () => {
      reqCompanyInfo(parsedQuery.company_id).then((res) => {
        reqBaseTech(parsedQuery.company_id).then((tech) => {
          // console.log(res, 'res -- 基本信息res数据');
          const data = res.data.map((item) => ({
            ...item,
            tech_ent: tech.data,
          }));
          setCompanyInfo(data);
          setStatus(STATUS.success);
        });
      });
    };
    if (status === STATUS.idle) {
      doPost();
    }

    // 获取联系方式列表
    const dofetchContacts = async () => {
      // console.log(currentPage, 'currentPage--传递的页码');
      reqContactsWithRegNo(record?.id_com, currentPage).then((res) => {
        // console.log(res, 'res -- 联系方式res数据');
        setContactsList(res);
        setContactStatus(STATUS.success);
      });
    };
    if (contactStatus === STATUS.idle) {
      dofetchContacts();
    }
  }, [location, tradestatus, ptstatus, ststatus, cpstatus, status, contactStatus]);

  function contactsStatusChange(newStatus) {
    // 更新状态变量 contactStatus 为新的状态值  用于重新获取数据
    setContactStatus(newStatus);
  }

  const handleAddContact = () => {
    // 更新状态变量 contactStatus 为新的状态值  用于重新获取数据
    setContactStatus(STATUS.idle);
  };

  return (
    <>
      <PageHeader
        title={companyInfo[0]?.format_name}
        description="展示企业的各种公开信息，包括：基本信息、经营信息、知识产权等。"
        breadcrumb={[
          {
            title: (
              <Link to={`/${parkName}/query/companies`}>企业数据检索</Link>
            ),
          },
          { title: `${companyInfo[0]?.format_name ?? ""}` },
        ]}
      />
      <Tags dataSource={companyInfo} status={status} setStatus={setStatus} />

      <Row gutter={32}>
        <Col span={18}>
          {/* 基本信息 */}
          <ShDescription
            // tench={tench}
            status={status}
            config={baseDesConfig} // 使用扩展的基本信息配置
            dataSource={companyInfo}
          />
          {/* 联系信息 */}
          {/* 移除单独的联系信息描述组件 */}
          {/* <ShDescription
            status={status}
            config={contactDesConfig}
            dataSource={companyInfo}
          /> */}
          {/* 联系方式 */}
          {role === 1 && (
            <>
              <ShDetailPageModel
                rowKey={"id"} // 表格行的唯一标识符
                status={contactStatus} // 当前表格的加载状态
                config={contactsTableConfig} // 表格配置，包括列信息和标题
                dataSource={contactsList} // 表格的数据源
                reqInfo={reqContactsWithRegNo} // 请求数据的方法，这里用Promise.resolve返回假数据
                setData={setContactsList} // 更新数据源的函数
                setStatus={setContactStatus} // 更新状态的函数
                showAddButton={true} // 新增：显示添加按钮 以及用于区分是否使用 record.id_com
                onAddButtonClick={handleAddContact} // 新增：添加按钮点击事件处理函数
                id_com={record?.id_com} // 传递 record.id_com 参数
                onPageChange={handlePageChange} // 传递修改页码数的函数
                onContactAdded={handleAddContact} // 传递onContactAdded函数
              />
            </>
          )}
          {/* 风险 */}
          <ShRiskPageModel
            riskName="abnormal_items"
            status={status}
            config={riskTableConfig}
            dataSource={companyInfo}
          />
          {/* 商标 */}
          <ShDetailPageModel
            rowKey={"id_tm"}
            status={tradestatus}
            config={tradeTableConfig}
            dataSource={trademarksList}
            reqInfo={reqTrademarksWithRegNo}
            setData={setTrademarksList}
            setStatus={setTradeStatus}
          />
          {/* 专利 */}
          <ShDetailPageModel
            rowKey={"id_pt"}
            status={ptstatus}
            config={patentsTableConfig}
            dataSource={patentsList}
            reqInfo={reqPatentsWithRegNo}
            setStatus={setPtStatus}
            setData={setPatentsList}
          />
          {/* 著作 */}
          <ShDetailPageModel
            rowKey={"id_cp"}
            status={cpstatus}
            config={copyrightsTabelConfig}
            dataSource={copyRightsList}
            reqInfo={reqCopyRightsWithRegNo}
            setStatus={setCpStatus}
            setData={setCopyRightsList}
          />
          {/* 软著 */}
          <ShDetailPageModel
            rowKey={"id_cs"}
            status={ststatus}
            config={softwareTableConfig}
            dataSource={softWaresList}
            reqInfo={reqSoftwaresWithRegNo}
            setStatus={setStStatus}
            setData={setSoftwaresList}
          />
          <Typography.Title className={styles.title} id="业务咨询" level={5}>
            <NumberOutlined />
            业务咨询
          </Typography.Title>
          <div className={styles.section}>
            <Table
              columns={[
                {
                  title: "订单号",
                  dataIndex: "id",
                  render: (data) => (
                    <Link to="#">
                      <LinkOutlined style={{ marginRight: ".25rem" }} />
                      {data}
                    </Link>
                  ),
                  sorter: (a, b) => (gt(a, b) ? 1 : -1),
                },
                {
                  title: "业务名称",
                  dataIndex: "name",
                  render: (data) => (
                    <div>
                      <Avatar
                        style={{
                          marginRight: ".5rem",
                          backgroundColor: "#333333",
                        }}
                      >
                        S
                      </Avatar>
                      {data}
                    </div>
                  ),
                  sorter: (a, b) => (gt(a, b) ? 1 : -1),
                },
                {
                  title: "咨询人",
                  dataIndex: "client",
                },
                {
                  title: "手机",
                  dataIndex: "mobile",
                },
                {
                  title: "公司",
                  dataIndex: "company",
                },
                {
                  title: "服务状态",
                  dataIndex: "status",
                  render: (data) => <Tag color="#87d068">待受理</Tag>,
                },
                {
                  title: "咨询时间",
                  dataIndex: "createdAt",
                },
                {
                  title: "最后更新时间",
                  dataIndex: "updatedAt",
                },
              ]}
              rowKey="id"
            />
          </div>
        </Col>
        <Col span={6}>
          <Anchor
            offsetTop={96}
            items={[
              { key: "1", href: "#基本信息", title: "基本信息" },
              ...(role === 1 ? [{ key: "2", href: "#联系方式", title: "联系方式" }] : []),
              { key: "3", href: "#经营风险", title: "经营风险" },
              { key: "4", href: "#商标", title: "商标" },
              { key: "5", href: "#专利", title: "专利" },
              { key: "6", href: "#著作权", title: "著作权" },
              { key: "7", href: "#软件著作权", title: "软件著作权" },
              { key: "8", href: "#业务咨询", title: "业务咨询" },
            ]}
          />
        </Col>
      </Row>
    </>
  );
}

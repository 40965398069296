import { Col, Form, Select } from "antd";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { UserContext } from "../../../context";
import qs from "query-string";
import { reqCompanyWithKeyWords } from "../../../service/empowerment/createmodel";
import { reqPatentStatus } from "../../../service/query/patent";
export default function PtFilter({ form }) {
  const { pathname, search } = useLocation();
  const { parkId } = useContext(UserContext);
  const [show, setShow] = useState(false);
  const [patentType, setPatentType] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  useEffect(() => {
    if (parkId && pathname.indexOf("/query/patents") !== -1) {
      setShow(true);
      reqCompanyWithKeyWords(parkId).then((res) => {
        setCompanyList(res.data);
      });
      reqPatentStatus(parkId).then((res) => {
        setPatentType(res.data);
      });
      let parsedQuery = qs.parse(search.slice(1), {
        parseNumbers: true,
      });
      Object.entries(parsedQuery).forEach(([key, value]) => {
        value && form.setFieldValue(key, value);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parkId, pathname, search]);
  return (
    <>
      {show && (
        <>
          <Col>
            <Form.Item name="patentType">
              <Select
                placeholder="专利类型"
                onChange={() => form.submit()}
                allowClear
                showSearch
                style={{ minWidth: "160px" }}
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                options={[
                  {
                    value: "中国实用新型专利",
                    label: "中国实用新型专利",
                  },
                  {
                    value: "中国发明专利",
                    label: "中国发明专利",
                  },
                  {
                    value: "中国外观设计专利",
                    label: "中国外观设计专利",
                  },
                  {
                    value: "PCT",
                    label: "PCT",
                  },
                ]}
              />
            </Form.Item>
          </Col>
          {/* 专利状态 */}
          <Col>
            <Form.Item name="patentTypeStatus">
              <Select
                placeholder="是否授权"
                onChange={() => form.submit()}
                allowClear
                showSearch
                style={{ minWidth: "160px" }}
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                options={[
                  {
                    value: 1,
                    label: "未授权",
                  },
                  {
                    value: 2,
                    label: "已授权",
                  },
                ]}
              />
            </Form.Item>
          </Col>
          {/*法律状态*/}
          <Col>
            <Form.Item name="patentStatus">
              <Select
                placeholder="法律状态"
                onChange={() => form.submit()}
                allowClear
                showSearch
                style={{ minWidth: "180px" }}
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                options={(patentType || []).map((item) => ({
                  label: item.last_status,
                  value: item.last_status,
                }))}
              />
            </Form.Item>
          </Col>
          {/* 注册人 */}
          <Col>
            <Form.Item name="patentCom">
              <Select
                onChange={() => form.submit()}
                allowClear
                showSearch
                placeholder="注册人"
                style={{ minWidth: "160px" }}
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                options={(companyList || []).map((d) => ({
                  value: d.format_name,
                  label: d.format_name,
                }))}
              />
            </Form.Item>
          </Col>
        </>
      )}
    </>
  );
}

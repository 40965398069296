import supabase from "..";
export const park_bar = async () => {
  const { data, error } = await supabase
    .from("park_bar")
    .select("name,context");
  const result = {
    data: data,
    error: error,
  };
  return result;
};

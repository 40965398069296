import { Col, Modal, Row, Select, Form, Button, notification } from "antd";
import { useState } from "react";
import { fee_status } from "../../../../intlClass";
import { useParams } from "react-router-dom";
import { reqUpdateActivityFee } from "../../../../service/member/activity/details";
import { STATUS } from "../../../../constants";
export default function EditFee({ onStatusChange, data }) {
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const { pid } = useParams();
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);
  const onFinish = async () => {
    try {
      const values = await form.validateFields();
      setLoading(true);
      const feeData = {
        ...values,
        meeting_id: parseInt(pid),
        member_id: data.member_id,
      };
      await reqUpdateActivityFee(feeData).then((res) => {
        api.success({
          message: "缴费状态修改成功",
          placement: "top",
          style: {
            marginTop: "3rem",
          },
        });
        onStatusChange(STATUS.idle);
        setOpen(false);
        setLoading(false);
      });
    } catch (error) {}
  };
  return (
    <>
      <Button
        ghost
        type="test"
        style={{ color: "#1B9C85" }}
        onClick={() => setOpen(true)}
      >
        缴费
      </Button>
      <Modal
        title={"修改缴费状态"}
        forceRender
        open={open}
        onOk={onFinish}
        onCancel={() => setOpen(false)}
        width={720}
        confirmLoading={loading}
        destroyOnClose={true}
      >
        {contextHolder}
        <Row gutter={32}>
          <Col span={20}>
            <Form
              wrapperCol={{ span: 19 }}
              initialValues={data}
              labelCol={{ span: 5 }}
              form={form}
            >
              <Form.Item
                label="缴费状态"
                name="fee_status"
                rules={[
                  {
                    required: true,
                    message: "请求选择会费状态!",
                  },
                ]}
              >
                <Select
                  placeholder="缴费状态"
                  onChange={() => form.submit()}
                  allowClear
                  showSearch
                  style={{ minWidth: "120px" }}
                  filterOption={(input, option) =>
                    (option?.label ?? "").includes(input)
                  }
                  options={fee_status.map((item) => {
                    return {
                      label: item.title,
                      value: item.id,
                    };
                  })}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

import { Button } from "antd";
import styles from "./index.module.css";
export default function ParkFn({ park, parkFn }) {
  const arr = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"];
  return (
    <>
      <section>
        <div className={styles.container}>
          <div className={styles.title}>
            <div className={styles.sub}>系统功能介绍</div>
            <h2>
              {park.park_role}的“{arr[parkFn.length]}项功能”
            </h2>
          </div>
          <div className={styles.cards}>
            {parkFn.map((item, index) => (
              <div className={styles.cardItem} key={index}>
                <div className={styles.thumb}>
                  <img src={item.icon_url} alt="图标" />
                </div>
                <div className={styles.title}>{item.title}</div>
                <div className={styles.description}>{item.desc}</div>
                <div className={styles.action}>
                  <Button type="primary" size="large">
                    了解详情
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

import { ConfigProvider } from "antd";
import zh_CN from "antd/lib/locale/zh_CN";
import theme from "./theme";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Index from "./pages";
import Root from "./pages/root";
import Recovery from "./pages/recovery";
import Renew from "./pages/recovery/renew";
import { Kanban1, Kanban2, Kanban3, Kanban4 } from "./pages/kanban";
import {
  QueryCompanies,
  QueryTrademarks,
  QueryPatents,
  QueryCopyrights,
  QueryCompany,
  QueryTrademark,
  QueryPatent,
  QuerySoftware,
} from "./pages/query";
import { RoleProvider, RequireRole } from "./providerRoute";
import Monitoring from "./pages/monitoring";
import MonitroingDetails from "./pages/monitoring/details";
import Empowerment from "./pages/empowerment";
import EmpowermentDetails from "./pages/empowerment/details";
import Consulting from "./pages/consulting";
import ConsultingDetails from "./pages/consulting/details";
import Register from "./pages/register/index";
import Forehead from "./pages/forehead";
import Forbidden from "./pages/forbidden";
import Transaction from "./pages/management/transaction";
import TransactionDetail from "./pages/management/transactionDetail";
import BrandServe from "./pages/management/brandServe";
import Audit from "./pages/audit";
import Vip from "./pages/member/vip";
import VipDetails from "./pages/member/vip/VipDetail";
import Finance from "./pages/member/finance";
import Notice from "./pages/member/notice";
import AuditDetails from "./pages/audit/detail";
import Activity from "./pages/member/activity";
import ActivityDetail from "./pages/member/activity/ActivityDetail";
import Page404 from "./pages/Page404";

function App() {
  return (
    <ConfigProvider locale={zh_CN} theme={theme}>
      <BrowserRouter>
        <RoleProvider>
          <Routes>
            <Route path="/" element={<Forehead />} />
            <Route path="/home" element={<Forehead />} />
            <Route path="/forbidden" element={<Forbidden />} />
            <Route path="/:pid" element={<Index />} />
            <Route path="/:pid/password-recovery" element={<Recovery />} />
            <Route path="/:pid/password-renew" element={<Renew />} />
            <Route path="/register" element={<Register />} />
            <Route path="/:pid" element={<Root />}>
              <Route path="kanban/1" element={<Kanban1 />} />
              <Route path="kanban/2" element={<Kanban2 />} />
              <Route path="kanban/3" element={<Kanban3 />} />
              <Route path="kanban/4" element={<Kanban4 />} />
              <Route path="query/companies" element={<QueryCompanies />} />
              <Route path="query/companies/:id" element={<QueryCompany />} />
              <Route path="query/trademarks" element={<QueryTrademarks />} />
              <Route path="query/trademarks/:id" element={<QueryTrademark />} />
              <Route path="query/patents" element={<QueryPatents />} />
              <Route path="query/patents/:id" element={<QueryPatent />} />
              <Route path="query/copyrights" element={<QueryCopyrights />} />
              <Route path="query/software" element={<QuerySoftware />} />
              <Route path="monitoring/:id" element={<MonitroingDetails />} />
              <Route path="monitoring" element={<Monitoring />} />
              <Route path="transaction" element={<Transaction />} />
              <Route path="transaction/:id" element={<TransactionDetail />} />
              <Route path="audit" element={<Audit />} />
              <Route path="auditdetail/:id" element={<AuditDetails />} />
              <Route path="vip" element={<Navigate to="1" />} />
              <Route
                path="vip/:id"
                element={
                  <RequireRole>
                    <Vip />
                  </RequireRole>
                }
              />
              <Route path="activity" element={<Navigate to="1" />} />
              <Route
                path="activity/:id"
                element={
                  <RequireRole>
                    <Activity />
                  </RequireRole>
                }
              />
              <Route
                path="activity/:id/:pid"
                element={
                  <RequireRole>
                    <ActivityDetail />
                  </RequireRole>
                }
              />
              <Route path="vip/:id/:id" element={<VipDetails />} />
              <Route path="finance" element={<Navigate to="1" />} />
              <Route
                path="finance/:id"
                element={
                  <RequireRole>
                    <Finance />
                  </RequireRole>
                }
              />
              <Route path="notice" element={<Notice />} />
              <Route path="brandserve" element={<BrandServe />} />
              <Route path="consulting/:id" element={<ConsultingDetails />} />
              <Route path="consulting" element={<Consulting />} />
              <Route path="empowerment/:id" element={<EmpowermentDetails />} />
              <Route path="empowerment" element={<Empowerment />} />
              <Route path="*" element={<Page404 />} />
            </Route>
          </Routes>
        </RoleProvider>
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;

import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  notification,
  DatePicker,
} from "antd";
import { useContext, useEffect, useState } from "react";
import styles from "../index.module.css";
import { STATUS } from "../../../../constants";
import { UserContext } from "../../../../context";
import { reqCompanyWithKeyWords } from "../../../../service/empowerment/createmodel";
import {
  reqMemberList,
  reqInsertMemberFee,
} from "../../../../service/member/finance";

export default function AddFee({ onStatusChange, title = "添加会员" }) {
  const [open, setOpen] = useState(false);

  const [api, contextHolder] = notification.useNotification();

  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const { parkId } = useContext(UserContext);
  const onFinish = async () => {
    try {
      const values = await form.validateFields();
      const memberFeeData = {
        ...values,
        park_id: parkId,
      };
      reqInsertMemberFee(memberFeeData).then((res) => {
        api.success({
          message: "缴费记录添加成功",
          placement: "top",
          style: {
            marginTop: "3rem",
          },
        });
        onStatusChange(STATUS.idle);
        setOpen(false);
        form.resetFields();
      });
    } catch (error) {}
  };
  useEffect(() => {
    if (parkId) {
      reqCompanyWithKeyWords(parkId).then((res) => {});
      reqMemberList(parkId).then((res) => {
        setData(res.data);
      });
    }
  }, [parkId]);
  return (
    <>
      <div className={styles.buttons}>
        <Button ghost type="primary" onClick={() => setOpen(true)}>
          添加会费记录
        </Button>
      </div>
      <Modal
        title={title}
        forceRender
        open={open}
        onOk={onFinish}
        onCancel={() => setOpen(false)}
        width={720}
        confirmLoading={!open}
        destroyOnClose={true}
      >
        {contextHolder}
        <Row gutter={32}>
          <Col span={20}>
            <Form
              wrapperCol={{ span: 19 }}
              labelCol={{ span: 5 }}
              form={form}
              name="validateOnly"
            >
              <Form.Item
                label="公司"
                name="member_id"
                rules={[
                  {
                    required: true,
                    message: "请求选择公司!",
                  },
                ]}
              >
                <Select
                  placeholder="请求选择公司"
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? "").includes(input)
                  }
                  // onChange={handleChange}
                  options={(data || []).map((d) => ({
                    value: d.id,
                    label: d.member_name,
                  }))}
                />
              </Form.Item>
              <Form.Item
                name="price"
                label="缴费金额"
                rules={[
                  {
                    required: true,
                    message: "请输入缴费金额!",
                  },
                  {
                    // 金钱的正则
                    pattern: /^[0-9]+(.[0-9]{1,2})?$/,
                    message: "请输入正确的缴费金额!",
                  },
                ]}
              >
                <Input placeholder="请输入缴费金额" />
              </Form.Item>
              <Form.Item name="explain" label="备注">
                <Input placeholder="请输入缴费备注" />
              </Form.Item>
              <Form.Item
                label="缴费时间"
                name="pay_date"
                rules={[{ required: true, message: "请填写缴费时间" }]}
              >
                <DatePicker />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

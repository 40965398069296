import supabase from "../..";

// 获取联系方式列表
export const reqContactsWithRegNo = async (id_com, current = 1) => {
    // console.log(id_com, '联系方式--id_com参数');
    // console.log(current, '联系方式--current参数');

    // 计算起始索引，current 是当前页数，默认从 1 开始
    const start = (current - 1) * 10;
    // 计算结束索引
    const end = start + 9;

    // 从 supabase 数据库中的 "company_contact" 表中选择数据
    const { data, count, error } = await supabase
        .from("company_contact")   // 指定表名
        .select("*", { count: "exact" })  // 选择所有列，并精确计数
        .eq("id_com", id_com)  // 筛选出 id_com 匹配的记录
        .range(start, end);  // 分页查询，指定查询范围

    // 如果有错误，输出错误信息到控制台
    if (error) {
        console.error("获取数据失败:", error.message);
    }

    // 构造返回结果对象，包含数据和总记录数
    const result = {
        data: data ?? [],  // 如果 data 为 null 或 undefined，则使用空数组
        count: count ?? 0,  // 如果 count 为 null 或 undefined，则使用 0
    }

    // 返回结果对象
    return result;
}

// 删除指定联系人
export const reqDeleteContact = async (id) => {
    // console.log(id, '删除联系方式--id参数');

    // 从 supabase 数据库中的 "company_contact" 表中删除 id 匹配的数据
    const { error } = await supabase
        .from("company_contact")   // 指定表名
        .delete()  // 删除数据
        .eq("id", id);  // 筛选出 id 匹配的数据

    // 如果有错误，输出错误信息到控制台
    if (error) {
        console.error("删除数据失败:", error.message);
        throw new Error(error.message);
    }

    return null;
}

// 修改指定联系人信息
export const reqUpdateContact = async (contact) => {
    // console.log(contact, '修改联系方式--contact参数');

    // 从 supabase 数据库中的 "company_contact" 表中更新 id 匹配的数据
    const { error } = await supabase
        .from("company_contact")   // 指定表名
        .update(contact)  // 更新数据
        .eq("id", contact.id);  // 筛选出 id 匹配的数据

    // 如果有错误，输出错误信息到控制台
    if (error) {
        console.error("更新数据失败:", error.message);
        throw new Error(error.message);
    }

    return null;
}

// 添加联系人信息
export const reqAddContact = async (contact) => {
    // console.log(contact, '添加联系方式--contact参数');

    // // 检查数据库中是否存在相同的手机号码
    // const { data: existingContacts, error: checkError } = await supabase
    //     .from("company_contact")
    //     .select("phone")
    //     .eq("phone", contact.phone);

    // // 如果查询出错，输出错误信息到控制台
    // if (checkError) {
    //     console.error("查询数据库失败:", checkError.message);
    //     throw new Error(checkError.message);
    // }

    // // 如果已存在相同的手机号码，提醒用户
    // if (existingContacts.length > 0) {
    //     throw new Error("该联系人已存在！");
    // }

    // 从 supabase 数据库中的 "company_contact" 表中添加数据
    const { error } = await supabase
        .from("company_contact")   // 指定表名
        .insert(contact);  // 添加数据

    // 如果有错误，输出错误信息到控制台
    if (error) {
        console.error("添加数据失败:", error.message);
        throw new Error(error.message);
    }

    return null;
}

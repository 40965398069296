import styles from "./Footer.module.css";
import { reqParkName } from "../service/home/login";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function Footer() {
  const location = useLocation();
  const parkName = location?.pathname.split("/").filter(Boolean)[0];
  const [park, setPark] = useState();
  // 技术支持
  const [support, setSupport] = useState();
  // 备案号
  const [record, setRecord] = useState();

  useEffect(() => {
    if (parkName) {
      reqParkName(parkName).then((res) => {
        // console.log(res, 'res底部数据');
        if (res.data.length > 0) {
          const parkData = res.data[0];
          setPark(parkData);
          setRecord(parkData.icp);

          // 获取当前年份
          const currentYear = new Date().getFullYear();

          // 替换 surport 中的 ${park_name} 以及拼接当前年份
          const updatedSupport = `&copy; ${currentYear} ` + parkData.surport
            .replace('${park_name}', parkData.name);

          setSupport(updatedSupport);
        }
      });
    }
  }, [parkName]);

  return (
    <div className={styles.root}>
      <p dangerouslySetInnerHTML={{__html: support}}></p>
      <p>
        <a href="https://beian.miit.gov.cn">{record}</a>
      </p>
    </div>
  );
}


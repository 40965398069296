import { Row, Col, Statistic, Button, Spin } from "antd";
import styles from "./kanban.module.css";
import {
  Chart7,
  Chart8,
  Chart9,
  Chart10,
  Chart11,
  Chart19,
} from "../../components/charts";
import PageHeader from "../../components/PageHeader";
import { useNavigate } from "react-router-dom";
import { reqCounts, reqKanBanFilterInfo } from "../../service/kanban";
import { UserContext } from "../../context";
import { useEffect, useState, useContext } from "react";
import { STATUS } from "../../constants";
import dayjs from "dayjs";
var utc = require("dayjs/plugin/utc");
const span = { xs: 24, sm: 24, md: 24, lg: 12, xl: 8, xxl: 8 };
const firstSpan = { span: 24};

export default function Kanban1() {
  dayjs.extend(utc);
  const navigate = useNavigate();
  const [kanbanFilterInfo, setKanBanFilterInfo] = useState([]);
  const [status, setStatus] = useState(STATUS.idle);
  const [total, setTotal] = useState(0);
  const { parkId, parkName } = useContext(UserContext);
  const [dateTime, setDateTime] = useState("");

  useEffect(() => {
    if (parkId) {
      const fetchData = async () => {
        const fetchCounts = reqCounts("select_count_tm", parkId);
        const fetchKanBan = reqKanBanFilterInfo(parkId, 2);

        Promise.all([fetchCounts, fetchKanBan]).then(([count, kanban]) => {
          setTotal(count.data);
          setKanBanFilterInfo(kanban.data);
          setDateTime(kanban.data[0]?.created_at);
          setStatus(STATUS.success);
        });
      };

      if (status === STATUS.idle) {
        fetchData();
      }
    }
  }, [parkId, status]);
  useEffect(() => {
    if (parkId) {
      setStatus(STATUS.idle);
      setKanBanFilterInfo([]);
    }
  }, [parkId]);
  return (
    <Spin spinning={status === STATUS.success ? false : true}>
      <PageHeader
        title="商标数据统计"
        description="针对入驻园区企业的商标、驰名商标、海关备案等数据的统计看板。"
      />
      <div className={styles.timestamp}>
        最后更新时间：{" "}
        {dateTime && dayjs.utc(dateTime).format("YYYY年MM月DD日 hh:mm:ss")}
      </div>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <div className={styles.section}>
            <div className={styles.statistic}>
              <div className={styles.item}>
                <Statistic title="商标注册数量" value={total} />
                <Button
                  type="primary"
                  onClick={() => navigate(`/${parkName}/query/trademarks`)}
                >
                  查看详情
                </Button>
              </div>
              <div className={styles.divider} />
              <div className={styles.item}>
                <Statistic title="驰名商标数量" value={0} />
                <Button
                  type="primary"
                  onClick={() =>
                    navigate(
                      `/${parkName}/query/trademarks?tradeType=驰名商标&pageSize=10&current=1&keywords=`
                    )
                  }
                >
                  查看详情
                </Button>
              </div>
              <div className={styles.divider} />
              <div className={styles.item}>
                <Statistic title="马德里商标数量" value={0} />
                <Button
                  type="primary"
                  onClick={() =>
                    navigate(
                      `/${parkName}/query/trademarks?tradeType=马德里商标&pageSize=10&current=1&keywords=`
                    )
                  }
                >
                  查看详情
                </Button>
              </div>
              <div className={styles.divider} />
              <div className={styles.item}>
                <Statistic title="海关备案数量" value={0} />
                <Button
                  type="primary"
                  onClick={() =>
                    navigate(
                      `/${parkName}/query/trademarks?tradeType=海关备案&pageSize=10&current=1&keywords=`
                    )
                  }
                >
                  查看详情
                </Button>
              </div>
            </div>
          </div>
        </Col>
        <Col {...firstSpan}>
          <div className={styles.section}>
            <div className={styles.chart}>
              <h2>商标注册分类（前20类）</h2>
              <Chart7 kanbanFilterInfo={kanbanFilterInfo} status={status} />
            </div>
          </div>
        </Col>
        <Col {...span}>
          <div className={styles.section}>
            <div className={styles.chart}>
              <h2>驰名商标注册分类（前5类）</h2>
              <Chart8 />
            </div>
          </div>
        </Col>
        <Col {...span}>
          <div className={styles.section}>
            <div className={styles.chart}>
              <h2>马德里商标注册数量（近5年）</h2>
              <Chart19 />
            </div>
          </div>
        </Col>
        <Col {...span}>
          <div className={styles.section}>
            <div className={styles.chart}>
              <h2>海关备案数量（近5年）</h2>
              <Chart9 />
            </div>
          </div>
        </Col>
        <Col {...span}>
          <div className={styles.section}>
            <div className={styles.chart}>
              <h2>商标申请/注册数量（近5年）</h2>
              <Chart10 kanbanFilterInfo={kanbanFilterInfo} status={status} />
            </div>
          </div>
        </Col>
        <Col {...span}>
          <div className={styles.section}>
            <div className={styles.chart}>
              <h2>驰名商标数量（近5年）</h2>
              <Chart11 />
            </div>
          </div>
        </Col>
      </Row>
    </Spin>
  );
}

import styles from "../query.module.css";
import { Row, Col, Typography, List, Spin } from "antd";
import { NumberOutlined } from "@ant-design/icons";
import { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import qs from "query-string";

import PageHeader from "../../../components/PageHeader";
import { STATUS } from "../../../constants";
import ShDescription from "../../../components/ShDescription";
import baseDesConfig from "./detailsConfig/baseDesConfig";

import { reqTradeMarkInfo } from "../../../service/query/trademarks";
import { UserContext } from "../../../context";

export default function Trademark() {
  const location = useLocation();

  const [status, setStatus] = useState(STATUS.idle);
  const [tradeMarkInfo, setTradeMarkInfo] = useState([]);

  const { parkName } = useContext(UserContext);
  useEffect(() => {
    const parsedQuery = qs.parse(location.search.slice(1), {
      parseNumbers: true,
    });
    reqTradeMarkInfo(parsedQuery.reg_number).then((res) => {
      setTradeMarkInfo(res.data);
      setStatus(STATUS.success);
    });
  }, [location]);
  return (
    <Spin spinning={status === STATUS.success ? false : true}>
      <PageHeader
        title={tradeMarkInfo[0]?.name}
        description="展示商标的详细信息。"
        breadcrumb={[
          {
            title: (
              <Link to={`/${parkName}/query/trademarks`}>商标数据检索</Link>
            ),
          },
          { title: `${tradeMarkInfo[0]?.name ?? ""}` },
        ]}
      />
      <Row gutter={32}>
        <Col span={18}>
          <ShDescription
            status={status}
            config={baseDesConfig}
            dataSource={tradeMarkInfo}
          />

          <Typography.Title className={styles.title} id="生命周期" level={5}>
            <NumberOutlined />
            生命周期
          </Typography.Title>

          {tradeMarkInfo[0] && (
            <div className={styles.section}>
              <List
                renderItem={(item) =>
                  tradeMarkInfo[0] ? (
                    <List.Item>
                      <List.Item.Meta description={item.date} />
                      <div>{item.step}</div>
                    </List.Item>
                  ) : (
                    <></>
                  )
                }
                dataSource={JSON.parse(tradeMarkInfo[0].steps)}
                rowKey="id"
              />
            </div>
          )}
        </Col>
      </Row>
    </Spin>
  );
}

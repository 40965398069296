import React from "react";
import { Modal } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { updateNoticeStatus } from "../../../../service/member/notice";
import { STATUS } from "../../../../constants";

const { confirm } = Modal;

export default function noticeFn() {

  const switchState = (
    data,
    onStatusChange
  ) => {
    confirm({
      title: data.status === 0 ? "发布确认" : "撤回确认",
      icon: <ExclamationCircleFilled />,
      content: `您确定要将 ${data.title} 通知${data.status === 0 ? "发布" : "存为草稿"}吗？`,
      onOk() {
        updateNoticeStatus({
          id: data.id,
          // 根据当前状态切换
          status: data.status === 0 ? 1 : 0,
        }).then((res) => {
          onStatusChange(STATUS.idle);
        }).catch((err) => {
          console.log(err);
        })
      },
    });
  };

  // 解析 HTML 字符串并返回第一个包含文本的元素节点的文本内容
  const extractFirstTextNode = (html) => {
    // 创建一个 DOMParser 实例
    const parser = new DOMParser();
    // 解析 HTML 字符串为 DOM 文档
    const doc = parser.parseFromString(html, 'text/html');

    // 获取所有的元素节点
    const allElements = doc.body.childNodes;

    // 遍历所有子节点
    for (const node of allElements) {
      // 如果节点是一个文本节点且内容不为空
      if (node.nodeType === Node.ELEMENT_NODE) {
        const textContent = node.textContent.trim(); // 获取并去除文本内容的前后空白字符
        if (textContent) {
          return textContent; // 返回第一个包含文本的节点的内容
        }
      }
    }

    return ''; // 如果没有找到包含文本的节点，返回空字符串
  };

  return {
    switchState,
    extractFirstTextNode,
  }

}
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Radio,
  notification,
} from "antd";
import { useContext, useState, useEffect, useRef } from "react";
import styles from "../index.module.css";
import { UserContext } from "../../../../context";
import { updateNotice } from "../../../../service/member/notice";
import { STATUS } from "../../../../constants";

import ReactQuill from 'react-quill'; // 导入react-quill模块
import 'react-quill/dist/quill.snow.css'; // 导入react-quill样式
import ImageUpload from '../hook/ImageUpload'; // 导入自定义图片上传模块

export default function EditMember({
  record,
  onStatusChange, // 通知状态改变
}) {
  const [open, setOpen] = useState(false);
  const { parkId } = useContext(UserContext);
  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();

  const [value, setValue] = useState(''); // 创建一个 state，用于存储编辑器中的文本内容
  const quillContainerRef = useRef(null); // 使用 useRef 创建对 ReactQuill 组件的引用，以便在组件加载时获取编辑器实例

  const onFinish = async () => {
    try {
      const values = await form.validateFields();
      // 请求接口，只需传递指定的数据
      const noticeData = {
        ...values,
        info: value,
        id: record.id,
      }
      await updateNotice(noticeData).then(res => {
        // console.log(res, '通知修改成功');
        api.success({
          message: "通知修改成功",
          placement: "top",
          style: {
            marginTop: "3rem",
          },
        });
        onStatusChange(STATUS.idle); // 重置状态刷新表格数据
        setOpen(false); // 关闭模态框
      }).catch(err => {
        console.log(err, '通知修改失败');
      })
    } catch (error) { }
  };

  const handleCancel = () => {
    form.resetFields(); // 清空表单内容
    setValue(''); // 清空 Quill 编辑器内容
    setOpen(false); // 关闭模态框
  };

  // 定义 Quill 编辑器的模块配置，包括工具栏的功能
  const modules = {
    toolbar: [
      [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }], // 标题和字体选项
      [{ 'list': 'ordered' }, { 'list': 'bullet' }], // 有序列表和无序列表
      ['bold', 'italic', 'underline'], // 加粗、斜体、下划线按钮
      ['link', 'image'], // 添加链接和图片按钮
      [{ 'color': [] }, { 'background': [] }], // 字体颜色和背景颜色选择器
      [{ 'align': [] }], // 对齐方式
      ['clean'] // 清除格式按钮
    ],
  };
  
  useEffect(() => {
    // 当 modal 打开 (open) 且 quillContainerRef 已经初始化时触发
    if (open && quillContainerRef.current) {
      // 获取 Quill 编辑器实例
      const quill = quillContainerRef.current.getEditor();

      // 使用 dangerouslyPasteHTML 方法直接将 HTML 内容插入到 Quill 编辑器中
      // 这会强制 Quill 解析并渲染 HTML 内容中的标签和样式
      quill.clipboard.dangerouslyPasteHTML(record.info);

      // 绑定自定义的图片上传功能，使得在 Quill 编辑器中上传图片时，能够使用自定义的逻辑
      ImageUpload(quill);
    }
  }, [open, record.info]); // 每次模态框打开或者 record.info 改变时触发

  return (
    <>
      {contextHolder}
      <div className={styles.buttons}>
        <a onClick={() => setOpen(true)}>编辑</a>
      </div>
      <Modal
        title="修改通知信息"
        forceRender
        open={open}
        onOk={() => onFinish()}
        onCancel={handleCancel}
        width={1200}
        confirmLoading={!open}
        destroyOnClose={true}
      >
        <Row gutter={32}>
          <Col span={20}>
            <Form
              wrapperCol={{ span: 19 }}
              initialValues={record}
              labelCol={{ span: 5 }}
              form={form}
              className={styles.modalTitle}
            >
              <Form.Item
                name="title"
                label="标题"
                rules={[{ required: true, message: "请输入通知标题" }]}
              >
                <Input placeholder="请输入标题" />
              </Form.Item>
              <Form.Item
                label="状态"
                name="status"
                rules={[
                  {
                    required: true,
                    message: "请选择通知状态!",
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value={1}>发布</Radio>
                  <Radio value={0}>草稿</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                label="通知时间"
                name="broadcast_time"
                rules={[{ required: true, message: "请填写通知时间" }]}
              >
                <DatePicker />
              </Form.Item>
              <Form.Item
                label="通知内容"
                name="info"
              >
                <ReactQuill
                  theme="snow" // 设置 Quill 编辑器的主题为 'snow'
                  value={value} // 将编辑器内容与组件 state 绑定
                  onChange={setValue} // 当编辑器内容变化时更新 state
                  modules={modules} // 为编辑器传入自定义的工具栏模块
                  ref={quillContainerRef} // 使用 ref 引用 ReactQuill 组件
                  style={{ height: '22vw' }} // 设置编辑器的高度
                />
                {/* <p>你输入的内容是: {value}</p> 显示当前编辑器中的文本内容 */}
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

import { useEffect, useState, useContext } from "react";
import qs from "query-string";
import { useLocation, useNavigate } from "react-router-dom";

import PageHeader from "../../../components/PageHeader";
import ShPageModel from "../../../components/ShPageModel";

import { STATUS } from "../../../constants";
import { UserContext } from "../../../context";
import { reqSoftWaresList } from "../../../service/query/software";
import { reqAxiossoftsLists } from "../../../api/lists";
export default function Patents() {
  const [status, setStatus] = useState(STATUS.idle);
  const navigate = useNavigate();
  const [softwaresList, setSoftwaresList] = useState([]);
  const [aprSort, setAprSort] = useState("");
  const [total, setTotal] = useState(1);
  const location = useLocation();
  const { parkId } = useContext(UserContext);
  const handleStatus = (status) => {
    setStatus(status);
  };
  const [query, setQuery] = useState({});

  // 声明一个状态变量用于保存选中的行
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  // 声明一个状态变量用于保存 id_cs 的数组
  const [idCss, setIdCss] = useState([]);

  // 当复选框状态改变时触发
  const onSelectChange = (newSelectedRowKeys, selectedRows, dataSource, rowKey) => {
    // console.log(`selectedRowKeys: ${newSelectedRowKeys}`, 'selectedRows: ', selectedRows);

    // 获取当前页面的选中行的 id_cs
    const ids = selectedRows.map(row => row.id_cs);

    // 从数据源中提取出每一项的行键（默认为 'id'），生成当前页面所有行的键数组
    const currentPageKeys = dataSource.map(item => item[rowKey ?? 'id']);

    // 更新选中的行键数组：
    // 1. 保留当前选中行键中那些在当前页面中的行键，并且这些行键仍然被新选中的行键包含。
    // 2. 保留当前选中行键中那些不在当前页面中的行键（这些行键不会被新选中的行键影响）。
    const updatedSelectedRowKeys = selectedRowKeys.filter(key =>
      currentPageKeys.includes(key) ? newSelectedRowKeys.includes(key) : true
    );

    // 更新选中的 id_cs 数组：
    // 1. 保留当前选中 id_cs 中那些在当前页面中的 id_cs，并且这些 id_cs 仍然被新选中的行数据包含。
    // 2. 保留当前选中 id_cs 中那些不在当前页面中的 id_cs（这些 id_cs 不会被新选中的行数据影响）。
    const updatedIdCss = idCss.filter(id =>
      currentPageKeys.includes(dataSource.find(item => item.id_cs === id)?.[rowKey ?? 'id']) ? ids.includes(id) : true
    );

    // 遍历新选中的行键数组，将不在 updatedSelectedRowKeys 中的行键添加进去
    newSelectedRowKeys.forEach(key => {
      if (!updatedSelectedRowKeys.includes(key)) {
        updatedSelectedRowKeys.push(key);
      }
    });

    // 遍历新选中的 id_cs 数组，将不在 updatedIdCss 中的 id_cs 添加进去
    ids.forEach(id => {
      if (!updatedIdCss.includes(id)) {
        updatedIdCss.push(id);
      }
    });

    // 更新状态
    setSelectedRowKeys(updatedSelectedRowKeys);
    setIdCss(updatedIdCss);
  };

  // 表格行选择配置
  const rowSelection = {
    selectedRowKeys,  // 当前选中的行键数组
    // 当行选择变化时，调用 onSelectChange 函数，并传递新选中的行键、新选中的行数据、当前页面的数据源和行键字段（默认为 'id'）
    onChange: (newSelectedRowKeys, selectedRows) => onSelectChange(newSelectedRowKeys, selectedRows, softwaresList, 'id'),
  };

  // 清空复选框选中状态
  const resetSelection = () => {
    // 清空复选框选中状态
    setSelectedRowKeys([]);
    // 清空 id_cs 数组
    setIdCss([]);
  };

  const softwareTableConfig = {
    nameList: [
      {
        title: "类型",
        render: () => (
          <img
            style={{
              display: "block",
              width: "3rem",
              height: "3rem",
            }}
            src="/icon/ico-copyright-2.png"
            alt="专利类型"
          />
        ),
      },
      {
        title: "软件著作权名称",
        dataIndex: "name",
      },
      {
        title: "登记号",
        dataIndex: "number",
      },
      // {
      //   title: "作品分类",
      //   dataIndex: "type",
      // },
      {
        title: "创作时间",
        dataIndex: "success_date",
      },
      {
        title: "发布时间",
        dataIndex: "first_date",
      },
      {
        title: "登记人",
        dataIndex: "company",
      },
      {
        title: "登记时间",
        dataIndex: "approval_date",
        sorter: true,
        sortOrder: aprSort,
      },
    ],
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const newQuery = {
      // query 在 useEffect中已经被替换为新的值
      ...query,
      sortFiled: sorter.field,
      sortOrder: sorter.order,
    };
    let newUrl = `?${Object.entries(newQuery)
      .map(([key, value]) => `${key}=${value ?? ""}`)
      .join("&")}`;
    navigate(newUrl);
  };
  useEffect(() => {
    const parsedQuery = qs.parse(location.search.slice(1), {
      parseNumbers: true,
    });
    setAprSort(
      parsedQuery.sortFiled === "approval_date" ? parsedQuery.sortOrder : ""
    );

    setQuery(parsedQuery);

    if (parkId) {
      const doPost = () => {
        reqSoftWaresList(parsedQuery, parkId).then((res) => {
          setSoftwaresList(res.data);
          setTotal(res.count);
          setStatus(STATUS.success);
        });
      };
      if (status === STATUS.idle) {
        doPost();
      }
    }
  }, [location, total, parkId, status]);
  useEffect(() => {
    setStatus(STATUS.idle);
  }, [parkId, location]);
  return (
    <>
      <PageHeader
        title="软件著作权数据检索"
        description="检索入驻园区企业的相关著作权信息。"
      />
      <ShPageModel
        placeholder="软件著作权名称/登记号"
        changeStatus={handleStatus}
        status={status}
        config={softwareTableConfig}
        dataSource={softwaresList}
        total={total}
        reqExport={reqAxiossoftsLists}
        handleTableChange={handleTableChange}
        rowSelection={rowSelection} // 复选框: 传递 rowSelection 属性
        idCss={idCss} // 传递 idCss
        resetSelection={resetSelection} // 清空复选框: 传递 resetSelection 属性
      />
    </>
  );
}

import supabase from "..";

export const reqAlreadyLogin = async () => {
  // 获取当前用户的身份验证状态
  try {
    const user = await supabase.auth.getUser();
    return user;
  } catch (error) {
    console.log("error :>> ", error);
  }
};
// 用户审计
export const InsAudit = async (userId, operation) => {
  let query = supabase.from("user_logs").insert([
    {
      user_id: userId,
      operation: operation,
    },
  ]);
  const { data, error } = await query;
  const result = {
    data: data ?? [],
    error: error,
  };
  return result;
};
// 获取园区列表
export const reqParks = async (orgUrl) => {
  let query = supabase
    .from("park")
    .select(
      `name,short_name,sub_title,address,img_url,bg_img_url,logo_img_url,park_role,org_url,title_left_url,title_right_url,bg_color,park_fn(desc,icon_url,sort_id,title),park_function_introduce,sub_title2`
    )
    .eq("is_disable", 0)
    .order("id", { ascending: false })
    .order("sort_id", { foreignTable: "park_fn", ascending: true });
  if (orgUrl && orgUrl !== "localhost" && orgUrl !== "park.biaoxq.com") {
    query.eq("org_url", orgUrl);
  }
  const { data } = await query;
  const result = {
    data: data ?? [],
    title_img: data
      ? data.find((item) => item.title_left_url && item.org_url === orgUrl)
      : "",
  };
  return result;
};
// 管理圆管理的园区
export const reqParkLists = async (email) => {
  const { data, error } = await supabase
    .from("user_park")
    .select("short_name")
    .eq("create_by", email);
  if (error) {
    console.log("error :>> ", error);
  }
  const result = {
    data: data ? data.map((item) => item.short_name) : [],
  };
  return result;
};
// 获取园区列表内园区信息

export const reqParkListInfo = async (lists) => {
  const { data, error } = await supabase
    .from("park")
    .select("name,short_name,address,img_url,bg_img_url,logo_img_url,park_role")
    .in("short_name", lists)
    .order("id", { ascending: false });
  if (error) {
    console.log("error :>> ", error);
  }
  const result = {
    data: data ?? [],
  };
  return result;
};

export const reqLogin = async (values) => {
  let data = await supabase.auth.signInWithPassword({
    email: values.username,
    password: values.password,
  });
  if (data.error !== null) {
    throw new Error("账户密码错误");
  }
  return data;
};

export const reqParkId = async (email) => {
  const result = await supabase.from("park").select("*").eq("create_by", email);
  return result;
};

export const reqVerfiyPark = async (email, parkName) => {
  const { data, error } = await supabase
    .from("user_park")
    .select("*")
    .eq("create_by", email)
    .eq("short_name", parkName);
  if (error) {
    console.log("error :>> ", error);
  }
  const result = {
    data: data ?? [],
  };
  return result;
};
export const reqResetPasswordForEmail = async (email, parkName, host) => {
  const customEmail = {
    subject: "Password Reset",
    html: '<p>Click <a href="%RESET_URL%">here</a > to reset your password.</p >',
  };

  const result = await supabase.auth.resetPasswordForEmail(email, {
    redirectTo: `http://${host}/${parkName}/password-renew`,
    customEmail,
  });
  return result;
};

export const reqUpdateUser = async (email, password) => {
  const result = await supabase.auth.updateUser({
    email: email,
    password: password,
  });
  return result;
};
export const reqParkName = async (parkName) => {
  const { data, error } = await supabase
    .from("park")
    .select("*")
    .eq("short_name", parkName);
  if (error) {
    console.log("error :>> ", error);
  }
  const result = {
    data: data ?? [],
  };
  return result;
};

export const InsBrands = async (arrs) => {
  const { data, error } = await supabase
    .from("park_brand")
    .insert(arrs)
    .select();
  if (error) {
    console.log("error :>> ", error);
  }
  const result = {
    data: data ?? [],
  };
  return result;
};

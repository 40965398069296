import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  notification,
  Radio,
} from "antd";
import { useContext, useEffect, useState } from "react";
import { reqUpdateContact } from "../../../../service/details/contacts/index";
import { UserContext } from "../../../../context";
import { STATUS } from "../../../../constants";

// 编辑联系人信息组件
export default function EditMember({ record, onStatusChange }) {
  const [open, setOpen] = useState(false); // 定义打开状态的状态变量
  const [api, contextHolder] = notification.useNotification(); // 获取通知API和上下文持有者
  const [form] = Form.useForm(); // 创建表单实例
  // 表单提交完成时的处理函数
  const onFinish = async () => {
    try {
      const values = await form.validateFields(); // 校验并获取表单值
      // console.log(values, 'values--值'); // 输出表单值
      await reqUpdateContact({ ...values, id: record.id }).then(() => { // 发送更新请求
        api.success({
          message: "联系人信息修改成功", // 成功提示信息
          placement: "top",
          style: {
            marginTop: "3rem",
          },
        });
        onStatusChange(STATUS.idle); // 修改状态为空闲
        setOpen(false); // 关闭模态框
      });
    } catch (error) {
      // 处理错误
    }
  };

  return (
    <>
      <div>
        <Button type="link" onClick={() => setOpen(true)}>
          编辑
        </Button>
      </div>
      <Modal
        title={`编辑${record.name}联系人信息`} // 设置模态框标题
        forceRender
        open={open} // 控制模态框的显示
        onOk={() => onFinish()} // 点击确定时调用onFinish
        onCancel={() => setOpen(false)} // 点击取消时关闭模态框
        width={650} // 设置模态框宽度
        confirmLoading={!open} // 设置确定按钮的加载状态
        destroyOnClose={true} // 关闭时销毁模态框中的子元素
      >
        {contextHolder}
        <Row gutter={32}>
          <Col span={20}>
            <Form
              form={form} // 绑定表单实例
              labelCol={{ span: 5 }} // 设置标签的布局
              wrapperCol={{ span: 19 }} // 设置表单项的布局
              initialValues={record} // 设置初始值
            >
              <Form.Item
                label="姓名"
                name="name"
                rules={[{ required: true, message: '请输入姓名' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="性别"
                name="sex"
                // rules={[{ required: true, message: '请选择性别' }]}
              >
                <Radio.Group>
                  <Radio value={1}>先生</Radio>
                  <Radio value={0}>女士</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                label="职务 / 头衔"
                name="title"
                // rules={[{ required: true, message: '请输入职务 / 头衔' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="手机"
                name="phone"
                rules={[
                  { required: true, message: '请输入手机号码' },
                  {
                    pattern: /^1[3|415178][0-9]{9}$/,
                    message: "手机号码格式不正确",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="备注"
                name="remark"
                // rules={[{ required: true, message: '请输入备注' }]}
              >
                <Input />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

import { Tabs } from "antd";
import styles from "./index.module.css";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../../../context";
import VipList from "./VipList";

export default function Vip() {
  const { parkName } = useContext(UserContext);
  const [activeKey, setActiveKey] = useState(1);
  const { pathname } = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const onChange = (key) => {
    navigate(`/${parkName}/vip/${key}`);
  };

  const items = [
    {
      key: "1",
      label: "会员列表",
      children: <VipList />,
    },
    {
      key: "2",
      label: "申请入会用户",
      children: <VipList />,
    },
    {
      key: "3",
      label: "不通过用户",
      children: <VipList />,
    },
  ];
  useEffect(() => {
    setActiveKey(id);
  }, [pathname, id]);
  return (
    <div className={styles.root}>
      <Tabs
        destroyInactiveTabPane={true}
        activeKey={activeKey}
        items={items}
        onChange={onChange}
      />
    </div>
  );
}

import React, { useState } from "react";
import { Button, Col, Form, Input, Modal, Row, Radio, notification } from "antd";
import { reqAddContact } from "../../../../service/details/contacts/index";

// 添加联系人信息组件
export default function AddContact({ id_com, onContactAdded }) {
  const [open, setOpen] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();

  // 表单提交完成时的处理函数
  const onFinish = async () => {
    try {
      const values = await form.validateFields(); // 校验并获取表单值
      // console.log(values, 'values--值'); // 输出表单值

      // 添加 id_com 到表单数据
      const contactData = { ...values, id_com };
      await reqAddContact(contactData).then(() => { // 发送添加请求
        api.success({
          message: "联系人添加成功", // 成功提示信息
          placement: "top",
          style: {
            marginTop: "3rem",
          },
        });
        onContactAdded(); // 通知父组件联系人已添加
        setOpen(false); // 关闭模态框
        form.resetFields(); // 重置表单
      }).catch((err) => {
        api.error({
          message: err.message, // 错误提示信息
          placement: "top",
          style: {
            marginTop: "3rem",
          },
        });
      });
    } catch (error) {
      // 处理表单校验错误
      console.error("表单校验失败:", error);
    }
  };

  // 关闭模态框时重置表单
  const handleCancel = () => {
    setOpen(false); // 关闭模态框
    form.resetFields(); // 重置表单
  };

  return (
    <>
      <div>
        <Button type="primary" style={{ marginTop: "1.2rem" }} onClick={() => setOpen(true)}>
          添加联系方式
        </Button>
      </div>
      <Modal
        title="添加联系人信息" // 设置模态框标题
        forceRender
        open={open} // 控制模态框的显示
        onOk={() => onFinish()} // 点击确定时调用onFinish
        onCancel={handleCancel} // 点击取消时关闭模态框并重置表单
        afterClose={form.resetFields} // 模态框关闭后重置表单
        width={720} // 设置模态框宽度
        confirmLoading={!open} // 设置确定按钮的加载状态
        destroyOnClose={true} // 关闭时销毁模态框中的子元素
      >
        {contextHolder}
        <Row gutter={32}>
          <Col span={20}>
            <Form
              form={form} // 绑定表单实例
              labelCol={{ span: 5 }} // 设置标签的布局
              wrapperCol={{ span: 19 }} // 设置表单项的布局
            >
              <Form.Item
                label="姓名"
                name="name"
                rules={[{ required: true, message: '请输入姓名' }]}
              >
                <Input placeholder="请输入姓名" />
              </Form.Item>
              <Form.Item
                label="性别"
                name="sex"
                // rules={[{ required: true, message: '请选择性别' }]}
              >
                <Radio.Group>
                  <Radio value={1}>先生</Radio>
                  <Radio value={0}>女士</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                label="职务 / 头衔"
                name="title"
                // rules={[{ required: true, message: '请输入职务 / 头衔' }]}
              >
                <Input placeholder="请输入职务 / 头衔" />
              </Form.Item>
              <Form.Item
                label="手机号码"
                name="phone"
                rules={[
                  { required: true, message: '请输入手机号码' },
                  {
                    pattern: /^1[3|4|5|7|8][0-9]{9}$/,
                    message: "手机号码格式不正确",
                  },
                ]}
              >
                <Input placeholder="请输入手机号码" />
              </Form.Item>
              <Form.Item
                label="备注"
                name="remark"
                // rules={[{ required: true, message: '请输入备注' }]}
              >
                <Input placeholder="请输入备注" />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

import Quill from 'quill'; // 导入 Quill 富文本编辑器的核心模块

import { uploadNoticeFile } from "../../../../service/member/notice/index"; // 导入自定义的文件上传服务，用于上传图片文件到服务器

// 获取 Quill 编辑器内置的 Image 格式对象
const Image = Quill.import('formats/image');

// 扩展 Quill 编辑器的 Image 格式，定义自定义的图片格式类
class CustomImage extends Image {
  // 重写 create 方法，用于创建自定义图片节点
  static create(value) {
    let node = super.create(value); // 调用父类的 create 方法创建基础节点
    node.setAttribute('src', value); // 设置图片的 src 属性，指定图片的 URL
    node.setAttribute('alt', 'Uploaded Image'); // 设置图片的 alt 属性为 "Uploaded Image"
    return node; // 返回自定义的图片节点
  }

  // 重写 value 方法，用于返回节点的 src 属性值
  static value(node) {
    return node.getAttribute('src'); // 返回图片节点的 src 属性
  }
}

// 注册自定义的 Image 格式到 Quill 编辑器，使得自定义的格式生效
Quill.register(CustomImage, true);

// 定义图片上传的模块函数，接收 Quill 编辑器实例作为参数
const ImageUpload = (quill) => {
  const toolbar = quill.getModule('toolbar'); // 获取 Quill 编辑器的工具栏模块
  toolbar.addHandler('image', () => { // 为工具栏中的 "image" 按钮添加点击事件处理器
    const range = quill.getSelection(); // 获取当前编辑器中的光标位置
    const input = document.createElement('input'); // 创建一个文件选择框的 HTML 元素
    input.setAttribute('type', 'file'); // 设置 input 的 type 为 'file'，用于选择文件
    input.setAttribute('accept', 'image/*'); // 限制文件选择框只接受图片类型的文件

    // 为文件选择框添加 change 事件监听器，监控用户选择文件的动作
    input.addEventListener('change', async () => {
      const file = input.files[0]; // 获取用户选择的文件（第一个文件）
      if (file) { // 如果文件存在
        try {
          // 调用上传服务，将文件上传到服务器，并返回文件存储路径
          const { path } = await uploadNoticeFile(file);

          // 构建图片的 URL，指向服务器存储的图片文件
          const imageUrl = `https://zevgwqoenxkhkvornmdw.supabase.co/storage/v1/object/public/notice_files/${path}`;
          
          // console.log('Uploaded image URL:', imageUrl); // 调试信息：打印上传后的图片 URL
          
          // 将上传后的图片插入到 Quill 编辑器中，位置为光标所在位置
          quill.insertEmbed(range.index, 'image', imageUrl);
        } catch (error) {
          // 处理图片上传错误
          console.error('图片上传失败:', error.message); // 打印错误信息
        }
      }
    });
    
    input.click(); // 模拟用户点击文件选择框，打开文件选择对话框
  });
};

// 导出 ImageUpload 模块
export default ImageUpload;

import { useContext, useEffect, useState } from "react";
import ShPageModel from "../../../components/ShPageModel";
import { STATUS } from "../../../constants";
import { activity_status, activity_type } from "../../../intlClass";
import { useLocation } from "react-router-dom";
import { UserContext } from "../../../context";
import { reqActivityLists } from "../../../service/member/activity";
import dayjs from "dayjs";
import qs from "query-string";
import { ActivityRender1 } from "./hook/ActivityRender";
export default function Statistics() {
  const [status, setStatus] = useState(STATUS.idle);
  const { parkId } = useContext(UserContext);
  const [total, setTotal] = useState(0);
  const { search } = useLocation();
  const [activityList, setActivityList] = useState([]);
  const ActivityTableConfig = {
    nameList: [
      {
        title: "时间",
        dataIndex: "meeting_date",
        render(data) {
          return data ? dayjs(data).format("YYYY-MM-DD") : "-";
        },
      },
      {
        title: "活动主题",
        dataIndex: "meeting_title",
      },
      {
        title: "类型",
        dataIndex: "issue_type",
        render(data) {
          return activity_type.find((item) => item.id === data).title;
        },
      },
      {
        title: "邀请人数",
        dataIndex: "member_meeting_members",
        render(data) {
          return data && data.length;
        },
      },
      {
        title: "报名人数",
        dataIndex: "member_meeting_members",
        render(data) {
          return data && data.filter((item) => item.apply_status === 1).length;
        },
      },
      {
        title: "费用（元）",
        dataIndex: "meeting_fee",
      },
      {
        title: "状态",
        dataIndex: "status",
        render(data) {
          return activity_status.find((item) => item.id === data).title;
        },
      },
      {
        title: "操作",
        render(data) {
          return (
            <ActivityRender1
              data={data}
              onStatusChange={handleChildStatusChange}
            />
          );
        },
      },
    ],
  };
  function handleChildStatusChange(newStatus) {
    setStatus(newStatus);
  }
  useEffect(() => {
    let parsedQuery = qs.parse(search.slice(1), {
      parseNumbers: true,
    });
    const doPost = () => {
      reqActivityLists(parsedQuery, parkId).then((res) => {
        setActivityList(res.data);
        setTotal(res.count);
        setStatus(STATUS.success);
      });
    };
    if (parkId && status === STATUS.idle) {
      doPost();
    }
  }, [parkId, search, status]);
  useEffect(() => {
    if (status === STATUS.success) {
      setStatus(STATUS.idle);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);
  return (
    <div>
      <ShPageModel
        config={ActivityTableConfig}
        status={status}
        dataSource={activityList}
        rowKey={"id"}
        placeholder={"活动名称"}
        total={total}
      />
    </div>
  );
}

import { Link } from "react-router-dom";
import PageHeader from "../../../components/PageHeader";
import { useContext } from "react";
import { UserContext } from "../../../context";
import { Col, Row } from "antd";
import ShDescription from "../../../components/ShDescription";

export default function VipDetails() {
  const { parkName } = useContext(UserContext);

  const useInfo = [
    {
      id: 1,
      member_name: "张三",
      age: 20,
      gender: "男",
      ethnicity: "汉族",
      birthplace: "湖南",
      phone: "13888888888",
      recommender: "李四",
      fee_status: "未缴纳",
      sub_time: "2020-01-01",
      join_date: "2020-01-01",
      data_type: "1",
      invitation_count: "1",
    },
  ];

  const baseConfig = {
    title: "基本信息",
    column: 2,
    nameList: [
      {
        label: "ID",
        span: 1,
        dataIndex: "id",
      },
      {
        label: "会员名称",
        span: 1,
        dataIndex: "member_name",
      },
      {
        label: "年龄",
        span: 1,

        dataIndex: "age",
      },
      {
        label: "性别",
        span: 1,

        dataIndex: "gender",
      },
      {
        label: "民族",
        span: 1,

        align: "center",
        dataIndex: "ethnicity",
      },
      {
        label: "籍贯",
        span: 1,

        align: "center",
        dataIndex: "birthplace",
      },
      {
        label: "手机号码",
        span: 1,

        align: "center",
        dataIndex: "phone",
      },
      {
        label: "会费状态",
        span: 1,

        align: "center",
        dataIndex: "fee_status",
      },
      {
        label: "推荐人",
        span: 1,

        dataIndex: "recommender",
      },
      {
        label: "入会时间",
        span: 1,

        dataIndex: "join_date",
      },
      {
        label: "邀请人数",
        dataIndex: "invitation_count",
      },
    ],
  };
  return (
    <>
      <PageHeader
        title={"基本信息"}
        description="展示用户的各种信息，包括：基本信息、会员信息、会员活动等。"
        breadcrumb={[
          {
            title: <Link to={`/${parkName}/vip/1`}>会员管理</Link>,
          },
          { title: `` },
        ]}
      />
      <Row gutter={32}>
        <Col span={18}>
          <ShDescription config={baseConfig} dataSource={useInfo} />
          {/* <ShDetailPageModel
            rowKey={"id"}
            dataSource={eventData}
            config={eventConfig}
            status={eventStatus}
          /> */}
        </Col>
      </Row>
    </>
  );
}

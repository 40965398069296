import {
  Card,
  Col,
  Row,
  Typography,
  Spin,
  Statistic,
  Divider,
  Image,
} from "antd";
import styles from "./forehead.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { reqParks } from "../service/home/login";
import { useEffect, useState } from "react";
import { STATUS } from "../constants";
import { park_bar } from "../service/query/forhead";
export default function Forehead() {
  const [status, setStatus] = useState(STATUS.idle);
  const location = useLocation();
  const navigate = useNavigate();
  const [parkBarCount, setParkBarCount] = useState([]);
  const [parks, setParks] = useState([]);
  const [titleImg, setTitleImg] = useState();
  const currentDate = new Date();
  const formattedDate = `${currentDate.getFullYear()}年${(
    currentDate.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}月${currentDate.getDate().toString().padStart(2, "0")}日`;
  const goto = (item) => {
    navigate(`/${item.short_name}`);
  };
  const statisticsConfig = [
    {
      title: "企业数量",
      board_name: "company_num",
    },

    {
      title: "科技型企业数量",
      board_name: "tech_num",
    },
    {
      title: "预警企业数量",
      board_name: "alarm_num",
    },
    {
      title: "商标数量",
      board_name: "tm_num",
    },
    {
      title: "专利数量",
      board_name: "pt_num",
    },
    {
      title: "著作权数量",
      board_name: "cp_num",
    },
    {
      title: "软件著作权数量",
      board_name: "cs_num",
    },
  ];
  useEffect(() => {
    reqParks(window.location.hostname).then((res) => {
      park_bar().then((res) => {
        setParkBarCount(res.data);
        setStatus(STATUS.success);
      });
      setParks(res.data);
      setTitleImg(res.title_img);
    });
  }, [location]);
  if (status === STATUS.success) {
    return (
      <div className={styles.root}>
        {window.location.hostname === "localhost" ||
          window.location.hostname === "park.biaoxq.com" ? (
          <>
            <Typography>
              <Typography.Title level={5}>
                数据统计（截止至{formattedDate}）：
              </Typography.Title>
            </Typography>
            <div className={styles.statistics}>
              <div className={styles.items}>
                <Statistic
                  title="园区/协会数量"
                  value={
                    location.pathname === "/home"
                      ? parkBarCount.find((item) => item.name === "park_num")
                        ?.context
                      : parkBarCount.find((item) => item.name === "park_num")
                        ?.context * 3
                  }
                />
              </div>
              {statisticsConfig.map((item, index) => (
                <div key={index} style={{ display: "flex", padding: "1rem" }}>
                  <Divider style={{ height: 64 }} type="vertical" />
                  <div className={styles.items}>
                    <Statistic
                      title={item.title}
                      valueStyle={{
                        color: item.title === "预警企业数量" ? "red" : "",
                      }}
                      value={
                        location.pathname === "/home"
                          ? parkBarCount.find(
                            (parkBar) => parkBar.name === item.board_name
                          )?.context
                          : parkBarCount.find(
                            (parkBar) => parkBar.name === item.board_name
                          )?.context * 3
                      }
                    />
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          ""
        )}

        {titleImg && (
          <Row gutter={8} style={{ marginBottom: "2rem" }}>
            <Col span={8}>
              <Image preview={false} src={titleImg.title_left_url} />
            </Col>
            <Col span={8} offset={8}>
              <Image preview={false} src={titleImg.title_right_url} />
            </Col>
          </Row>
        )}
        <Typography>
          <Typography.Title level={5}>园区 / 协会：</Typography.Title>
        </Typography>
        <Row gutter={[24, 16]}>
          {parks.length > 0 &&
            parks.map((item, index) => (
              <Col key={index} span={6}>
                <Card
                  cover={
                    <img
                      height={192}
                      style={{ objectFit: "cover" }}
                      src={item.img_url}
                      alt="封面图"
                    />
                  }
                  actions={["立即访问"]}
                  hoverable
                  onClick={() => goto(item)}
                >
                  <Card.Meta title={item.name} description={item.address} />
                </Card>
              </Col>
            ))}
        </Row>
      </div>
    );
  }
  return (
    <div className={styles.loading}>
      <Spin size="large" />
    </div>
  );
}

import { Form, Input, Button, Divider, Checkbox, Space } from "antd";
import { Link, useNavigate } from "react-router-dom";

import styles from "./index.module.css";

export default function Index() {
  const navigate = useNavigate();
  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <div className={styles.head}>
          <div>
            <img className={styles.logo} src="/logo.png" alt="logo" />
          </div>
          <h1>注册新账号</h1>
        </div>
        <div className={styles.body}>
          <Form layout="vertical">
            <Form.Item label="邮箱">
              <Input placeholder="手机号码" />
            </Form.Item>
            <Form.Item label="短信验证码">
              {/* <Button type="primary" block>发送验证码</Button> */}
              <Input.Group compact>
                <Input
                  disabled
                  style={{ width: "calc(100% - 8rem)" }}
                  placeholder="短信验证码"
                />
                <Button type="primary" style={{ width: "8rem" }}>
                  发送验证码
                </Button>
              </Input.Group>
            </Form.Item>
            <Divider />
            <Form.Item label="登录密码">
              <Input.Password placeholder="登录密码" />
            </Form.Item>
            <Form.Item label="确认登录密码">
              <Input.Password placeholder="再输入一次登录密码" />
            </Form.Item>
            <Form.Item style={{ marginTop: "2rem" }}>
              <Checkbox>
                同意遵守<Link to="/">《环球品牌数据库服务协议》</Link>
              </Checkbox>
            </Form.Item>
            <Form.Item>
              <Space direction="vertical" style={{ width: "100%" }}>
                <Button size="large" type="primary" block>
                  立即注册
                </Button>
                <Button size="large" onClick={() => navigate("/")} block>
                  已经注册了，需要登录
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}

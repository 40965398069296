import { Typography, Table, Spin } from "antd";
import { NumberOutlined } from "@ant-design/icons";
import styles from "./index.module.css";
import { STATUS } from "../../constants";
// import qs from "query-string";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
export default function ShRiskPageModel({
  rowKey,
  config,
  dataSource,
  riskName,
  status,
  reqInfo,
  setData,
  risksTotal,
  setRisksTotal,
}) {
  const location = useLocation();
  // const [company_id, setCompany_id] = useState("");
  const [risks, setRisks] = useState([]);
  useEffect(() => {
    // const parsedQuery = qs.parse(location?.search?.slice(1), {
    //   parseNumbers: true,
    // });
    // setCompany_id(parsedQuery.company_id);
    if (dataSource.length > 0 && riskName) {
      if (dataSource[0][`${riskName}`]) {
        if (setRisksTotal) {
          setRisksTotal(JSON.parse(dataSource[0][`${riskName}`]).length);
        }
        setRisks(
          JSON.parse(dataSource[0][`${riskName}`]).map((item, index) => ({
            ...item,
            key: index,
          }))
        );
      }
    }
  }, [location, dataSource, setRisksTotal, riskName]);

  // const handleCurrentChange = async (current) => {
  //   await reqInfo(company_id, current).then((res) => {
  //     setData(res);
  //   });
  // };

  return (
    <>
      <Typography.Title className={styles.title} id={config.title} level={5}>
        <NumberOutlined />
        {config.title}
      </Typography.Title>
      <div className={styles.section}>
        <Spin spinning={status === STATUS.success ? false : true}>
          <Table
            columns={config.nameList}
            dataSource={risks}
            scroll={{ x: risks.length === 0 ? "max-content" : "1440px" }}
            pagination={{
              total: risksTotal,
              showSizeChanger: false,
              // onChange: handleCurrentChange,
            }}
          />
        </Spin>
      </div>
    </>
  );
}

import { Table, Spin } from "antd";
export default function ShTable({
  rowKey,
  config,
  dataSource,
  handleTableChange,
  rowSelection, // 复选框: 接受 rowSelection 作为属性
}) {
  return (
    <Spin spinning={dataSource ? false : true}>
      <Table
        columns={config.nameList}
        sortDirections={["descend", "ascend"]}
        dataSource={dataSource}
        rowKey={rowKey ?? "id"}
        pagination={false}
        onChange={handleTableChange}
        scroll={{ x: "max-content" }}
        rowSelection={rowSelection} // 复选框: 添加 rowSelection 属性
      />
    </Spin>
  );
}

import { useContext, useEffect, useState } from "react";
import qs from "query-string";
import { useLocation } from "react-router-dom";
import ShPageModel from "../../../components/ShPageModel";
import { STATUS } from "../../../constants";
import { UserContext } from "../../../context";
import AddNotice from "./cpn/addNotice";
import EditNotice from "./cpn/editNotice";
import noticeFn from "./hook/noticeFn"
import dayjs from "dayjs";
import { Divider, Badge } from "antd";
import { getNotice } from "../../../service/member/notice";

export default function Index() {
  const [status, setStatus] = useState(STATUS.idle);
  const { parkId, parkName } = useContext(UserContext);
  const location = useLocation();
  const { switchState, extractFirstTextNode } = noticeFn();
  const [total, setTotal] = useState(0);
  const [noticeList, setNoticeList] = useState([]);

  // 声明一个状态变量用于保存选中的行
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  // 声明一个状态变量用于保存 id 的数组
  const [noticeIds, setNoticeIds] = useState([]);

  // 当复选框状态改变时触发
  const onSelectChange = (newSelectedRowKeys, selectedRows, dataSource, rowKey) => {
    // console.log(`selectedRowKeys: ${newSelectedRowKeys}`, 'selectedRows: ', selectedRows);

    // 获取当前页面的选中行的 id
    const ids = selectedRows.map(row => row.id);

    // 从数据源中提取出每一项的行键（默认为 'id'），生成当前页面所有行的键数组
    const currentPageKeys = dataSource.map(item => item[rowKey ?? 'id']);

    // 更新选中的行键数组：
    // 1. 保留当前选中行键中那些在当前页面中的行键，并且这些行键仍然被新选中的行键包含。
    // 2. 保留当前选中行键中那些不在当前页面中的行键（这些行键不会被新选中的行键影响）。
    const updatedSelectedRowKeys = selectedRowKeys.filter(key =>
      currentPageKeys.includes(key) ? newSelectedRowKeys.includes(key) : true
    );

    // 更新选中的 id 数组：
    // 1. 保留当前选中 id 中那些在当前页面中的 id，并且这些 id 仍然被新选中的行数据包含。
    // 2. 保留当前选中 id 中那些不在当前页面中的 id（这些 id 不会被新选中的行数据影响）。
    const updatedNoticeIds = noticeIds.filter(id =>
      currentPageKeys.includes(dataSource.find(item => item.id === id)?.[rowKey ?? 'id']) ? ids.includes(id) : true
    );

    // 遍历新选中的行键数组，将不在 updatedSelectedRowKeys 中的行键添加进去
    newSelectedRowKeys.forEach(key => {
      if (!updatedSelectedRowKeys.includes(key)) {
        updatedSelectedRowKeys.push(key);
      }
    });

    // 遍历新选中的 id 数组，将不在 updatedNoticeIds 中的 id 添加进去
    ids.forEach(id => {
      if (!updatedNoticeIds.includes(id)) {
        updatedNoticeIds.push(id);
      }
    });

    // 更新状态
    setSelectedRowKeys(updatedSelectedRowKeys);
    setNoticeIds(updatedNoticeIds);
  };

  // 表格行选择配置
  const rowSelection = {
    selectedRowKeys,  // 当前选中的行键数组
    // 当行选择变化时，调用 onSelectChange 函数，并传递新选中的行键、新选中的行数据、当前页面的数据源和行键字段（默认为 'id'）
    onChange: (newSelectedRowKeys, selectedRows) => onSelectChange(newSelectedRowKeys, selectedRows, noticeList, 'id'),
  };

  // 清空复选框选中状态
  const resetSelection = () => {
    // console.log("调用了清空复选框选中状态");
    // 清空复选框选中状态
    setSelectedRowKeys([]);
    // 清空 id 数组
    setNoticeIds([]);
  };

  const noticeConfig = {
    nameList: [
      {
        title: '标题',
        dataIndex: 'title',
      },
      {
        title: '状态',
        dataIndex: 'status',
        key: 'status',
        render: (status) => {
          // 根据状态显示不同颜色的 Badge
          return status === 1 ? (
            <Badge color="green" text="发布" />
          ) : (
            <Badge color="orange" text="草稿" />
          );
        },
      },
      {
        title: '通知时间',
        dataIndex: 'broadcast_time',
        sorter: (a, b) => dayjs(a.broadcast_time).unix() - dayjs(b.broadcast_time).unix(), // 排序功能
        render(data) {
          // 格式化日期
          return data ? dayjs(data).format("YYYY-MM-DD") : "-";
        },
      },
      {
        title: '通知摘要',
        dataIndex: 'info',
        render: (text) => {
          const firstText = extractFirstTextNode(text); // 提取第一个包含文本的节点的内容
          return (
            <div style={{
              maxWidth: '200px', // 设置固定宽度
              whiteSpace: 'nowrap', // 不换行
              overflow: 'hidden', // 隐藏超出部分
              textOverflow: 'ellipsis' // 超出部分显示省略号
            }}>
              {firstText}
            </div>
          );
        },
      },
      {
        title: '操作',
        render: (data, record) => (
          <>
            <EditNotice
              record={{
                ...record,
                broadcast_time: record.broadcast_time ? dayjs(record.broadcast_time) : "",
              }}
              onStatusChange={handleChildStatusChange}
            />
            <Divider type="vertical" />
            <a type="test" onClick={() => switchState(
              data,
              handleChildStatusChange
            )}>
              {data.status === 0 ? '发布' : '撤回'}
            </a>
          </>
        )
      }
    ]
  };

  // 更新status状态，重新渲染表格
  function handleChildStatusChange(newStatus) {
    setStatus(newStatus);
  }

  const eiditButtons = () => {
    return <AddNotice
      onStatusChange={handleChildStatusChange}
      parkId={parkId}
      noticeIds={noticeIds} // 传递 noticeIds
      resetSelection={resetSelection} // 清空复选框: 传递 resetSelection 属性
    />
  };

  useEffect(() => {
    const parsedQuery = qs.parse(location.search.slice(1), {
      parseNumbers: true,
    });

    const doPost = () => {
      getNotice(parsedQuery, parkId).then(res => {
        // console.log(res, '通知列表数据');
        setNoticeList(res.data);
        setTotal(res.count);
        setStatus(STATUS.success);
      })
    };
    if (parkId && status === STATUS.idle) {
      doPost();
    }
  }, [location, parkId, status]);

  useEffect(() => {
    setStatus(STATUS.idle);
  }, [parkId, location]);

  return (
    <>
      <ShPageModel
        placeholder="通知标题"
        status={status}
        config={noticeConfig}
        dataSource={noticeList}
        total={total}
        eiditButtons={eiditButtons}
        rowSelection={rowSelection}
      />
    </>
  )
}

import { Modal } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import {
  reqDeleteContact
} from "../../../../service/details/contacts/index";
import { STATUS } from "../../../../constants";

// 定义一个删除联系人的函数
const delContacts = () => {
  const { confirm } = Modal; // 从 Modal 中获取 confirm 方法
  const delContactsData = (data, onStatusChange) => {
    // console.log(data, "data参数");

    // 显示确认对话框
    confirm({
      // 设置对话框的标题
      title: `您确定要将 ${data.name} 联系人删除嘛`,
      // 设置对话框的图标
      icon: <ExclamationCircleFilled />,
      // 确认删除时的回调函数
      onOk() {
        // 发送删除联系人请求
        reqDeleteContact(data.id).then(() => {
          // 更新状态为 idle
          onStatusChange(STATUS.idle);
        });
      },
    });
  };
  // 返回删除联系人数据的函数
  return {
    delContactsData,
  };
};

export default delContacts; // 导出删除联系人的函数

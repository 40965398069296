import { useRef, useEffect } from "react";
import Chart from "chart.js/auto";
import { range, random } from "lodash";

function Chart9() {
  const element = useRef();
  useEffect(() => {
    const chart = new Chart(element.current, {
      type: "line",
      data: {
        labels: range(5).map((index) => `${2019 + index}年`),
        datasets: [
          {
            data: range(5).map(() => random(0, 0)),
            fill: true,
            borderColor: "#2a9d8f",
          },
        ],
      },
      options: {
        scales: {
          y: {
            min: 0,
            max: 30,
            ticks: { stepSize: 1 },
          },
        },
        aspectRatio: 1.5,
        layout: {
          // padding: 16
        },
        plugins: {
          legend: false,
        },
      },
    });
    return () => chart.destroy();
  }, []);
  return <canvas ref={element} />;
}

export default Chart9;

import { useRef, useEffect } from "react";
import Chart from "chart.js/auto";
import { range, random } from "lodash";

import { palette1 } from "./palette";

function Chart8() {
  const element = useRef();
  useEffect(() => {
    const chart = new Chart(element.current, {
      type: "bar",
      data: {
        labels: [
          "03类 - 日化用品",
          "29类 - 食用制品",
          "05类 - 医药用品",
          "30类 - 方便食品",
          "35类 - 广告销售",
        ],
        datasets: [
          {
            data: range(5).map(() => random(0, 0)),
            backgroundColor: palette1,
          },
        ],
      },
      options: {
        aspectRatio: 1.5,
        scales: {
          y: {
            min: 0,
            max: 10,
            ticks: { stepSize: 1 },
          },
        },
        layout: {
          // padding: 16
        },
        plugins: {
          legend: false,
        },
      },
    });
    return () => chart.destroy();
  }, []);
  return <canvas ref={element} />;
}

export default Chart8;

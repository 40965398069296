import React, { useState, useEffect } from "react";
import { Button, Modal, Card, Col, Row, Input } from "antd";
import {
  reqAlreadyLogin,
  reqParkListInfo,
  reqParkLists,
} from "../service/home/login";
import { useNavigate } from "react-router-dom";
import { trim } from "lodash";
import { SearchOutlined } from "@ant-design/icons";
import styles from "./parkModal.module.css";
import RoleConsumer from "../providerRoute";

export default function ParkModal() {
  const navigate = useNavigate();
  const [inpValue, setInp] = useState("");
  const [open, setOpen] = useState(false);
  const [isAdmin, setAdmin] = useState(false);
  const [parkLists, setParkLists] = useState([]);
  const [filterParkLists, setFilterParkLists] = useState([]);
  const { Search } = Input;
  const { fetchRole } = RoleConsumer();

  const showModal = () => {
    setOpen(true);
  };
  const goto = async (short_name) => {
    setOpen(false);
    await fetchRole(short_name).then(() => {
      navigate(`/${short_name}/kanban/1`);
    });
  };
  const filterLists = () => {
    const result = parkLists.filter((item) =>
      item.name.includes(trim(inpValue))
    );
    setFilterParkLists(result);
  };

  const onChange = (e) => {
    setInp(e.target.value);
  };
  useEffect(() => {
    filterLists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inpValue]);
  useEffect(() => {
    reqAlreadyLogin()
      .then((res) => {
        if (res.user === null) {
          return new Promise.reject("无权访问");
        }
        return res;
      })
      .then((res) => {
        reqParkLists(res.data.user?.email).then((lists) => {
          if (lists.data.length > 1) {
            setAdmin(true);
            reqParkListInfo(lists.data).then((infos) => {
              setParkLists(infos.data);
              setFilterParkLists(infos.data);
            });
          }
        });
      })
      .catch((error) => {
        console.log("error :>> ", error);
      });
  }, []);
  return (
    <>
      {isAdmin && (
        <Button
          ghost
          type="primary"
          style={{ marginRight: "1rem" }}
          onClick={showModal}
        >
          园区列表
        </Button>
      )}
      <Modal
        onCancel={() => setOpen(false)}
        className={styles.container}
        footer={null}
        title="园区列表"
        open={open}
        width={1440}
      >
        <Row gutter={[16, 16]} style={{ marginBottom: "1rem" }} align="middle">
          <Col>
            <Search
              prefix={<SearchOutlined />}
              enterButton="搜索"
              placeholder="园区名称"
              size="large"
              value={inpValue}
              onChange={(e) => onChange(e)}
              onSearch={() => filterLists()}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          {parkLists.length > 0 &&
            filterParkLists.map((item, index) => (
              <Col key={index} span={6}>
                <Card
                  cover={
                    <img
                      height={192}
                      style={{ objectFit: "cover" }}
                      src={item.img_url}
                      alt="封面图"
                    />
                  }
                  actions={["立即访问"]}
                  hoverable
                  onClick={() => goto(item.short_name)}
                >
                  <Card.Meta title={item.name} description={item.address} />
                </Card>
              </Col>
            ))}
        </Row>
      </Modal>
    </>
  );
}

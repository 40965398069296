import { Link, useLocation, useNavigate } from "react-router-dom";
import { Form, Input, Button, Divider, message, Modal } from "antd";
import { LockOutlined } from "@ant-design/icons";
import styles from "./index.module.css";
import { reqUpdateUser, reqParkName } from "../../service/home/login";
import { useEffect, useState } from "react";
export default function Renew() {
  const [messageApi, contextHolder] = message.useMessage();
  const [park, setPark] = useState();
  const location = useLocation();

  const parkName = location?.pathname.split("/").filter(Boolean)[0];

  const navigate = useNavigate();
  const success = (message) => {
    messageApi.open({
      type: "success",
      content: message,
      duration: 10,
    });
  };

  const handleConfirm = () => {
    Modal.confirm({
      content: "修改成功",
      okText: "确认",
      onOk() {
        // 用户点击确认按钮时执行的回调函数
        navigate("/");
      },
    });
  };
  const resetPassword = async ({ password }) => {
    const email = JSON.parse(
      localStorage.getItem("sb-zevgwqoenxkhkvornmdw-auth-token")
    ).user.email;
    await reqUpdateUser(email, password).then(async () => {
      success("密码重置成功");
      handleConfirm();
    });
  };
  useEffect(() => {
    if (parkName) {
      reqParkName(parkName).then((res) => {
        if (res.data.length > 0) {
          setPark(res.data[0]);
        }
      });
    }
  }, [parkName]);
  return (
    <>
      {contextHolder}
      <section
        style={{
          backgroundImage: `url(${park ? park.bg_img_url : ""})`,
        }}
        className={styles.background}
      >
        <div className={styles.container}>
          <div className={styles.jumbo}>
            <div className={styles.login}>
              <div className={styles.title}>重设密码</div>
              <div className={styles.welcome}>
                <div>请输入新的登录密码,请勿刷新页面</div>
              </div>
              <Form onFinish={resetPassword}>
                <Divider>新的登录密码</Divider>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "请输入密码",
                    },
                  ]}
                >
                  <Input.Password
                    size="large"
                    prefix={<LockOutlined />}
                    placeholder="密码"
                  />
                </Form.Item>
                <Form.Item
                  name="repeat"
                  rules={[
                    {
                      required: true,
                      message: "请再次输入密码",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject("两次输入的密码不一致");
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    size="large"
                    prefix={<LockOutlined />}
                    placeholder="再次输入密码"
                  />
                </Form.Item>
                <Form.Item>
                  <Button htmlType="submit" type="primary" size="large" block>
                    提交
                  </Button>
                </Form.Item>
                <div>
                  <Link to={`/${parkName}`}>返回并重新登录</Link>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

import { Button, Modal, Result } from "antd";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context";

export default function ResultModal({ open, onCancel, orderId }) {
  const { parkName } = useContext(UserContext);
  const navigate = useNavigate();
  return (
    <Modal open={open} footer={null} onCancel={onCancel}>
      <Result
        status="success"
        title="提交成功!"
        extra={[
          <Button
            type="primary"
            key="order"
            onClick={() => navigate(`/${parkName}/consulting/${orderId}`)}
          >
            咨询详情
          </Button>,
          <Button
            key="list"
            onClick={() => navigate(`/${parkName}/consulting`)}
          >
            服务列表
          </Button>,
        ]}
      />
    </Modal>
  );
}

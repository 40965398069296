import { Typography, Table, Spin, Button } from "antd";
import { NumberOutlined, PlusOutlined } from "@ant-design/icons";
import styles from "./index.module.css";
import { STATUS } from "../../constants";
import qs from "query-string";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

import AddContact from "../../pages/query/companies/detailsCpn/addContact"; // 导入AddContact组件

export default function ShDetailPageModel({
  rowKey, // 表格行的唯一标识符
  config, // 表格配置，包括列信息和标题
  dataSource, // 表格的数据源
  status, // 当前表格的加载状态
  reqInfo, // 请求数据的方法
  setData, // 更新数据源的函数
  setStatus, // 更新状态的函数
  showAddButton, // 新增：是否显示添加按钮的标志
  id_com, // 新增：公司ID
  onPageChange, // 修改页码数的函数
  onContactAdded // 新增: 添加联系人后调用的回调函数
}) {
  const location = useLocation(); // 获取当前路由信息
  const [company_id, setCompany_id] = useState(""); // 保存公司ID

  useEffect(() => {
    // 解析URL中的查询参数，获取company_id
    const parsedQuery = qs.parse(location?.search?.slice(1), {
      parseNumbers: true,
    });
    setCompany_id(parsedQuery.company_id); // 设置公司ID
  }, [location]);

  const handleCurrentChange = async (current) => {
    // console.log("当前页码数--current:", current);
    // 处理分页变化时的逻辑
    setStatus(STATUS.loading); // 设置状态为加载中
    // 调用修改页码数的函数
    if (onPageChange) onPageChange(current);
    const id = showAddButton ? id_com : company_id; // 判断使用哪个ID
    await reqInfo(id, current).then((res) => {
      // 请求新页的数据
      setData(res); // 更新数据源
      setStatus(STATUS.success); // 设置状态为加载成功
    });
  };

  return (
    <>
      <div className={styles.header}>
        <Typography.Title className={styles.title} id={config.title} level={5}>
          <NumberOutlined />
          {config.title} {/* 表格标题 */}
        </Typography.Title>
        {showAddButton && (
          // 添加联系人组件
          <AddContact id_com={id_com} onContactAdded={onContactAdded} />
        )}
      </div>
      <div className={styles.section}>
        <Spin spinning={status === STATUS.success ? false : true}>
          <Table
            loading={status === STATUS.loading} // 表格加载状态
            columns={config.nameList} // 表格列配置
            dataSource={dataSource.data} // 表格数据
            rowKey={rowKey ?? "id"} // 表格行的唯一标识符
            scroll={{ x: "max-content" }} // 表格横向滚动配置
            pagination={{
              total: dataSource.count, // 数据总数
              showSizeChanger: false, // 是否展示页面大小切换器
              onChange: handleCurrentChange, // 分页变化时触发的函数
            }}
          />
        </Spin>
      </div>
    </>
  );
}

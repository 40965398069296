import { Button, Divider, Dropdown } from "antd";
import useDetail from "./handleFn";
import EditMember from "../cpn/editMember";

export function HandlerRender1({ onStatusChange, data, record }) {
  const { goToUserDetail, showConfirm } = useDetail();
  // const items = [
  //   {
  //     key: "1",
  //     label: <EditMember record={record} onStatusChange={onStatusChange} />,
  //   },
  //   {
  //     key: "2",
  //     label: (
  //       <Button
  //         style={{ width: "100%" }}
  //         type="test"
  //         onClick={() => showConfirm(data, onStatusChange)}
  //       >
  //         退会
  //       </Button>
  //     ),
  //   },
  // ];
  return (
    <>
      {/* <Button type="link" onClick={() => goToUserDetail(data.company_id)}>
        查看
      </Button>
      <Divider type="vertical" />
      <Dropdown
        trigger={["click"]}
        menu={{
          items,
        }}
        placement="topRight"
        arrow={{
          pointAtCenter: true,
        }}
      >
        <Button type="link">更多</Button>
      </Dropdown> */}
      <EditMember record={record} onStatusChange={onStatusChange} />
      <Divider type="vertical" />
      <a
        type="test"
        onClick={() => showConfirm(data, onStatusChange)}
      >
        退会
      </a>
    </>
  );
}

export function HandlerRender2({ data, onStatusChange }) {
  const { goToUserDetail, handleAgree, handleRefuse } = useDetail();

  const dropdownitems2 = [
    {
      key: "1",
      label: (
        <Button
          type="primary"
          ghost
          onClick={() => handleAgree(data, onStatusChange)}
        >
          同意
        </Button>
      ),
    },
    {
      key: "2",
      label: (
        <Button
          type="primary"
          ghost
          danger
          onClick={() => handleRefuse(data, onStatusChange)}
        >
          拒绝
        </Button>
      ),
    },
  ];
  return (
    <>
      <Button type="link" onClick={() => goToUserDetail(data.company_id)}>
        查看
      </Button>
      <Divider type="vertical" />
      <Dropdown
        menu={{
          items: dropdownitems2,
        }}
        placement="topRight"
        arrow={{
          pointAtCenter: true,
        }}
      >
        <Button type="link">更多</Button>
      </Dropdown>
    </>
  );
}

export function HandlerRender3({ data, onStatusChange }) {
  const { goToUserDetail, handleDelete } = useDetail();
  const dropdownitems2 = [
    {
      key: "1",
      label: (
        <Button type="test" onClick={() => handleDelete(data, onStatusChange)}>
          删除
        </Button>
      ),
    },
  ];
  return (
    <>
      <Button type="link" onClick={() => goToUserDetail(data.company_id)}>
        查看
      </Button>
      <Divider type="vertical" />
      <Dropdown
        menu={{
          items: dropdownitems2,
        }}
        placement="topRight"
        arrow={{
          pointAtCenter: true,
        }}
      >
        <Button type="link">更多</Button>
      </Dropdown>
    </>
  );
}

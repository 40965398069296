import { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import qs from "query-string";
import { Tag } from "antd";
import { Link } from "react-router-dom";

import ShPageModel from "../../../components/ShPageModel";
import PageHeader from "../../../components/PageHeader";

import { STATUS } from "../../../constants";
import { UserContext } from "../../../context";

import { reqPatentsList } from "../../../service/query/patent";
import { reqAxiospatentsLists } from "../../../api/lists";
export default function Patents() {
  const location = useLocation();
  const navigate = useNavigate();
  const [status, setStatus] = useState(STATUS.idle);
  const [patentsList, setPatentsList] = useState([]);
  const [total, setTotal] = useState(1);

  const [ptSort, setPtSort] = useState("");
  const [query, setQuery] = useState({});
  const handleStatus = (status) => {
    setStatus(status);
  };
  const { parkId, parkName } = useContext(UserContext);

  // 声明一个状态变量用于保存选中的行
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  // 声明一个状态变量用于保存 id_pt 的数组
  const [idPts, setIdPts] = useState([]);

  // 当复选框状态改变时触发
  const onSelectChange = (newSelectedRowKeys, selectedRows, dataSource, rowKey) => {
    // console.log(`selectedRowKeys: ${newSelectedRowKeys}`, 'selectedRows: ', selectedRows);

    // 获取当前页面的选中行的 id_pt
    const newIdPts = selectedRows.map(row => row.id_pt);

    // 从数据源中提取出每一项的行键（默认为 'id'），生成当前页面所有行的键数组
    const currentPageKeys = dataSource.map(item => item[rowKey ?? 'id']);

    // 更新选中的行键数组：
    // 1. 保留当前选中行键中那些在当前页面中的行键，并且这些行键仍然被新选中的行键包含。
    // 2. 保留当前选中行键中那些不在当前页面中的行键（这些行键不会被新选中的行键影响）。
    const updatedSelectedRowKeys = selectedRowKeys.filter(key =>
      currentPageKeys.includes(key) ? newSelectedRowKeys.includes(key) : true
    );

    // 更新选中的 id_pt 数组：
    // 1. 保留当前选中 id_pt 中那些在当前页面中的 id_pt，并且这些 id_pt 仍然被新选中的行数据包含。
    // 2. 保留当前选中 id_pt 中那些不在当前页面中的 id_pt（这些 id_pt 不会被新选中的行数据影响）。
    const updatedIdPts = idPts.filter(idPt =>
      currentPageKeys.includes(dataSource.find(item => item.id_pt === idPt)?.[rowKey ?? 'id']) ? newIdPts.includes(idPt) : true
    );

    // 遍历新选中的行键数组，将不在 updatedSelectedRowKeys 中的行键添加进去
    newSelectedRowKeys.forEach(key => {
      if (!updatedSelectedRowKeys.includes(key)) {
        updatedSelectedRowKeys.push(key);
      }
    });

    // 遍历新选中的 id_pt 数组，将不在 updatedIdPts 中的 id_pt 添加进去
    newIdPts.forEach(idPt => {
      if (!updatedIdPts.includes(idPt)) {
        updatedIdPts.push(idPt);
      }
    });

    // 更新状态
    setSelectedRowKeys(updatedSelectedRowKeys);
    setIdPts(updatedIdPts);
  };

  // 表格行选择配置
  const rowSelection = {
    selectedRowKeys,  // 当前选中的行键数组
    // 当行选择变化时，调用 onSelectChange 函数，并传递新选中的行键、新选中的行数据、当前页面的数据源和行键字段（默认为 'id_pt'）
    onChange: (newSelectedRowKeys, selectedRows) => onSelectChange(newSelectedRowKeys, selectedRows, patentsList, 'id_pt'),
  };

  // 清空复选框选中状态
  const resetSelection = () => {
    // 清空复选框选中状态
    setSelectedRowKeys([]);
    // 清空 id_pt 数组
    setIdPts([]);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const newQuery = {
      // query 在 useEffect中已经被替换为新的值
      ...query,
      sortFiled: sorter.field,
      sortOrder: sorter.order,
    };
    let newUrl = `?${Object.entries(newQuery)
      .map(([key, value]) => `${key}=${value ?? ""}`)
      .join("&")}`;
    navigate(newUrl);
  };
  const filterType = (typeName) => {
    switch (typeName) {
      case "中国实用新型专利":
        return "/icon/ico-patent-1.png";
      case "中国发明专利":
        return "/icon/ico-patent-2.png";
      case "中国外观设计专利":
        return "/icon/ico-patent-3.png";
      default:
        return;
    }
  };
  const patentsTableConfig = {
    nameList: [
      {
        title: "专利类型",
        dataIndex: "type_name",
        render: (data, record) => (
          <img
            style={{
              display: "block",
              width: "3rem",
              height: " 3rem",
            }}
            src={filterType(data)}
            alt="专利类型"
          />
        ),
      },
      {
        title: "专利名称",
        dataIndex: "patent_name",
        render: (data, record) => (
          <Link
            to={{
              pathname: `/${parkName}/query/patents/${record.id_pt}`,
              search: qs.stringify({ id_pt: record.id_pt }),
            }}
          >
            {data}
          </Link>
        ),
      },
      {
        title: "申请号",
        dataIndex: "request_num",
      },

      {
        title: "法律状态",
        dataIndex: "last_status",
      },
      {
        title: "是否授权",
        dataIndex: "authorize_tag",
        render: (data) =>
          data === "0" ? <Tag>未授权</Tag> : <Tag color="#1B9C85">已授权</Tag>,
      },
      {
        title: "申请人",
        dataIndex: "patent_person",
        width: 320,
      },
      {
        title: "申请时间",
        dataIndex: "request_date",
        sorter: true,
        sortOrder: ptSort,
      },
      {
        title: "授权公告时间",
        dataIndex: "authorize_date",
      },
    ],
  };

  useEffect(() => {
    const parsedQuery = qs.parse(location.search.slice(1), {
      parseNumbers: true,
    });
    setPtSort(
      parsedQuery.sortFiled === "request_date" ? parsedQuery.sortOrder : ""
    );
    setQuery(parsedQuery);
    if (parkId) {
      const doPost = () => {
        reqPatentsList(parsedQuery, parkId).then((res) => {
          setPatentsList(res.data);
          setTotal(res.count);
          setStatus(STATUS.success);
        });
      };
      if (status === STATUS.idle) {
        doPost();
      }
    }
  }, [location, total, parkId, status]);

  useEffect(() => {
    setStatus(STATUS.idle);
  }, [parkId, location]);
  return (
    <>
      <PageHeader
        title="专利数据检索"
        description="检索入驻园区企业的相关专利信息。"
      />

      <ShPageModel
        rowKey={"id_pt"}
        placeholder="专利名称/申请号"
        changeStatus={handleStatus}
        status={status}
        config={patentsTableConfig}
        dataSource={patentsList}
        total={total}
        reqExport={reqAxiospatentsLists}
        handleTableChange={handleTableChange}
        rowSelection={rowSelection} // 复选框: 传递 rowSelection 属性
        idPts={idPts} // 传递 idPts
        resetSelection={resetSelection} // 清空复选框: 传递 resetSelection 属性
      />
    </>
  );
}

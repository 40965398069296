import supabase from "../..";
export const reqActivityLists = async (parsedQuery, parkId) => {
  const {
    pageSize = 10,
    current = 1,
    keywords,
    startDate,
    endDate,
  } = parsedQuery;
  const start = (current - 1) * pageSize;
  const end = start + pageSize - 1;
  let query = supabase
    .from("member_meeting")
    .select("*,member_meeting_members(member_id,apply_status)", {
      count: "exact",
    })
    .eq("is_show", 1)
    .eq("park_id", parkId)
    .order("meeting_date", { ascending: false });
  if (keywords) {
    query.like("meeting_title", `%${keywords}%`);
  }
  if (startDate && endDate) {
    query.or(`and(meeting_date.gt.${startDate},meeting_date.lt.${endDate})`);
  }
  const { data, error, count } = await query.range(start, end);
  if (error) {
    throw new Error(error.message);
  }
  const result = {
    data: data ?? [],
    error: error,
    count: count,
  };
  return result;
};

export const reqActivityInfo = async (meeting_id, parkId) => {
  const { data, error } = await supabase
    .from("member_meeting")
    .select(
      "meeting_title,meeting_fee,status,meeting_date,issue_type,content,member_meeting_members(id)",
      {
        count: "exact",
      }
    )
    .eq("park_id", parkId)
    .eq("id", meeting_id)
    .eq("is_show", 1);

  if (error) {
    throw new Error(error.message);
  }
  const result = {
    data: data ?? [],
  };
  return result;
};

export const reqUpdateActivity = async (activityData) => {
  const { data, error } = await supabase
    .from("member_meeting")
    .update(activityData)
    .eq("id", activityData.id);

  if (error) {
    throw new Error(error.message);
  }
  const result = {
    data: data ?? [],
  };
  return result;
};

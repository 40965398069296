import { Typography, Image } from "antd";
import { clazzes } from "../../../intlClass";
const riskTrademarkConfig = {
  title: "商标风险",
  nameList: [
    {
      title: "商标",
      width: 120,
      dataIndex: "image_url",
      render: (data) => <Image width={64} src={data} alt="商标" />,
    },
    {
      title: "商标名称",
      dataIndex: "name",
    },
    {
      title: "风险",
      dataIndex: "risks",
      render: (data) => (
        <Typography.Text type="danger">
          临近商标专用期期限，待续展
        </Typography.Text>
      ),
    },
    {
      title: "时间",
      dataIndex: "end_date",
    },
    {
      title: "注册号",
      dataIndex: "reg_number",
    },
    {
      title: "分类",
      dataIndex: "type_num",
      render: (data) => clazzes[data - 1]?.title,
    },
  ],
};
export default riskTrademarkConfig;

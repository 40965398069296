import { Col, Form, Select } from "antd";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { UserContext } from "../../../context";
import qs from "query-string";
import { reqDomains, reqTypes } from "../../../service/query/companies";
export default function ComFilter({ form }) {
  const { pathname, search } = useLocation();
  const { parkId } = useContext(UserContext);
  const [show, setShow] = useState(false);
  const [domains, setDomains] = useState([]);
  const [tags, setTags] = useState([]);
  const [types, setTypes] = useState([]);
  useEffect(() => {
    if (parkId && pathname.indexOf("/query/companies") !== -1) {
      setShow(true);
      reqDomains(parkId).then((res) => {
        setDomains(res.data);
        setTags(res.tags);
      });
      reqTypes(parkId).then((res) => {
        setTypes(res.data);
      });
      let parsedQuery = qs.parse(search.slice(1), {
        parseNumbers: true,
      });
      Object.entries(parsedQuery).forEach(([key, value]) => {
        if (key === "tags") {
          value && form.setFieldValue(key, [...value.split(",")]);
        } else {
          value && form.setFieldValue(key, value);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parkId, pathname, search]);
  return (
    <>
      {show && (
        <>
          <Col>
            <Form.Item name="industory">
              <Select
                placeholder="所属行业"
                onChange={() => form.submit()}
                allowClear
                showSearch
                style={{ minWidth: "160px" }}
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                options={(domains || []).map((d) => ({
                  value: JSON.parse(d)[0],
                  label: JSON.parse(d)[0],
                }))}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name="highTech">
              <Select
                placeholder="企业荣誉"
                onChange={() => form.submit()}
                allowClear
                showSearch
                style={{ minWidth: "160px" }}
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                options={(types || []).map((d) => ({
                  value: d,
                  label: d,
                }))}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name="tags">
              <Select
                mode="multiple"
                placeholder="标签"
                onChange={() => form.submit()}
                showSearch
                style={{ minWidth: "160px" }}
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                options={(tags || []).map((d) => ({
                  value: d,
                  label: d,
                }))}
              />
            </Form.Item>
          </Col>
        </>
      )}
    </>
  );
}

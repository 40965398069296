import supabase from "..";

export const reqTradeMarksList = async (parsedQuery, parkId) => {
  const defaultQuery = {
    pageSize: 10,
    current: 1,
    keywords: "",
    tradeType: "",
    tradeStatus: "",
    tradeCom: "",
    sortFiled: "",
    sortOrder: "",
    type_num: "",
  };
  parsedQuery = { ...defaultQuery, ...parsedQuery };

  const { pageSize, current, keywords } = parsedQuery;
  const start = (current - 1) * pageSize;
  const end = start + pageSize - 1;
  let query = supabase
    .from("park_trademark")
    .select(`*,trademark!inner(*)`, { count: "exact" })
    .eq("park_id", parkId);
  if (
    parsedQuery.tradeType === "驰名商标" ||
    parsedQuery.tradeType === "海关备案" ||
    parsedQuery.tradeType === "马德里商标"
  ) {
    const resultNone = {
      data: [],
      count: 0,
    };
    return resultNone;
  }
  if (parsedQuery.type_num) {
    query.eq("trademark.type_num", parsedQuery.type_num);
  }

  if (parsedQuery.sortFiled === "apply_date") {
    query.neq("trademark.apply_date", "-");
    query.order("apply_date_timestamp", {
      ascending: parsedQuery.sortOrder === "ascend" ? true : false,
    });
  }
  if (parsedQuery.sortFiled === "reg_pubdate") {
    query.neq("trademark.reg_pubdate", "-");
    query.order("reg_pubdate_timestamp", {
      ascending: parsedQuery.sortOrder === "ascend" ? true : false,
    });
  }
  if (parsedQuery.tradeStatus) {
    query.like("trademark.status", `%${parsedQuery.tradeStatus}%`);
  }
  if (parsedQuery.tradeCom) {
    query.like("trademark.company", `%${parsedQuery.tradeCom}%`);
  }
  if (keywords) {
    query.or(`name.like.%${keywords}%,reg_number.like.%${keywords}%`, {
      foreignTable: "trademark",
    });
  }
  const { error, data, count } = await query.range(start, end);
  if (error) {
    console.error("获取数据失败:", error.message);
  }
  const result = {
    data: data ? data.map((item) => item.trademark) : [],
    count: count ?? 0,
  };
  return result;
};

// 商标状态
export const reqTradeStatus = async (parkId) => {
  const { data, error } = await supabase.rpc("get_trademark_statuses", {
    parkid: parkId,
  });
  if (error) {
    console.error("获取数据失败:", error.message);
  }
  const result = {
    data: data ? data.filter((item) => item.status) : [],
  };
  return result;
};
export const reqTradeMarkInfo = async (reg_number) => {
  const result = await supabase
    .from("trademark")
    .select("*")
    .eq("reg_number", reg_number);
  const { error } = result;
  if (error) {
    console.error("获取数据失败:", error.message);
  }
  return result;
};

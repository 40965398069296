import PageHeader from "../../../components/PageHeader";
import { STATUS } from "../../../constants";
import { activity_status, activity_type, fee_status } from "../../../intlClass";
import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { UserContext } from "../../../context";
import qs from "query-string";
import { reqActivityDetailLists } from "../../../service/member/activity/details";
import ShPageModel from "../../../components/ShPageModel";
import { ActivityRender2 } from "./hook/ActivityRender";
import dayjs from "dayjs";
import { reqActivityInfo } from "../../../service/member/activity";
import { Divider, Statistic, Tag, Typography } from "antd";

export default function ActivityDetail() {
  const { parkName } = useContext(UserContext);
  const { pid } = useParams();
  const [status, setStatus] = useState(STATUS.idle);
  const [activityDetailLists, setActivityDetailLists] = useState([]);
  const [activityInfo, setActivityInfo] = useState();
  const { parkId } = useContext(UserContext);
  const [total, setTotal] = useState(0);
  const { search } = useLocation();
  const ActivityDetailConfig = {
    nameList: [
      {
        title: "企业名称",
        dataIndex: "member",
        render(data) {
          return data && data.member_name;
        },
      },

      {
        title: "报名状态",
        dataIndex: "apply_status",
        render(data) {
          return data ? <Tag color="#1B9C85">已报名</Tag> : <Tag>未报名</Tag>;
        },
      },
      {
        title: "报名时间",
        dataIndex: "join_date",
        render(data) {
          return data ? dayjs(data).format("YYYY-MM-DD") : "-";
        },
      },
      {
        title: "缴费状态",
        dataIndex: "fee_status",
        render(data) {
          return fee_status.find((item) => item.id === data).title;
        },
      },

      {
        title: "操作",
        render(data) {
          return (
            <ActivityRender2
              onStatusChange={handleChildStatusChange}
              data={data}
            />
          );
        },
      },
    ],
  };
  const ActivityTableConfig = [
    {
      title: "时间",
      dataIndex: "meeting_date",
    },
    {
      title: "类型",
      dataIndex: "issue_type",
    },
    {
      title: "邀请人数",
      dataIndex: "InviteMembers",
    },
    {
      title: "报名人数",
      dataIndex: "ApplyMembers",
    },
    {
      title: "费用（元）",
      dataIndex: "meeting_fee",
    },
    {
      title: "状态",
      dataIndex: "status",
    },
  ];
  function handleChildStatusChange(newStatus) {
    setStatus(newStatus);
  }
  useEffect(() => {
    let parsedQuery = qs.parse(search.slice(1), {
      parseNumbers: true,
    });
    const doPost = () => {
      reqActivityInfo(pid, parkId).then((res) => {
        setActivityInfo({
          ...res.data[0],
          meeting_date:
            res.data[0].meeting_date &&
            dayjs(res.data[0].meeting_date).format("YYYY-MM-DD"),
          issue_type:
            res.data[0].issue_type &&
            activity_type.find((item) => item.id === res.data[0].issue_type)
              .title,
          InviteMembers:
            res.data[0].member_meeting_members &&
            res.data[0].member_meeting_members.length,
          ApplyMembers:
            res.data[0].member_meeting_members &&
            res.data[0].member_meeting_members.filter(
              (item) => item.apply_status === 1
            ).length,
          status:
            res.data[0].status &&
            activity_status.find((item) => item.id === res.data[0].status)
              .title,
        });
      });
      reqActivityDetailLists(parsedQuery, pid).then((res) => {
        setActivityDetailLists(res.data);
        setTotal(res.total);
        setStatus(STATUS.success);
      });
    };
    if (parkId && status === STATUS.idle && pid) {
      doPost();
    }
  }, [parkId, search, status, pid]);

  useEffect(() => {
    if (status === STATUS.success) {
      setStatus(STATUS.idle);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);
  return (
    <>
      <PageHeader
        breadcrumb={[
          {
            title: <Link to={`/${parkName}/activity/2`}>活动管理</Link>,
          },
          {
            title: `${activityInfo ? activityInfo.meeting_title : ""}`,
          },
        ]}
      />
      <Typography style={{ margin: "1rem 0" }}>
        <Typography.Title level={3}>
          活动主题：{activityInfo ? activityInfo.meeting_title : ""}
        </Typography.Title>
        <Typography.Title level={5}>
          活动内容：{activityInfo ? activityInfo.content : ""}
        </Typography.Title>
      </Typography>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "1rem",
          backgroundColor: "#fff",
        }}
      >
        {activityInfo &&
          ActivityTableConfig.map((item, index) => (
            <div key={index} style={{ display: "flex", padding: "1rem" }}>
              {index > 0 && <Divider type="vertical" style={{ height: 64 }} />}
              <div style={{ padding: "0 1rem" }}>
                <Statistic
                  title={item.title}
                  value={activityInfo[item.dataIndex]}
                />
              </div>
            </div>
          ))}
      </div>

      <div>
        <ShPageModel
          config={ActivityDetailConfig}
          status={status}
          dataSource={activityDetailLists}
          rowKey={"id"}
          placeholder={"公司名称"}
          total={total}
        />
      </div>
    </>
  );
}

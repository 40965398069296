import { useContext, useEffect, useState } from "react";
import PageHeader from "../../components/PageHeader";
import ShPageModel from "../../components/ShPageModel";
import { STATUS } from "../../constants";
import { UserContext } from "../../context";
import { reqTmGoods } from "../../service/management/transaction";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Image, Tag, Popover } from "antd";
import { clazzes } from "../../intlClass";
import { reqAxiostransactionLists } from "../../api/lists";
import qs from "query-string";
import dayjs from "dayjs";
var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);
export default function Transaction() {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const { parkName } = useContext(UserContext);
  const [status, setStatus] = useState(STATUS.idle);
  const [tmGoodsList, setTmGoodsList] = useState([]);
  const [total, setTotal] = useState(0);

  // 声明一个状态变量用于保存选中的行
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  // 声明一个状态变量用于保存 id 的数组
  const [ids, setIds] = useState([]);

  // 当复选框状态改变时触发
  const onSelectChange = (newSelectedRowKeys, selectedRows, dataSource, rowKey) => {
    // console.log(`selectedRowKeys: ${newSelectedRowKeys}`, 'selectedRows: ', selectedRows);

    // 获取当前页面的选中行的 id
    const idList = selectedRows.map(row => row.id);

    // 从数据源中提取出每一项的行键（默认为 'id'），生成当前页面所有行的键数组
    const currentPageKeys = dataSource.map(item => item[rowKey ?? 'id']);

    // 更新选中的行键数组：
    // 1. 保留当前选中行键中那些在当前页面中的行键，并且这些行键仍然被新选中的行键包含。
    // 2. 保留当前选中行键中那些不在当前页面中的行键（这些行键不会被新选中的行键影响）。
    const updatedSelectedRowKeys = selectedRowKeys.filter(key =>
      currentPageKeys.includes(key) ? newSelectedRowKeys.includes(key) : true
    );

    // 更新选中的 id 数组：
    // 1. 保留当前选中 id 中那些在当前页面中的 id，并且这些 id 仍然被新选中的行数据包含。
    // 2. 保留当前选中 id 中那些不在当前页面中的 id（这些 id 不会被新选中的行数据影响）。
    const updatedIds = ids.filter(id =>
      currentPageKeys.includes(dataSource.find(item => item.id === id)?.[rowKey ?? 'id']) ? idList.includes(id) : true
    );

    // 遍历新选中的行键数组，将不在 updatedSelectedRowKeys 中的行键添加进去
    newSelectedRowKeys.forEach(key => {
      if (!updatedSelectedRowKeys.includes(key)) {
        updatedSelectedRowKeys.push(key);
      }
    });

    // 遍历新选中的 id 数组，将不在 updatedIds 中的 id 添加进去
    idList.forEach(id => {
      if (!updatedIds.includes(id)) {
        updatedIds.push(id);
      }
    });

    // 更新状态
    setSelectedRowKeys(updatedSelectedRowKeys);
    setIds(updatedIds);
  };

  // 表格行选择配置
  const rowSelection = {
    selectedRowKeys,  // 当前选中的行键数组
    // 当行选择变化时，调用 onSelectChange 函数，并传递新选中的行键、新选中的行数据、当前页面的数据源和行键字段（默认为 'id'）
    onChange: (newSelectedRowKeys, selectedRows) => onSelectChange(newSelectedRowKeys, selectedRows, tmGoodsList, 'id'),
  };

  // 清空复选框选中状态
  const resetSelection = () => {
    // 清空复选框选中状态
    setSelectedRowKeys([]);
    // 清空 id 数组
    setIds([]);
  };

  const handleButton = (record) => {
    if (!search) {
      navigate(`?pageSize=10&current=1&keywords=${record.mark_name}`);
      return;
    }
    const url = qs.parse(search);
    url.keywords = record.mark_name;
    url.pageSize = 10;
    url.current = 1;
    navigate("?" + qs.stringify(url));
  };
  const companiesTableConfig = {
    nameList: [
      {
        title: "商标名称",
        dataIndex: "mark_name",
        fixed: "left",
        render: (data, record) => (
          <Link
            to={{
              pathname: `/${parkName}/transaction/${record.id}`,
              search: `?request_no=${record.request_no}&good_kind=${record.good_kind}`,
            }}
          >
            {data}
          </Link>
        ),
      },
      {
        title: "商标",
        fixed: "left",
        dataIndex: "request_no",
        render: (data) => {
          return (
            <Popover
              placement="right"
              title={
                <Image
                  width={256}
                  preview={false}
                  src={`https://biaoxq-oss.oss-cn-beijing.aliyuncs.com/${data}.jpg`}
                />
              }
            >
              <Image
                width={96}
                src={`https://biaoxq-oss.oss-cn-beijing.aliyuncs.com/${data}.jpg`}
              />
            </Popover>
          );
        },
      },

      {
        title: "注册号",
        dataIndex: "request_no",
      },
      {
        title: "分类",
        dataIndex: "good_kind",
        render: (data) => "第" + data + "类-" + clazzes[data - 1].title,
      },
      {
        title: "商品/服务",
        width: 480,
        dataIndex: "group_cn",
      },
      {
        title: "关联群组",
        width: 480,
        dataIndex: "group_id",
        render: (data) => {
          return data.split(",").map((item, index) => (
            <Tag color="green" key={index}>
              {item}
            </Tag>
          ));
        },
      },
      {
        title: "注册日期",
        dataIndex: "registration_date",
        render: (data) => {
          return data ? dayjs.utc(data).format("YYYY年MM月DD日") : "-";
        },
      },
      {
        title: "最低报价",
        dataIndex: "price",
        render: (data) => "￥" + data,
      },
      {
        title: "操作",
        render: (data, record) => {
          return (
            <Button ghost type="primary" onClick={() => handleButton(record)}>
              搜索同名
            </Button>
          );
        },
      },
    ],
  };
  useEffect(() => {
    let parsedQuery = qs.parse(search.slice(1), {
      parseNumbers: true,
    });
    const fetchTmGoods = async () => {
      reqTmGoods(parsedQuery)
        .then((res) => {
          setStatus(STATUS.success);
          setTmGoodsList(res.data);
          setTotal(res.count);
        })
        .catch((error) => {
          console.log("error :>> ", error);
        });
    };
    if (status === STATUS.idle) {
      fetchTmGoods();
    }
  }, [status, search]);
  useEffect(() => {
    setStatus(STATUS.idle);
  }, [pathname, search]);
  return (
    <>
      <PageHeader title={"品牌交易"} />
      <ShPageModel
        config={companiesTableConfig}
        status={status}
        dataSource={tmGoodsList}
        reqExport={reqAxiostransactionLists}
        rowKey={"id"}
        placeholder={"商标名称/注册号"}
        total={total}
        rowSelection={rowSelection} // 复选框: 传递 rowSelection 属性
        ids={ids} // 传递 ids
        resetSelection={resetSelection} // 清空复选框: 传递 resetSelection 属性
      />
    </>
  );
}

import { Link } from "react-router-dom";
import { Form, Input, Modal, Alert, Row, Col, message, Select } from "antd";
import styles from "./Create.Modal.module.css";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context";
import { IsqEmpowerment } from "../../service/empowerment/createmodel";
import { reqInsComment } from "../../service/consulting";
import ResultModal from "./Result.Modal";
import { reqCompanyWithKeyWords } from "../../service/empowerment/createmodel";
export default function CreateModal({
  open,
  onOk,
  onCancel,
  brand_id,
  title,
  img_url,
}) {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const { parkId } = useContext(UserContext);
  const [isMaskOpen, setIsMaskOpen] = useState(false);
  const [orderId, setOrderId] = useState();
  const [data, setData] = useState([]);
  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        const queryInfo = form.getFieldsValue();
        IsqEmpowerment(queryInfo, brand_id, parkId)
          .then((res) => {
            onOk();
            return res.data[0];
          })
          .then((res) => {
            setIsMaskOpen(true);
            setOrderId(res.id);
            reqInsComment(queryInfo.message, parkId, res.id, 1);
          });
      })
      .catch(() => {
        messageApi.open({
          type: "error",
          content: "提交失败,请填写信息!",
          className: "custom-class",
        });
      });
  };

  useEffect(() => {
    if (parkId) {
      reqCompanyWithKeyWords(parkId).then((res) => {
        setData(res.data);
      });
    }
  }, [parkId]);

  return (
    <>
      <Modal
        title="咨询服务申请表"
        open={open}
        onOk={onFinish}
        confirmLoading={!open}
        onCancel={onCancel}
        width={720}
      >
        {contextHolder}
        <Row gutter={32} className={styles.root}>
          <Col span={16}>
            <Alert
              className={styles.alert}
              type="info"
              message="请填写联系方式，咨询顾问将很快与其联系。"
              showIcon
            />
            <Form
              wrapperCol={{ span: 19 }}
              labelCol={{ span: 5 }}
              form={form}
              name="validateOnly"
            >
              <Form.Item label="咨询服务">
                <div className={styles.card}>
                  <div className={styles.header}>
                    <img src={img_url} alt="封面图" />
                  </div>
                  <div className={styles.body}>
                    <div className={styles.secondary}>服务代码：{brand_id}</div>
                    <div className={styles.primary}>{title}</div>
                  </div>
                </div>
              </Form.Item>
              <Form.Item
                label="公司"
                name="company_name"
                rules={[
                  {
                    required: true,
                    message: "请求填写联系人姓名!",
                    validateTrigger: ["onChange"],
                  },
                ]}
              >
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? "").includes(input)
                  }
                  options={(data || []).map((d) => ({
                    value: d.format_name,
                    label: d.format_name,
                  }))}
                />
              </Form.Item>
              <Form.Item
                label="联系人"
                name="name"
                initialValue={""}
                rules={[
                  {
                    required: true,
                    message: "请求填写联系人姓名!",
                    validateTrigger: ["onChange"],
                  },
                ]}
              >
                <Input placeholder="" />
              </Form.Item>
              <Form.Item
                label="手机"
                name="tel"
                initialValue={""}
                rules={[
                  {
                    required: true,
                    message: "请求填写手机号!",
                    validateTrigger: "onChange",
                  },
                ]}
              >
                <Input placeholder="" />
              </Form.Item>
              <Form.Item label="职务" name="duties" initialValue={""}>
                <Input placeholder="" />
              </Form.Item>
              <Form.Item label="留言" name="message" initialValue={""}>
                <Input.TextArea placeholder="" rows={6} />
              </Form.Item>
            </Form>
          </Col>
          <Col span={8}>
            <div className={styles.consultant}>
              <div className={styles.header}>
                <img src={"/consultant/雷先生.jpg"} alt="顾问头像" />
              </div>
              <div className={styles.body}>
                <div className={styles.row}>
                  <div className={styles.secondary}>高级咨询服务顾问</div>
                  <div className={styles.primary}>雷先生</div>
                </div>
                <div className={styles.row}>
                  <div className={styles.secondary}>联系电话</div>
                  <div className={styles.primary}>
                    <Link href="tel:+8618600000000">186-0000-0000</Link>
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={styles.secondary}>企业微信</div>
                  <div className={styles.primary}>
                    <img
                      className={styles.qrcode}
                      src="/consultant/qr-雷先生.jpg"
                      alt="二维码"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Modal>
      <ResultModal
        open={isMaskOpen}
        onCancel={() => setIsMaskOpen(false)}
        orderId={orderId}
      />
    </>
  );
}

import { Row, Col, Statistic, Button, Spin } from "antd";
import styles from "./kanban.module.css";
import {
  Chart12,
  // Chart13,
  Chart14,
  Chart15,
} from "../../components/charts";
import PageHeader from "../../components/PageHeader";
import { useNavigate } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { reqCounts, reqKanBanFilterInfo } from "../../service/kanban";
import { UserContext } from "../../context";
import { STATUS } from "../../constants";
import dayjs from "dayjs";
var utc = require("dayjs/plugin/utc");
const span = { xs: 24, sm: 24, md: 24, lg: 12, xl: 8, xxl: 8 };

export default function Kanban1() {
  dayjs.extend(utc);

  const [kanbanFilterInfo, setKanBanFilterInfo] = useState([]);
  const [total, setTotal] = useState(0);
  const [status, setStatus] = useState(STATUS.idle);
  const [dateTime, setDateTime] = useState("");
  const [practicalTotal, setPracticalTotal] = useState(0);
  const [appearanceTotal, setAppearanceTotal] = useState(0);
  const [inventTotal, setInventTotal] = useState(0);

  const { parkId, parkName } = useContext(UserContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (parkId) {
      const fetchData = async () => {
        const fetchCounts = reqCounts("select_count_pt", parkId);
        const fetchKanBan = reqKanBanFilterInfo(parkId, 3);
        Promise.all([fetchCounts, fetchKanBan]).then(([count, kanban]) => {
          setTotal(count.data);
          setKanBanFilterInfo(kanban.data);
          setDateTime(kanban.data[0]?.created_at);

          setStatus(STATUS.success);
        });
      };

      if (status === STATUS.idle) {
        fetchData();
      }
    }
  }, [parkId, status]);
  useEffect(() => {
    if (parkId) {
      setStatus(STATUS.idle);
      setKanBanFilterInfo([]);
    }
  }, [parkId]);
  return (
    <Spin spinning={status === STATUS.success ? false : true}>
      <PageHeader
        title="专利数据统计"
        description="针对入驻园区企业的专利、PCT专利等数据的统计看板。"
      />
      <div className={styles.timestamp}>
        最后更新时间：{" "}
        {dateTime && dayjs.utc(dateTime).format("YYYY年MM月DD日 hh:mm:ss")}
      </div>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <div className={styles.section}>
            <div className={styles.statistic}>
              <div className={styles.item}>
                <Statistic title="专利注册数量" value={total} />
                <Button
                  type="primary"
                  onClick={() => navigate(`/${parkName}/query/patents`)}
                >
                  查看详情
                </Button>
              </div>
              <div className={styles.divider} />
              <div className={styles.item}>
                <Statistic title="实用新型专利数量" value={practicalTotal} />
                <Button
                  type="primary"
                  onClick={() =>
                    navigate(
                      `/${parkName}/query/patents?patentType=中国实用新型专利&pageSize=10&current=1&keywords=`
                    )
                  }
                >
                  查看详情
                </Button>
              </div>
              <div className={styles.item}>
                <Statistic title="外观设计专利数量" value={appearanceTotal} />
                <Button
                  type="primary"
                  onClick={() =>
                    navigate(
                      `/${parkName}/query/patents?patentType=中国外观设计专利&pageSize=10&current=1&keywords=`
                    )
                  }
                >
                  查看详情
                </Button>
              </div>
              <div className={styles.item}>
                <Statistic title="发明专利数量" value={inventTotal} />
                <Button
                  type="primary"
                  onClick={() =>
                    navigate(
                      `/${parkName}/query/patents?patentType=中国发明专利&pageSize=10&current=1&keywords=`
                    )
                  }
                >
                  查看详情
                </Button>
              </div>
              <div className={styles.item}>
                <Statistic title="PCT专利注册数量" value={0} />
                <Button
                  type="primary"
                  onClick={() =>
                    navigate(
                      `/${parkName}/query/patents?patentType=PCT&pageSize=10&current=1&keywords=`
                    )
                  }
                >
                  查看详情
                </Button>
              </div>
            </div>
          </div>
        </Col>
        <Col {...span}>
          <div className={styles.section}>
            <div className={styles.chart}>
              <h2>专利注册类型</h2>
              <Chart12
                setPracticalTotal={setPracticalTotal}
                setAppearanceTotal={setAppearanceTotal}
                setInventTotal={setInventTotal}
                kanbanFilterInfo={kanbanFilterInfo}
                status={status}
              />
            </div>
          </div>
        </Col>
        {/* <Col {...span}>
          <div className={styles.section}>
            <div className={styles.chart}>
              <h2>PCT专利注册类型</h2>
              <Chart13 />
            </div>
          </div>
        </Col> */}
        <Col {...span}>
          <div className={styles.section}>
            <div className={styles.chart}>
              <h2>专利申请/注册数量（近5年）</h2>
              <Chart14 kanbanFilterInfo={kanbanFilterInfo} status={status} />
            </div>
          </div>
        </Col>
        <Col {...span}>
          <div className={styles.section}>
            <div className={styles.chart}>
              <h2>PCT专利注册数量（近5年）</h2>
              <Chart15 />
            </div>
          </div>
        </Col>
      </Row>
    </Spin>
  );
}

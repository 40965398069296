import supabase from "../..";
export const reqActivityDetailLists = async (parsedQuery, meeting_id) => {
  const { pageSize = 10, current = 1, keywords } = parsedQuery;
  const start = (current - 1) * pageSize;
  const end = start + pageSize - 1;
  let query = supabase
    .from("member_meeting_members")
    .select("*,member!inner(member_name)", {
      count: "exact",
    })
    .eq("meeting_id", meeting_id)
    .order("apply_status", { ascending: false })
    .order("join_date", { ascending: false });

  if (keywords) {
    query.like("member.member_name", `%${keywords}%`);
  }

  const { data, error, count } = await query.range(start, end);
  if (error) {
    throw new Error(error.message);
  }
  const result = {
    data: data ?? [],
    error: error,
    count: count,
  };
  return result;
};

export const reqUpdateActivityFee = async (feeData) => {
  const { data, error } = await supabase
    .from("member_meeting_members")
    .update(feeData)
    .eq("meeting_id", feeData.meeting_id)
    .eq("member_id", feeData.member_id);
  if (error) {
    throw new Error(error.message);
  }
  console.log("error :>> ", error);
  return data;
};

export const reqDelActivyFee = async (feeData) => {
  const { error } = await supabase
    .from("member_meeting_members")
    .delete()
    .eq("meeting_id", feeData.meeting_id)
    .eq("member_id", feeData.member_id);
  if (error) {
    throw new Error(error.message);
  }
  console.log("error :>> ", error);
};

export const reqInsertActivity = async (activityData) => {
  const { data, error } = await supabase
    .from("member_meeting")
    .insert([activityData])
    .select("id,meeting_fee");
  console.log("error :>> ", error);
  return data[0];
};

export const reqUpdateActivity = async (activityData) => {
  const { data, error } = await supabase
    .from("member_meeting")
    .update(activityData)
    .eq("id", activityData.id)
    .select();
  console.log("error :>> ", error);
  return data[0];
};
export const reqInsertMembers = async (MemberData) => {
  const { error } = await supabase
    .from("member_meeting_members")
    .insert(MemberData);
  console.log("error :>> ", error);
};

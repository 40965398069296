import { Row, Col, Anchor, Statistic } from "antd";
import { Link, useLocation } from "react-router-dom";
import PageHeader from "../../components/PageHeader";
import styles from "./monitoring.module.css";
import { useContext, useEffect, useState } from "react";
import qs from "query-string";

import ShDescription from "../../components/ShDescription";
import Tags from "../../components/Tags";
import { STATUS } from "../../constants";
import baseDesConfig from "./detailsConfig/baseDesConfig";
import contactDesConfig from "./detailsConfig/contactDesConfig";
import riskTableConfig from "./detailsConfig/riskTableConfig";
import riskTrademarkConfig from "./detailsConfig/riskTrademarkConfig";
import riskPatentConfig from "./detailsConfig/riskPatentConfig";

import { reqBaseTech, reqCompanyInfo } from "../../service/query/companies";
import ShRiskPageModel from "../../components/shRiskPageModel";
import { UserContext } from "../../context";
export default function Details() {
  const [status, setStatus] = useState(STATUS.idle);
  const location = useLocation();
  const [companyInfo, setCompanyInfo] = useState([]);
  const [risksTotal, setRisksTotal] = useState(0);
  const [riskTradeTotal, setRisksTradeTotal] = useState(0);
  const [riskPatentTotal, setRisksPatentTradeTotal] = useState(0);
  const { parkName } = useContext(UserContext);

  useEffect(() => {
    const doPost = () => {
      const parsedQuery = qs.parse(location.search.slice(1), {
        parseNumbers: true,
      });

      reqCompanyInfo(parsedQuery.company_id).then((res) => {
        reqBaseTech(parsedQuery.company_id).then((tech) => {
          const data = res.data.map((item) => ({
            ...item,
            tech_ent: tech.data,
          }));
          setCompanyInfo(data);
          setStatus(STATUS.success);
        });
      });
    };
    if (status === STATUS.idle) {
      doPost();
    }
  }, [location, status]);
  return (
    <>
      <PageHeader
        title={companyInfo[0]?.format_name}
        description="实时监测企业的知识产权和经营状态，及时发现风险并作出提醒。"
        breadcrumb={[
          { title: <Link to={`/${parkName}/monitoring`}>监测预警</Link> },
          { title: `${companyInfo[0]?.format_name ?? ""}` },
        ]}
      />
      <div>
        <Tags dataSource={companyInfo} status={status} setStatus={setStatus} />
      </div>
      <Row gutter={32}>
        <Col span={18}>
          <div className={styles.section}>
            <div className={styles.statistic}>
              <div className={styles.item}>
                <Statistic
                  title="经营风险"
                  value={risksTotal}
                  valueStyle={{ color: "#EC5B56" }}
                />
              </div>
              <div className={styles.divider} />
              <div className={styles.item}>
                <Statistic
                  title="商标风险"
                  value={riskTradeTotal}
                  valueStyle={{ color: "#EC5B56" }}
                />
              </div>
              <div className={styles.divider} />
              <div className={styles.item}>
                <Statistic
                  title="专利风险"
                  value={riskPatentTotal}
                  valueStyle={{ color: "#EC5B56" }}
                />
              </div>
            </div>
          </div>

          {/* 基本信息 */}
          <ShDescription
            status={status}
            config={baseDesConfig}
            dataSource={companyInfo}
          />
          {/* 联系方式 */}
          <ShDescription
            status={status}
            config={contactDesConfig}
            dataSource={companyInfo}
          />
          {/* 经营风险 */}
          <ShRiskPageModel
            risksTotal={risksTotal}
            setRisksTotal={setRisksTotal}
            riskName="abnormal_items"
            status={status}
            config={riskTableConfig}
            dataSource={companyInfo}
          />
          {/* 商标风险 */}
          <ShRiskPageModel
            risksTotal={riskTradeTotal}
            setRisksTotal={setRisksTradeTotal}
            riskName="risk_trademark"
            status={status}
            config={riskTrademarkConfig}
            dataSource={companyInfo}
          />
          {/* 专利风险 */}
          <ShRiskPageModel
            risksTotal={riskPatentTotal}
            setRisksTotal={setRisksPatentTradeTotal}
            riskName="risk_patent"
            status={status}
            config={riskPatentConfig}
            dataSource={companyInfo}
          />
        </Col>
        <Col span={6}>
          <Anchor
            offsetTop={96}
            items={[
              { key: "1", href: "#基本信息", title: "基本信息" },
              { key: "2", href: "#联系方式", title: "联系方式" },
              { key: "3", href: "#经营风险", title: "经营风险" },
              { key: "4", href: "#商标风险", title: "商标风险" },
              { key: "5", href: "#专利风险", title: "专利风险" },
            ]}
          />
        </Col>
      </Row>
    </>
  );
}

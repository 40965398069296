import supabase from "../index";
export const IsqEmpowerment = async (queryInfo, brand_id, parkId) => {
  const { data, error } = await supabase
    .from("order")
    .insert([{ ...queryInfo, brand_id: brand_id, park_id: parkId, status: 0 }])
    .select();

  if (error) {
    console.error("获取数据失败:", error.message);
  }
  const result = {
    data: data ?? [],
  };
  return result;
};

export const reqCompanyWithKeyWords = async (parkId, keywords) => {
  let query = supabase
    .from("company")
    .select("format_name,id")
    .eq("park_id", parkId);

  if (keywords) {
    query.like("format_name", `%${keywords}%`);
  }
  const { data, error } = await query;
  if (error) {
    console.error("获取数据失败:", error.message);
  }
  const result = {
    data: data ?? [],
  };
  return result;
};

import { Form, Input, Button, Table, Tag, Statistic, Avatar, Spin } from "antd";
import { Link, useLocation } from "react-router-dom";
import { DownloadOutlined, SearchOutlined } from "@ant-design/icons";
import styles from "./consulting.module.css";
import PageHeader from "../../components/PageHeader";
import { reqOrders } from "../../service/consulting";
import { useEffect, useContext, useState } from "react";
import { UserContext } from "../../context";
import { reqAxiosordersLists } from "../../api/lists";

import dayjs from "dayjs";
import { reqOrdersWithId, reqOrderCount } from "../../service/consulting";
import { STATUS } from "../../constants";
import { reqParkName } from "../../service/home/login";
export default function Index() {
  const location = useLocation();
  const { parkId, parkName } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(STATUS.idle);
  const [orderList, setOrderList] = useState([]);
  const [park, setPark] = useState();
  const [orderCounts, setOrderCounts] = useState({});
  const showStatus = (status) => {
    switch (status) {
      case 0:
        return <Tag color="grey">待受理</Tag>;
      case 1:
        return <Tag color="yellow">受理中</Tag>;
      case 2:
        return <Tag color="#1B9C85">已完成</Tag>;
      case 3:
        return <Tag color="red">已取消</Tag>;
      default:
        return "";
    }
  };
  const onFinish = (value) => {
    const { keywords } = value;
    if (keywords !== "") {
      reqOrdersWithId(parkId, keywords).then((res) => {
        setOrderList(res.data);
      });
      return;
    }
    reqOrdersWithId(parkId).then((res) => {
      setOrderList(res.data);
    });
  };
  const handleDownload = (parkId) => {
    reqAxiosordersLists(parkId)
      .then((res) => {
        const contentDispositionHeader = res.headers.get("content-disposition");
        const match = contentDispositionHeader.match(/filename\*=(.*)/);
        const encodedFilename = match[1].trim();
        const decodedFilename = decodeURIComponent(encodedFilename);
        let decodedParkFilename = decodedFilename;

        if (park) {
          decodedParkFilename = park.name + "_" + decodedFilename;
        }
        const downloadUrl = URL.createObjectURL(res.data);
        const downloadLink = document.createElement("a");
        downloadLink.download = decodedParkFilename;
        downloadLink.href = downloadUrl;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        URL.revokeObjectURL(downloadUrl);
        setLoading(false);
      })
      .catch((error) => {
        // 处理下载失败情况
        console.error("下载失败：", error);
      });
  };
  useEffect(() => {
    if (parkId) {
      const doPost = () => {
        reqOrders(parkId).then((res) => {
          setOrderList(res.data);
        });
        reqOrderCount(parkId).then((res) => {
          setOrderCounts(res);
          setStatus(STATUS.success);
        });
        if (parkName) {
          reqParkName(parkName).then((res) => {
            if (res.data.length > 0) {
              setPark(res.data[0]);
            }
          });
        }
      };

      if (status === STATUS.idle) {
        doPost();
      }
    }
  }, [parkId, parkName, status]);
  useEffect(() => {
    setStatus(STATUS.idle);
  }, [location]);
  return (
    <Spin spinning={status === STATUS.success ? false : true}>
      <PageHeader
        title="咨询服务"
        description="检索来自园区入驻企业的相关咨询请求。"
      />
      {orderCounts ? (
        <div className={styles.section}>
          <div className={styles.statistic}>
            <div className={styles.item}>
              <Statistic title="待受理" value={orderCounts.idle} />
            </div>
            <div className={styles.divider} />
            <div className={styles.item}>
              <Statistic title="受理中" value={orderCounts.loading} />
            </div>
            <div className={styles.divider} />
            <div className={styles.item}>
              <Statistic title="已完成" value={orderCounts.sussess} />
            </div>
            <div className={styles.divider} />
            <div className={styles.item}>
              <Statistic title="已取消" value={orderCounts.cancel} />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <div className={styles.actions}>
        <Form layout="inline" onFinish={onFinish}>
          <Form.Item initialValue={""} name="keywords">
            <Input style={{ width: "16rem" }} placeholder="业务名称 / 订单号" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
              检索
            </Button>
          </Form.Item>
        </Form>
        <div>
          <Button
            type="primary"
            // 如果 orderList 数组为空或状态不为成功，则禁用按钮
            disabled={orderList.length === 0 || status !== STATUS.success}
            // disabled={status === STATUS.success ? false : true}
            loading={loading}
            onClick={() => handleDownload(parkId)}
            icon={<DownloadOutlined />}
          >
            导出报表
          </Button>
        </div>
      </div>
      <div className={styles.content}>
        <Table
          columns={[
            {
              title: "咨询单号",
              dataIndex: "id",
              render: (data, record) => (
                <Link
                  to={`/${parkName}/consulting/${record.id}`}
                  state={record}
                >
                  {"HQHT" + dayjs(record.created_time).format("YYMMDD") + data}
                </Link>
              ),
              // sorter: (a, b) => (gt(a, b) ? 1 : -1),
            },
            {
              title: "业务名称",
              dataIndex: "brand_id",
              render: (data, record) => (
                <div>
                  <Avatar
                    style={{ marginRight: ".5rem", backgroundColor: "#333333" }}
                  >
                    S
                  </Avatar>
                  {record.brand_empowerment.title}
                </div>
              ),
              // sorter: (a, b) => (gt(a, b) ? 1 : -1),
            },
            {
              title: "咨询人",
              dataIndex: "name",
            },
            {
              title: "手机",
              dataIndex: "tel",
            },
            {
              title: "公司",
              dataIndex: "company_name",
            },
            {
              title: "服务状态",
              dataIndex: "status",
              render: (data) => showStatus(data),
            },
            {
              title: "咨询时间",
              dataIndex: "created_time",
              render: (data) => dayjs(data).format("YYYY-MM-DD"),
            },
            {
              title: "最后更新时间",
              dataIndex: "update_time",
              render: (data) => dayjs(data).format("YYYY-MM-DD"),
            },
          ]}
          dataSource={orderList}
          rowKey="id"
        />
      </div>
    </Spin>
  );
}

import { Layout } from "antd";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import styles from "./root.module.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { reqAlreadyLogin, reqVerfiyPark } from "../service/home/login";
import { STATUS } from "../constants";
import { useEffect, useState } from "react";
import { UserContext } from "../context";
export default function Root() {
  const navigate = useNavigate();
  const [status, setStatus] = useState(STATUS.idle);
  const location = useLocation();
  const { pathname } = location;
  const [parkId, setParkId] = useState();
  const [admin, setAdmin] = useState(1);
  const [userId, setUserId] = useState("");
  const parkName = location?.pathname.split("/").filter(Boolean)[0];

  useEffect(() => {
    const verfiyPark = async (res) =>
      reqVerfiyPark(res.data.user?.email, parkName).then((res) => {
        if (res.data.length === 0) {
          setStatus(STATUS.failure);
          navigate(`/forbidden`, { state: { referral: location } });
          return;
        }
        setUserId(
          JSON.parse(localStorage.getItem("sb-zevgwqoenxkhkvornmdw-auth-token"))
            ?.user?.id
        );
        setParkId(res.data[0].park_id);
        setAdmin(res.data[0].role);
        setStatus(STATUS.success);
      });
    const fetchAlreadyLogin = async () => {
      await reqAlreadyLogin()
        .then((res) => {
          if (res.data === null) {
            navigate(`/`, { replace: true, state: { referral: location } });
            setStatus(STATUS.failure);
            return;
          }
          return res;
        })
        .then((res) => {
          verfiyPark(res);
        });
    };
    if (status === STATUS.idle) {
      fetchAlreadyLogin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, parkName]);
  useEffect(() => {
    setStatus(STATUS.idle);
  }, [pathname]);
  return (
    <Layout>
      <UserContext.Provider
        value={{
          parkId: parkId,
          parkName: parkName,
          admin: admin,
          userId: userId,
        }}
      >
        <Layout.Header className={styles.header}>
          <Header />
        </Layout.Header>
        <Layout.Content className={styles.body}>
          <Outlet />
        </Layout.Content>
        <Layout.Footer>
          <Footer />
        </Layout.Footer>
      </UserContext.Provider>
    </Layout>
  );
}

import supabase from "..";
export const reqTmGoods = async (parsedQuery) => {
  if (Object.keys(parsedQuery).length === 0) {
    parsedQuery = {
      pageSize: 10,
      current: 1,
      keywords: "",
      height: 1,
      sortFiled: "",
      sortOrder: "",
      brandtype: "",
      group: "",
      character: "",
      outline: "",
      startDate: "",
      endDate: "",
    };
  }
  const { pageSize, current, keywords } = parsedQuery;
  const start = (current - 1) * pageSize;
  const end = start + pageSize - 1;
  let query = supabase
    .from("tm_goods")
    .select(
      "id,request_no,mark_name,good_kind,group_cn,group_id,registration_date,price",
      { count: "exact" }
    )
    .neq("tm_status", 0);

  if (keywords) {
    query.or(`mark_name.like.%${keywords}%,request_no.eq.${keywords}`);
  }

  if (parsedQuery.group) {
    const grouparr = (parsedQuery.group + "").split(",");
    for (let index in grouparr) {
      query.like("group_id", `%${grouparr[index]}%`);
    }
  }
  if (parsedQuery.outline) {
    query.eq("shape_flag", parsedQuery.outline);
  }
  if (parsedQuery.character) {
    query.eq("char_num", parsedQuery.character);
  }
  if (parsedQuery.brandtype) {
    query.in("good_kind", (parsedQuery.brandtype + "").split(","));
  }
  if (parsedQuery.startDate && parsedQuery.endDate) {
    query.or(
      `and(registration_date.gt.${parsedQuery.startDate},registration_date.lt.${parsedQuery.endDate})`
    );
  }
  const { data, count, error } = await query.range(start, end);
  if (error) {
    console.error("获取数据失败:", error.message);
  }
  const result = {
    data: data ?? [],
    count: count ?? 0,
  };
  return result;
};
export const reqTmgoodInfo = async (tmgoodId) => {
  const result = await supabase
    .from("tm_goods")
    .select(
      "id,request_no,mark_name,good_kind,group_cn,group_id,registration_date,price,shape_flag,char_num,goods_status"
    )
    .eq("id", tmgoodId);
  const { error } = result;
  if (error) {
    console.error("获取数据失败:", error.message);
  }
  return result;
};

export const reqTmgoodInfoNo = async (tmgoodId) => {
  const result = await supabase
    .from("tm_goods")
    .select("request_no,mark_name,good_kind")
    .eq("id", tmgoodId);
  const { error } = result;
  if (error) {
    console.error("获取数据失败:", error.message);
  }
  return result;
};
export const reqContactInfo = async (request_no, good_kind) => {
  const query = supabase
    .from("tm_goods_contacts")
    .select("request_no,good_kind,price,contacts,contacts_phone,contacts_addr")
    .eq("request_no", request_no)
    .eq("good_kind", good_kind);
  const { data, error } = await query;
  if (error) {
    console.error("获取数据失败:", error.message);
  }
  const result = {
    data: data ?? [],
  };
  return result;
};

import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  notification,
  Space,
} from "antd";
import { useContext, useEffect, useState } from "react";
import styles from "../index.module.css";
import { UserContext } from "../../../../context";
import { reqCompanyWithKeyWords } from "../../../../service/empowerment/createmodel";
import { reqInsertMember } from "../../../../service/member/vip";
import { fee_status } from "../../../../intlClass";
import { STATUS } from "../../../../constants";
import { CheckCircleOutlined, CloseCircleOutlined, LoginOutlined } from "@ant-design/icons";

import { reqUpdateMembersFeeStatus, reqUpdateMembersStatus } from "../../../../service/member/vip"

export default function AddMember({
  onStatusChange,
  title = "添加会员",
  memberIds, // 接受 memberIds 作为属性--会员id
  resetSelection, // 清空复选框: 接受 resetSelection 作为属性
}) {
  const [open, setOpen] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [com, setCom] = useState();
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const { parkId } = useContext(UserContext);

  // 声明一个状态变量，用于管理按钮的加载状态
  const [loading, setLoading] = useState(false);
  // 声明一个状态变量，用于存储用户选择的批量操作
  const [selectedAction, setSelectedAction] = useState(null);

  const onFinish = async () => {
    try {
      const values = await form.validateFields();
      const memberData = {
        ...values,
        member_name: com,
        park_id: parkId,
        status: 1,
      };
      await reqInsertMember(memberData)
        .then((res) => {
          api.success({
            message: "会员添加成功",
            placement: "top",
            style: {
              marginTop: "3rem",
            },
          });
          onStatusChange(STATUS.idle);
          setOpen(false);
        })
        .catch((err) => {
          api.error({
            message: err.message,
            placement: "top",
            style: {
              marginTop: "3rem",
            },
          });
        });
    } catch (error) { }
  };
  const handleChange = (value, option) => {
    setCom(option.label);
  };
  useEffect(() => {
    if (parkId) {
      reqCompanyWithKeyWords(parkId).then((res) => {
        setData(res.data);
      });
    }
  }, [parkId]);


  // 批量设置会员状态函数
  const updateVipStatus = async (status) => {
    setLoading(true); // 启用加载状态
    try {
      const result = await reqUpdateMembersFeeStatus(parkId, memberIds, status);
      // 调用清空复选框方法
      resetSelection();
      // 刷新表格数据
      onStatusChange(STATUS.idle);
      // console.log(`会员状态(${status === 1 ? '已缴费' : '未缴费'})更新成功`, result);
    } catch (error) {
      console.error(`会员状态(${status === 1 ? '已缴费' : '未缴费'})更新失败`, error);
    } finally {
      setLoading(false); // 关闭加载状态
    }
  };

  // 批量处理会员退会
  const setVipLogin = async () => {
    setLoading(true); // 启用加载状态
    try {
      const result = await reqUpdateMembersStatus(parkId, memberIds);
      // 调用清空复选框方法
      resetSelection();
      // 刷新表格数据
      onStatusChange(STATUS.idle);
      // console.log(`会员退会处理成功`, result);
    } catch (error) {
      console.error(`会员退会处理失败`, error);
    } finally {
      setLoading(false); // 关闭加载状态
    }
  };

  // 处理执行按钮点击事件，根据 selectedAction 调用相应的函数
  const handleExecute = () => {
    if (selectedAction === "已缴费") {
      updateVipStatus(1);
    } else if (selectedAction === "未缴费") {
      updateVipStatus(2);
    } else if (selectedAction === "退会") {
      setVipLogin();
    }
    setSelectedAction(null); // 重置下拉菜单的值
  };

  return (
    <>
      {contextHolder}
      <div className={styles.addMember}>
        <div>
          <Space>
            <Select
              placeholder="批量操作"
              style={{ width: 200 }}
              onChange={(value) => setSelectedAction(value)}
              value={selectedAction} // 绑定 selectedAction 状态
              options={[
                { value: "已缴费", label: <><CheckCircleOutlined /> 批量设置已缴费</> },
                { value: "未缴费", label: <><CloseCircleOutlined /> 批量设置未缴费</> },
                { value: "退会", label: <><LoginOutlined /> 批量删除</> }
              ]}
            />
            <Button
              type="primary"
              onClick={handleExecute}
              disabled={!selectedAction || memberIds.length === 0} // 禁用按钮，如果没有选择操作或没有选择会员
              loading={loading}
            >
              执行
            </Button>
          </Space>
        </div>
        {/* <div>
          <Button ghost type="primary" onClick={() => setOpen(true)}>
            添加会员
          </Button>
        </div> */}
      </div>
      <Modal
        title={title}
        forceRender
        open={open}
        onOk={() => onFinish()}
        onCancel={() => setOpen(false)}
        width={720}
        confirmLoading={!open}
        destroyOnClose={true}
      >
        <Row gutter={32}>
          <Col span={20}>
            <Form
              wrapperCol={{ span: 19 }}
              labelCol={{ span: 5 }}
              form={form}
              name="validateOnly"
            >
              <Form.Item
                label="公司"
                name="company_id"
                rules={[
                  {
                    required: true,
                    message: "请求选择公司!",
                  },
                ]}
              >
                <Select
                  placeholder="请求选择公司"
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? "").includes(input)
                  }
                  onChange={handleChange}
                  options={(data || []).map((d) => ({
                    value: d.id,
                    label: d.format_name,
                  }))}
                />
              </Form.Item>
              <Form.Item
                name="phone"
                label="手机"
                rules={[
                  {
                    pattern: /^1[3|415178][0-9]{9}$/, // 使用正则表达式验证手机号码格式
                    message: "手机号码格式不正确",
                  },
                ]}
              >
                <Input placeholder="请输入手机号码" />
              </Form.Item>
              <Form.Item
                label="会费状态"
                name="fee_status"
                rules={[
                  {
                    required: true,
                    message: "请求选择会费状态!",
                  },
                ]}
              >
                <Select
                  placeholder="请选择会费状态"
                  onChange={() => form.submit()}
                  allowClear
                  showSearch
                  style={{ minWidth: "120px" }}
                  filterOption={(input, option) =>
                    (option?.label ?? "").includes(input)
                  }
                  options={fee_status.map((item) => {
                    return {
                      label: item.title,
                      value: item.id,
                    };
                  })}
                />
              </Form.Item>
              <Form.Item
                label="入会时间"
                name="join_date"
                rules={[{ required: true, message: "请填写入会时间" }]}
              >
                <DatePicker />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
}
